import React, { useState } from 'react';
import { MoreOutlined } from '@ant-design/icons';
import { Col, Row, Tooltip } from 'antd';
import { I18n } from '@aws-amplify/core';
import moment from 'moment';

import { ListWithoutPlayerProps } from '../ListWithoutPlayer';
import { riskCalc, riskLevels } from '@/utils/riskRange';
import { CustomModal } from '@/components/CustomModal';
import { Text, Title } from '@/components/Typography';
import { ActionsForFile } from '../ActionsForFile';
import { formatFileSize } from '@/utils/mask';
import { PlayerRowContainer } from './styles';
import { FilesListResponse } from '@/hooks';
import { Thumbnail } from './Thumbnail';
import { TagRisk } from '../../styles';
import { Player } from './Player';

interface ListWithPlayerProps extends ListWithoutPlayerProps {
	file: FilesListResponse;
	onSelectRowKey: (selectedRows: string[]) => void;
}

type ModalStatus = {
	isOpen: boolean;
	fileId?: string;
};

export function ListWithPlayer({
	file,
	onEditFile,
	onDeleteFile,
	selectedRows,
	onSelectRowKey,
	onDownloadVideo,
	onRedirectPreliminary,
	onShowReportOptionsReports
}: Readonly<ListWithPlayerProps>) {
	const [modalStatus, setModalStatus] = useState<ModalStatus>({
		isOpen: false,
		fileId: undefined
	});

	function handleShowModal(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
		event.preventDefault();
		event.stopPropagation();
		setModalStatus({
			isOpen: true,
			fileId: file.id
		});
	}

	function handleCancel() {
		setModalStatus({
			isOpen: false,
			fileId: undefined
		});
	}

	function handleSelectRow(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
		event.preventDefault();
		event.stopPropagation();
		const selectedRowsCopy = [...selectedRows];

		if (selectedRowsCopy.indexOf(file.id) >= 0) {
			selectedRowsCopy.splice(selectedRowsCopy.indexOf(file.id), 1);
		} else {
			selectedRowsCopy.push(file.id);
		}
		onSelectRowKey(selectedRowsCopy);
	}

	return (
		<Col key={file.id} xs={24} style={{ marginBottom: '10px' }}>
			<PlayerRowContainer
				align="middle"
				gutter={[8, 8]}
				onClick={handleSelectRow}
				$isRowSelected={selectedRows.some((id) => id === file.id)}
			>
				<Thumbnail file={file} onShowVideoModal={handleShowModal} />
				<Col xxl={20}>
					<Row gutter={[30, 20]}>
						<Col span={24}>
							<Row justify="space-between">
								<Col>
									<Title level={5}>{file.original_name}</Title>
								</Col>
								<Col span={0.1}>
									<ActionsForFile
										file={file}
										isDropDown={true}
										onEdit={onEditFile}
										onDelete={onDeleteFile}
										onDownload={onDownloadVideo}
										onRedirectPreliminary={onRedirectPreliminary}
										icon={<MoreOutlined style={{ color: 'black' }} />}
										onShowReportOptionsReports={onShowReportOptionsReports}
									/>
								</Col>
							</Row>
						</Col>
						<Col xxl={6}>
							<Row>
								<Text style={{ fontWeight: '400', marginBottom: '10px' }}>Size</Text>
								<Text style={{ fontWeight: '350' }}>: {formatFileSize(file.size)}</Text>
							</Row>
							<Row>
								<Text style={{ fontWeight: '400' }}>Company</Text>
								<Text style={{ fontWeight: '350' }}>: {file.organization?.name}</Text>
							</Row>
						</Col>
						<Col xxl={6}>
							<Row>
								<Text style={{ fontWeight: '400', marginBottom: '10px' }}>Upload date</Text>
								<Text style={{ fontWeight: '350' }}>: {moment(file.createdAt).format('L')}</Text>
							</Row>
							<Row>
								<Text style={{ fontWeight: '400' }}>Industrial site</Text>
								<Text style={{ fontWeight: '350' }}>: {file.company?.name}</Text>
							</Row>
						</Col>
						<Col xxl={6}>
							<Row>
								<Text style={{ fontWeight: '400', marginBottom: '10px' }}>Status</Text>
								<Text style={{ fontWeight: '350' }}>: {file.original_name}</Text>
							</Row>
							<Row>
								<Text style={{ fontWeight: '400' }}>Sector</Text>
								<Text style={{ fontWeight: '350' }}>: {file.sector?.name}</Text>
							</Row>
						</Col>
						<Col xxl={6}>
							<Row>
								<Text style={{ fontWeight: '400', marginBottom: '10px' }}>Rating</Text>:{' '}
								{file.preliminary_analysis?.consolidated ? (
									<TagRisk
										$result={riskCalc(file.preliminary_analysis.worst_score)}
										style={{ marginLeft: '10px', textAlign: 'center', height: '22px' }}
										$isBrightBackground={riskCalc(file.preliminary_analysis.worst_score)}
									>
										<Tooltip
											title={I18n.get(
												`${riskLevels[riskCalc(file.preliminary_analysis.worst_score)]}`
											)}
										>
											{riskCalc(file.preliminary_analysis.worst_score) === 5 ? (
												<Text ellipsis={true}>Serious risk</Text>
											) : (
												<Text ellipsis={true}>
													{riskLevels[riskCalc(file.preliminary_analysis.worst_score)]}
												</Text>
											)}
										</Tooltip>
									</TagRisk>
								) : (
									<Text ellipsis={true}>Not assigned</Text>
								)}
							</Row>
							<Row>
								<Text style={{ fontWeight: '400' }}>Workstation</Text>
								<Text style={{ fontWeight: '350' }}>: {file.workstations?.name}</Text>
							</Row>
						</Col>
					</Row>
				</Col>
			</PlayerRowContainer>
			{modalStatus.isOpen && modalStatus.fileId === file.id && (
				<CustomModal
					centered
					width={600}
					footer={null}
					onCancel={handleCancel}
					open={modalStatus.isOpen}
					maskStyle={{ backgroundColor: 'rgba(0,0,0,0.1)' }}
				>
					<Row justify="center" gutter={[0, 20]}>
						<Col>
							<Title style={{ marginTop: '2rem' }} level={5}>
								{file.original_name}
							</Title>
						</Col>
						<Player file={file} />
					</Row>
				</CustomModal>
			)}
		</Col>
	);
}
