import { useQuery } from '@tanstack/react-query';

import Api from '@/services/api';
import { QUERY_KEYS } from '@/utils/query-keys';
import type { GetUsersDTO } from '@/types/dto/user.dto';
import type { UserRole } from '@/types/User';

export interface UserDTO {
	id: string;
	name: string;
	email: string;
	role: UserRole;
	is_active: boolean;
}

export type UserListResponse = {
	rows: UserDTO[];
	count: number;
};

async function getUsers({ filter, page, limit }: GetUsersDTO): Promise<UserListResponse> {
	const url = '/user_management/user_list_by_costumer';
	const { data } = await Api.get(url, { params: { ...filter, page, limit } });
	return data;
}

export const useGetUsers = (params: GetUsersDTO) => {
	return useQuery([QUERY_KEYS.GET_USERS, params], () => getUsers(params));
};
