const dictionary: Record<string, string> = {
	'Customer information not found': 'Informações do cliente não encontradas',
	'Account subscription expired, renew to continue.': 'A assinatura da conta expirou, renove para continuar.',
	'Account subscription cancelled, renew to continue.': 'Assinatura da conta cancelada, renove para continuar.',

	Warning: 'Atenção',
	Yes: 'Sim',
	No: 'Não',

	Companies: 'Empresas',
	'Corrupted file': 'Arquivo corrompido',
	'Extracted data': 'Dados extraídos',
	'Not processing': 'Não processado',
	Processing: 'Processando',
	Processed: 'Processado',
	'In queue': 'Em fila',

	// Upload
	'Send video': 'Enviar vídeo',
	Error: 'Erro',
	'Add files or drag': 'Adicionar arquivos ou arraste',
	'Unsupported file': 'Arquivo não suportado',
	'Drop files here': 'Solte os arquivos aqui',
	'Unsupported video format': 'Formato de video não é suportado',
	and: 'e',
	'is larger than': 'É maior que',
	'Max size': 'Tamanho máximo',
	Blur: 'Desfoque',
	'Are you sure you want to exit? You have an uploads in process.':
		'Tem certeza que deseja sair ? Você tem um uploads em processo.',
	' or many files': ' ou muitos arquivos',
	'Files number exceeded': 'Número de arquivos excedidos',
	'Max files': 'Máximo de arquivos',
	'User created with success': 'Usuário criado com sucesso',
	'File duplicated': 'Arquivo duplicado',

	// Select project
	'Select the desired company below': 'Selecione a empresa desejada abaixo',
	'Select the desired industrial site below': 'Selecione a planta industrial desejada abaixo',

	'Create a new company': 'Crie uma nova empresa',
	'Create a new industrial site': 'Crie uma nova planta industrial',

	'Enter a name for the company': 'Insira um nome para a empresa',
	'Enter a name for the industrial site': 'Insira um nome para a planta industrial',

	'Company created successfully': 'Empresa criada com sucesso',
	'Industrial site created successfully': 'Planta industrial criada com sucesso',

	'There are no registered companies': 'Não há empresas cadastradas',
	'There are no registered industrial sites': 'Não existe nenhuma planta industrial cadastrada.',

	'Congratulations! Now create an industrial site that belongs to this company.':
		'Parabéns! Agora crie a planta industrial que pertence a essa empresa e comece a utilizar a plataforma',
	'Create industrial site': 'Criar planta industrial',

	'Welcome to Kinebot, create your first company': 'Bem vindo ao Kinebot, crie sua primeira empresa.',
	'Create company': 'Criar empresa',

	'Go back': 'Voltar',
	Back: 'Voltar',
	Create: 'Criar',

	'Ergonomic tools': 'Ferramentas ergonômicas',
	'Select the most suitable tool for your analysis': 'Selecione a ferramenta mais adequada para sua análise',
	'Biomechanical analysis': 'Análise biomecânica',
	'Load analysis': 'Análise de cargas',
	'Repeatability analysis': 'Análise de repetitividade',

	// Report/UploadList
	Workstation: 'Posto de trabalho',
	Description: 'Descrição',
	Size: 'Tamanho',
	'Upload date': 'Data de upload',
	Sector: 'Setor',
	Rating: 'Classificação',
	Actions: 'Ações',
	Action: 'Ações',
	'Not assigned': 'Não atribuido',
	'Heads up!': 'Atenção!',
	'Heads up! Want to delete this file?': 'Atenção! Deseja excluir este arquivo?',
	'Heads up! Want to delete those files?': 'Atenção! Deseja excluir estes arquivos?',
	'Force/Charge movement data': 'Dados sobre movimento com Força/Carga',

	Repetitions: 'Repetições',
	'Repetitions per minute': 'Repetições por minuto',
	Legs: 'Pernas',
	Diagnostic: 'Diagnóstico',
	'Analysis results': 'Resultados da análise',

	'Not found': 'Não encontrado',
	Light: 'Leve',
	'Failed to fetch data': 'Falha ao buscar os dados.',
	//'Risk level of the job': 'Grau de risco do posto de trabalho',
	'Average exposure of the workplace': 'Média de exposição do posto de trabalho',
	'Unable to show results.': 'Não foi possível mostrar os resultados.',

	'Load less than 2 kg (intermittent)': 'Carga menor de 2 kg (intermitente)',
	'Load from 2 kg to 10 kg (intermittent)': 'Carga de 2 kg a 10 kg (intermitente)',
	'Load from 2 kg to 10 kg (static or repetitive)': 'Carga de 2 kg a 10 kg (estático ou repetitivo)',
	'Bigger load 10 kg of repetitive load or blows': 'Carga maior 10 kg de carga repetitivos ou pancadas',
	'Legs and feet supported and with equal load distribution':
		'As pernas e pés apoiados e com igual distribuição de carga',
	'Legs without support': 'Pernas sem apoio',

	Reports: 'Relatórios',
	'Select the data visualization model': 'Selecione o modelo de visualização dos dados',
	Angle: 'Ângulo',
	'Angle in degrees (°)': 'Ângulo em graus (°)',
	'Time in seconds (s)': 'Tempo em segundos (s)',
	Parameters: 'Parâmetros',
	'Manual lifting': 'Levantamento de cargas',
	'NIOSH / ISO 11228-1 - Manual lifting analysis': 'NIOSH / ISO 11228-1 - Análise de levantamento manual de cargas',
	'Push and pull': 'Empurrar e puxar',
	'KIM - Push and pull': 'KIM - Empurrar e puxar',
	'KIM - PP Report Results': 'KIM - EP Relatório de Resultados',
	meters: 'metros',
	'Temporal distribution': 'Distribuição temporal',
	Distance: 'Distância',
	'Work condition': 'Condições de trabalho',
	'Work conditions': 'Condições de trabalho',
	'Working conditions': 'Condições de trabalho',
	'Vehicle properties': 'Propriedades veículo',
	Vehicle: 'Veículo',
	'Vehicle: ': 'Veículo: ',
	'Distance or duration': 'Distância ou duração',

	'Select one of the options below': 'Selecione uma das opções abaixo',
	'Short distances': 'Distâncias curtas',
	'Long distances': 'Distâncias longas',
	Time: 'Tempo',
	Mass: 'Massa',
	'Mass: ': 'Massa: ',
	Precision: 'Precisão',
	'Worker gender': 'Gênero do trabalhador',
	'Pushing / Pulling in pairs': 'Empurrando / Puxando em pares',
	'By distance': 'Pela distância',
	'By duration': 'Pela duração',
	'Distance per work day (in meters)': 'Distância por dia de trabalho (em metros)',
	'Duration per work day (in minutes)': 'Duração por dia de trabalho (em minutos)',
	'≤ 40': '≤ 40',
	'≤ 200': '≤ 200',
	'≤ 400': '≤ 400',
	'≤ 800': '≤ 800',
	'≤ 1,200': '≤ 1.200',
	'≤ 1,800': '≤ 1.800',
	'≤ 2,500': '≤ 2.500',
	'≤ 4,200': '≤ 4.200',
	'≤ 6,300': '≤ 6.300',
	'≤ 8,400': '≤ 8.400',
	'≤ 11,000': '≤ 11.000',
	'≤ 15,000': '≤ 15.000',
	'≤ 20,000': '≤ 20.000',
	'≤ 1': '≤ 1',
	'≤ 5': '≤ 5',
	'≤ 10': '≤ 10',
	'≤ 20': '≤ 20',
	'≤ 30': '≤ 30',
	'≤ 45': '≤ 45',
	'≤ 60': '≤ 60',
	'≤ 100': '≤ 100',
	'≤ 150': '≤ 150',
	'≤ 210': '≤ 210',
	'≤ 270': '≤ 270',
	'≤ 360': '≤ 360',
	'≤ 480': '≤ 480',
	'Select one of the vehicles': 'Selecione um dos veículos',
	Barrows: 'Carrinhos de mão',
	Carriages: 'Carros de transporte',
	'Overhead conveyors': 'Transportadores',
	'Overhead cranes': 'Guindastes',
	'Choose an option': 'Escolha uma opção',
	'Reference image: only swivel castors': 'Imagem de referência: apenas rodas móveis',
	'Reference image: with fixed castors or lockable swivel castors':
		'Imagem de referência: com rodas fixas ou que podem ser travadas',
	'Reference image: pedestrian-controlled': 'Imagem de referência: controlados por pedestre',
	'Driveway conditions': 'Condições da calçada',
	'Inclination or stairs': 'Inclinação ou escadas',
	'Select the mass to be transported (in kg)': 'Selecione a massa a ser transportada (em kg)',
	'Driveway completely level, smooth, solid, dry, without inclinations':
		'Calçada completamente nivelada, lisa, sólida, seca, sem inclinações',
	'Driveway mostly smooth and level, with small damaged spots/faults, without inclinations':
		'Calçada quase lisa e nivelada, com pequenas danificações em alguns pontos, sem inclinações',
	'Mixture of cobbles, concrete, asphalt, slight inclinations, dropped kerb':
		'Mistura de paralelepípedos, concreto, asfalto, inclinações leves, meio-fio rebaixado',
	'Mixture of roughly cobbled, hard sand, slight inclinations, small edges/sills':
		'Mistura rude de paralelepípedos, areia dura, inclinações leves e pequenas arestas/soleiras',
	'Earth or roughly cobbled driveway, potholes, heavy soiling, slight inclinations, landings, sills':
		'Mistura rude de terra ou paralelepípedos, buracos, sujeira pesada, pequenas inclinações, aterragem, soleiras',
	'Inclinations of 2 ≤ 4° (4 ≤ 8%)': 'Inclinações de 2 ≤ 4° (4 ≤ 8%)',
	'Inclinations of 5 ≤ 10° (9 ≤ 18%)': 'Inclinações de 5 ≤ 10° (9 ≤ 18%)',
	'Stairs, inclinations > 10° (18%)': 'Escadas, inclinações > 10° (18%)',
	'No significant inclination': 'Sem inclinação relevante',
	'≤ 50': '≤ 50',
	'≤ 300': '≤ 300',
	'≤ 600': '≤ 600',
	'≤ 1,000': '≤ 1.000',
	'≤ 1,300': '≤ 1.300',
	'> 1,300': '> 1.300',
	'Select the appropriate options': 'Selecione a opção apropriada',
	'Unfavorable working conditions - more than one can be selected':
		'Condições de trabalho desfavoráveis - mais de uma opção pode ser selecionada',
	'Unfavorable vehicle properties - more than one can be selected':
		'Propriedades desfavoráveis do veículo - mais de uma opção pode ser selecionada',
	'Unfavorable working conditions': 'Condições de trabalho desfavoráveis',
	'Unfavorable vehicle properties': 'Propriedades desfavoráveis do veículo',
	'Regularly significantly increased starting forces, because transport devices sink into the ground or get wedged':
		'Regularmente necessária uma força considerável para iniciar movimentação, devido ao veículo afundar no chão ou ficar travado',
	'Frequent stops with breaking': 'Paradas frequentes com freagem',
	'Frequent stops without breaking': 'Paradas frequentes sem freagem',
	'Many changes of direction or curves, frequent manouvering':
		'Muitas mudanças de direção ou curvas, manobramento frequente',
	'Load must be positioned precisely and stopped, driveway must be adhered to precisely':
		'Carga precisa ser posicionada precisamente e fixada, aderindo um lugar exato da calçada',
	'Increased movement speed (approx. 1.0 ≤ 1.3 m/s)': 'Velocidade de movimento elevada (aprox. 1.0 ≤ 1.3 m/s)',
	'None: there are no unfavorable working conditions': 'Nenhuma: não há condições de trabalho desfavoráveis',
	'No suitable handles or construction parts for applying force':
		'Não há alças ou partes adequadas para aplicação de força',
	'No brake when driving on inclinations > 2° (> 3%)': 'Sem freios quando manuseando ou inclinações > 2° (> 3%)',
	'Unadjusted castors (e.g. too small on soft or uneven floor)':
		'Rodas mal ajustadas (ex.: pequenas demais ou não condizem com o piso)',
	'Defective castors (worn-out, rubbing, stiff, air pressure too low)':
		'Rodas defeituosas (desgastadas, com fricção, travadas, pressão de ar muito baixa)',
	'None: there are no unfavorable properties of the transport devices':
		'Nenhuma: não há propriedades desfavoráveis do veículo',

	'Trunk upright or slightly inclined forward, no twisting / Force application height can be selected freely / No hindrance for the legs':
		'Tronco ereto ou levemente inclinado para frente, sem torção / Força de aplicação com altura selecionada livremente / Sem complicação para as pernas',
	'Body inclined towards the direction of movement or slight twisting when pulling the load on one side / Fixed force application height ranging from 0.9 – 1.2 m / No or only slight hindrance for the legs / Predominantly pulling':
		'Corpo inclinado na direção do movimento ou levemente torcido puxando a carga de lado / Aplicação de força na altura fixa de 0.9 - 1.2m / Nenhuma ou pequena complicação para as pernas / Predominantemente puxando',
	'Awkward body postures caused by fixed force application height < 0.9 or > 1.2 m , lateral force application on one side or significantly obstructed view / Significant hindrance for the legs / Constant twisting and/or lateral inclination of the trunk':
		'Posturas corporais estranhas causadas por força na altura fixa de < 0.9 ou > 1.2m, aplicação de força lateral em um lado ou com vista significantemente obstruída / Complicação considerável para as pernas / Torção constante e/ou inclinação lateral do tronco',

	'Trunk upright or slightly inclined forward, no twisting':
		'Tronco ereto ou levemente inclinado para frente, sem torção',
	'Body inclined towards the direction of movement or slight twisting when pulling the load on one side':
		'Corpo inclinado na direção do movimento ou levemente torcido puxando a carga de lado',
	'Awkward body postures caused by fixed force application height < 0.9 or > 1.2 m , lateral force application on one side or significantly obstructed view':
		'Posturas corporais estranhas causadas por força na altura fixa de < 0.9 ou > 1.2m, aplicação de força lateral em um lado ou com vista significantemente obstruída',

	'Force application height can be selected freely': 'Força de aplicação com altura selecionada livremente',
	'Fixed force application height ranging from 0.9 – 1.2 m': 'Aplicação de força na altura fixa de 0.9 - 1.2m',
	'Significant hindrance for the legs': 'Complicação considerável para as pernas',

	'No hindrance for the legs': 'Sem complicação para as pernas',
	'No or only slight hindrance for the legs': 'Nenhuma ou pequena complicação para as pernas',
	'Constant twisting and/or lateral inclination of the trunk': 'Torção constante e/ou inclinação lateral do tronco',

	'Predominantly pulling': 'Predominantemente puxando',

	Postures: 'Posturas',
	'Direction of force': 'Direção da força',
	Unfavorable: 'Desfavorável',
	'Score and risk assessment': 'Score e definição do risco',
	'Intensity of load: ': 'Intensidade da carga',
	'Probability of physical overload / Possible health consequences: ':
		'Probabilidade de sobrecarga física / Possíveis consequências de saúde: ',
	'Physical overload is unlikely / No health risk is to be expected.':
		'Sobrecarga física é improvável / Sem risco de saúde esperado.',
	'Physical overload is unlikely': 'Sobrecarga física é improvável',
	'No health risk is to be expected': 'Sem risco de saúde esperado',
	'Physical overload is possible for less resilient persons /  Fatigue, low-grade adaptation problems which can be compensated for during leisure time.':
		'Sobrecarga física é possível para pessoas menos resistentes / Fadiga, pequenos problemas de adaptação que podem ser compensados com tempo de descanso.',
	'Physical overload is possible for less resilient persons':
		'Sobrecarga física é possível para pessoas menos resistentes',
	'Fatigue, low-grade adaptation problems which can be compensated for during leisure time':
		'Fadiga, pequenos problemas de adaptação que podem ser compensados com tempo de descanso.',
	'Physical overload is also possible for normally resilient persons / Disorders (pain), possibly including dysfunctions, reversible in most cases, without morphological manifestation':
		'Sobrecarga física é possível mesmo para pessoas com resistência normal / Distúrbios (dor), possibilidade de disfunções, reversível na maioria dos casos, sem manifestação morfológica',
	'Physical overload is also possible for normally resilient persons':
		'Sobrecarga física é possível mesmo para pessoas com resistência normal',
	'Disorders (pain), possibly including dysfunctions, reversible in most cases, without morphological manifestation':
		'Distúrbios (dor), possibilidade de disfunções, reversível na maioria dos casos, sem manifestação morfológica',
	'Physical overload is likely / More pronounced disorders and/or dysfunctions, structural damage with pathological significance':
		'Sobrecarga física é provável / Distúrbios acentuados e/ou disfunções, dano estrutural com significância patológica',
	'Physical overload is likely': 'Sobrecarga física é provável',
	'More pronounced disorders and/or dysfunctions, structural damage with pathological significance':
		'Distúrbios acentuados e/ou disfunções, dano estrutural com significância patológica',
	'Measures: ': 'Medidas: ',
	' For less resilient persons, workplace redesign and other prevention measures may be helpful.':
		' Para as pessoas menos resitentes, trocar o local de trabalho e outras medidas de prevenção podem ser de ajuda.',
	' Workplace redesign and other prevention measures should be considered.':
		' Trocar o local de trabalho e outras medidas de prevenção devem ser consideradas.',
	' Workplace redesign measures are necessary. Other prevention measures should be considered.':
		' Trocar o local de trabalho é necessário. Outras medidas de prevenção também devem ser consideradas.',
	None: 'Nenhuma',
	Results: 'Resultados',

	Information: 'Informações',
	'Body parts': 'Partes do corpo',
	Charts: 'Gráficos',

	'Industrial Site': 'Planta industrial',
	'Collection date': 'Data de coleta',
	'Risk range': 'Faixa de risco',
	'Risk Range': 'Faixa de risco',

	'Failed to fetch sectors': 'Falha ao buscar os setores',
	'Failed to create a new sector': 'Falha ao criar um novo setor',
	'Failed to fetch risk bands': 'Falha ao buscar as faixas de risco',
	'Sector created successfully': 'Setor criado com sucesso',

	'Select a sector': 'Selecione um setor',
	'Insert a workstation': 'Insira um posto de trabalho',
	'Insert the date of collection': 'Insira a data da coleta',
	'Select a risk band': 'Selecione uma faixa de risco',
	'Graphs of selected body parts': 'Gráficos das partes do corpo selecionadas',

	Summary: 'Resumo',
	'Range of Risk': 'Faixa de risco',
	'Job information': 'Informação de trabalho',
	'File data': 'Dados do arquivo',

	File: 'Arquivo',
	Duration: 'Duração',
	'Relevant points': 'Pontos relevantes',
	Frequency: 'Frequência',
	'Most critical move': 'Movimento mais crítico',
	'Include conclusion': 'Incluir conclusão',
	'Include signature': 'Incluir assinatura',
	'Insert conclusion': 'Inserir conclusão',
	'Risk track created successfully!': 'Faixa de risco criada com sucesso!',
	'Create custom risk bands based on your analysis':
		'Crie faixas de riscos personalizadas de acordo com as suas análises',

	//Dashboard
	'Industrial site status': 'Status da planta industrial',
	'Harmful postures': 'Posturas lesivas',
	'Inadequate postures': 'Posturas inadequadas',
	'Workstation with good postures': 'Postos com boas posturas',
	'Workers in real danger': 'Trabalhadores em risco real',
	'Total of videos': 'Total de vídeos',
	'Processed minutes': 'Minutos processados',
	'Critical sector': 'Setor crítico',
	'Registered sectors': 'Setores cadastrados',
	'Exported reports': 'Relatórios extraídos',
	'Sectors in alert': 'Setores em alerta',
	'Main statistics': 'Estatísticas principais',
	'No. of videos by risk level': 'Total de vídeos por grau de risco',
	Month: 'Mês',
	Year: 'Ano',
	Day: 'Dia',
	'Assessments completed': 'Avaliações completas',
	'Videos by sector': 'Vídeos por setores',
	'Percentage of videos by risk': 'Percentual de vídeos por risco',
	'Ergonomics Management': 'Gestão de Ergonomia',
	'Risk Quotation': 'Cotação de Risco',
	'Total workstations': 'Total de postos',
	'Total pending evaluations': 'Total de avaliações pendentes',
	'Total evaluations consolidated': 'Total de avaliações consolidadas',
	Evaluations: 'Avaliações',
	'Not analyzed': 'Não analisados',
	'Not analysed': 'Não analisado',
	'There are no registered action plans': 'Não há planos de ação registrados',
	'Acceptable Risk': 'Risco aceitável',
	'Acceptable risk': 'Risco aceitável',
	'Moderate Risk': 'Risco moderado',
	'Moderate risk': 'Risco moderado',
	'High Risk': 'Risco elevado',
	'High risk': 'Risco elevado',
	'Very High Risk': 'Risco muito elevado',
	'Very high risk': 'Risco muito elevado',
	'Extreme and Imminent Risk': 'Risco grave e iminente',
	'Serious and Imminent Risk': 'Risco grave e iminente',
	'Serious and imminent risk': 'Risco grave e iminente',
	'Serious risk': 'Risco grave',
	'Serious and imminent': 'Grave e iminente',

	// Dashboard Filter
	Cellule: 'Célula',
	'Select the Cellule': 'Selecione a Célula',
	'Select the Workstation': 'Selecione o Posto de Trabalho',
	'Working Population': 'População trabalhadora',
	Others: 'Outros',
	'Stress Level': 'Nível de Estresse',
	'Risk Level': 'Nível de Risco',

	// Dashboard Tools Results
	Repetitiveness: 'Repetitividade',
	'Cargo Handling': 'Manuseio de Carga',
	'Work Organization': 'Organização do Trabalho',
	'Score by workstation': 'Índice por posto de trabalho',
	'Total evaluations': 'Total de avaliações',
	Low: 'Baixo',
	Average: 'Média',
	High: 'Alta',

	// Dashboard Movement by score
	'Movement by score': 'Percentual de Exposição',
	'Risk per Workstation': 'Risco por posto de trabalho',

	// Dashboard Workstations
	Workstations: 'Postos de trabalho',
	'Higher workstation risk score': 'Maior índice de risco do posto de trabalho',
	// Dashboard Action Plans
	'Action plans': 'Planos de ação',
	'Planned Actions': 'Ações previstas',
	Completed: 'Concluídas',
	Concluded: 'Concluído',
	Ascending: 'Crescente',
	'Deadline : ': 'Prazo : ',

	// Buttons
	Next: 'Avançar',
	Previous: 'Voltar',
	delete: 'excluir',
	Delete: 'Excluir',

	'Do you really want to delete these tracks?': 'Deseja realmente exlcuir essas faixas ?',
	'Heads up! the deletion is permanent': 'Atenção! a exclusão é permanente',
	'Risk ranges deleted successfully': 'Faixas de risco excluídas com sucesso',

	'Description (optional)': 'Descrição (opcional)',

	// Body parts
	Neck: 'Pescoço',
	Trunk: 'Tronco',
	UpperArm: 'Ombro',
	LowerArm: 'Cotovelo',

	'Customize bands of risk': 'Personalize faixas de risco',
	'Create custom risk strips according to your needs.':
		'Crie faixas de riscos personalizadas de acordo com as suas necessidades.',

	Name: 'Nome',
	'Industrial site name': 'Nome da planta industrial',
	'Enter a name': 'Insira um nome',
	'Please enter a valid name': 'Insira um nome válido',
	'Please enter a valid phone number': 'Insira um telefone válido',
	'Invalid phone number': 'Número de telefone inválido',
	'Insert short description': 'Insira uma breve descrição',
	'Add new band': 'Adicionar nova banda',
	'Create new band': 'Criar nova banda',
	'Minimum angle': 'Ângulo mínimo',
	'Maximum angle': 'Ângulo máximo',
	Coloring: 'Coloração',
	Remove: 'Remover',

	//	Breadcrumb
	Home: 'Início',
	'Manage users': 'Gerenciar usuários',
	Preferences: 'Preferências',
	'My informations': 'Minhas informações',
	'My plan': 'Meu plano',

	individual: 'Individual',
	team: 'Equipe',
	business: 'Empresarial',
	pilot: 'Piloto',

	yearly: 'anual',
	monthly: 'mensal',
	Yearly: 'Anual',
	Monthly: 'Mensal',

	User: 'Usuário',
	Supervisor: 'Supervisor',
	Master: 'Master',
	Admin: 'Administrador',

	'Choose a plan different from your current plan': 'Escolha um plano diferente do seu plano atual',
	'Plan successfully canceled': 'Plano cancelado com sucesso',
	'Password changed successfully': 'Senha alterada com sucesso',

	'Customer plan not found': 'Plano do cliente não encontrado',
	'Customer data not found': 'Dados do cliente não encontrados',
	'User access not found': 'Permissão do usuário não encontrada',
	'File deleted successfully': 'Arquivo excluído com sucesso',

	'Check the parameters entered': 'Verifique os dados inseridos',
	'Number of document characters are invalid': 'Número de caracteres do documento são inválidos',
	'Invalid inserted CPF': 'CPF inserido inválido',
	'Invalid inserted CNPJ': 'CNPJ inserido inválido',

	'It is necessary to spend a day after contracting the service to change the plan':
		'É necessário passar um dia após a contratação do serviço para alterar o plano.',

	'Fake communication failure': 'Falha na comunicação',

	'Forgot password': 'Esqueceu sua senha?',
	'Log in': 'Acessar',
	'Please input your password!': 'Insira uma senha',
	'Invalid email or password': 'E-mail ou senha inválidos',
	'The input is not a valid e-mail!': 'Este e-mail não é válido!',
	'Please input your e-mail': 'Por favor, digite seu e-mail',
	'Inactive account, please activate': 'Conta invativa, por favor ativar',
	Send: 'Enviar',
	'User not found': 'Usuário não encontrado',
	'Success, check your email box': 'Sucesso, veja sua caixa de e-mail',
	'Password recovery': 'Redefinição de senha',
	'Token invalid': 'Requisição inválida',
	'Token expired, generated a new one': 'Sessão expirada, tente novamente',
	'Please confirm your password!': 'Por favor confirme sua senha',
	'Confirm Password': 'Confirme sua senha',
	'The two passwords that you entered do not match!': 'As duas senhas não conferem, verifique',
	Confirm: 'Confirmar',
	Generate: 'Gerar',
	Validate: 'Validar',
	Continue: 'Continuar',
	'Reset token': 'Recuperar',
	Cancel: 'Cancelar',
	flexion: 'Flexão',
	turned: 'Virado',
	extension: 'Extensão',
	sideways: 'Lateralmente',
	crouched: 'Agachado',
	downed: 'Abaixado',
	arm_raised: 'Braço levantado',
	behind: 'Atrás',
	raised: 'Levantado',
	none: 'Nenhum',

	// Check-in Customer
	'Registered successfully!': 'Cadastro realizado com sucesso!',
	'Just a little longer, check your e-mail inbox': 'Agora falta pouco, verifique sua caixa de entrada no email',
	Access: 'Acessar',
	'Kinebot - Terms of use': 'Kinebot - Termos de uso',
	'terms of use': 'termos de uso',
	"Welcome! Let's start": 'Seja bem-vindo, vamos começar!',
	'Enter your phone number': 'Insira seu telefone',
	'Select your contry': 'Selecione seu país',
	State: 'Estado',
	'State:': 'Estado:',
	'Select your state': 'Selecione o seu estado',
	'Select your city': 'Selecione a sua cidade',
	'Zip code': 'CEP',
	'Enter your zipcode': 'Insira o seu código postal',
	'Enter your address': 'Insira o seu endereço',
	'Enter your street number': 'Insira o número da rua',
	'Coupon (optional)': 'Cupom (opcional)',
	Coupon: 'Cupom',

	// User Activation
	'There are problems on your operation.': 'Existem alguns problemas com a sua operação.',
	Leave: 'Sair',
	Data: 'Dados',
	'Final steps': 'Finalização',
	'Card number': 'Número do cartão',
	'Enter your credit card number': 'Insira o número do cartão',
	'Invalid credit card': 'Cartão inválido',
	'Card holder name': 'Nome do titular',
	'Enter the card holder name': 'Insira o nome do titular',
	'Due date': 'Validade',
	"Enter the card's due date": 'Insira a validade do cartão',
	'Invalid month': 'Mês inválido',
	'Invalid card date': 'Data do cartão inválida',
	'Enter the security code': 'Insira o código de segurança',
	'Invalid code': 'Código inválido',

	// Maintenance
	"We are going through maintenance, we'll be back shortly.": 'Estamos em manutenção, voltamos em breve.',

	// Finalization
	'Personal data': 'Dados pessoais',
	'Document no.': 'CPF',
	'You will only be charged after the free test period, it can be cancelled at anytime.':
		'O valor da cobrança só será efetuado após o período gratuito, podendo ser cancelado a qualquer momento.',
	Discounts: 'Descontos',
	'I have read and agree with the': 'Eu li e concordo com os',
	Close: 'Fechar',

	// User Activation - Information
	'Validating coupon': 'Validando cupom',
	"Couldn't find the coupon": 'Não foi possível buscar o cupom',
	Individual: 'Individual',
	Team: 'Equipe',
	Business: 'Empresarial',
	'Enter your document no.': 'Insira o CPF',
	'Enter a valid document': 'Insira um CPF válido',
	'Enter a phone number': 'Insira um telefone',
	'Enter the zip code': 'Insira o CEP',
	'Enter the city': 'Insira a cidade',
	'Enter the state': 'Insira o estado',
	'Street number': 'Número da rua',
	'Street number:': 'Número da rua:',

	// Credit Card
	'Under review': 'Cartão sob revisão',
	'Insufficient funds': 'Saldo insuficiente',
	'Not accepted': 'Transação inválida',
	'Customer already registered': 'Usuário já cadastrado',
	'Failed to perform transaction': 'Falha ao realizar a transação',

	'email must be unique': 'E-mail já registrado',

	'Error loading video': 'Erro ao carregar o vídeo',
	'Failed to fetch your plan information.': 'Falha ao obter as informações do seu plano',
	'Failed to fetch total customer videos.': 'Falha ao buscar o total de vídeos do cliente',
	'Maximum uploads reached.': 'Máximo de uploads atingido',
	'Maximum minutes reached.': 'Máximo de minutos atingido',
	'Corrupted video.': 'Vídeo corrompido',

	// Painel control
	'Maximum users reached': 'Máximo de usuários cadastrados',

	"The 'name' field is required.": 'O campo nome é obrigatório.',
	"The 'email' field is required.": 'O campo email é obrigatório.',
	"The 'country' field is required.": 'O campo país é obrigatório.',
	"The 'state' field is required.": 'O campo estado é obrigatório.',
	"The 'city' field is required.": 'O campo cidade é obrigatório.',
	"The 'address' field is required.": 'O campo endereço é obrigatório.',
	"The 'street_number' field is required.": 'O campo número é obrigatório.',
	"The 'zipcode' field is required.": 'O campo código postal é obrigatório.',

	// ********************************** //
	'Personal information': 'Informações pessoais',
	Localization: 'Localização',

	'Full name': 'Nome completo',
	'Full name:': 'Nome completo:',
	'Enter your full name': 'Insira seu nome completo',
	'Enter your name': 'Insira seu nome',
	'Insert a document': 'Insira um documento',
	'Insert a valid document': 'Insira um documento válido',
	Address: 'Endereço',
	'Address:': 'Endereço:',
	'Enter an address': 'Insira um endereço',
	Number: 'Número',
	'Enter the number': 'Insira o número',
	Zipcode: 'CEP',
	'Zipcode:': 'Código postal:',
	'Enter zip code': 'Insira o código postal',
	Country: 'País',
	'Country:': 'País:',
	'Select your country': 'Selecione o seu país',
	'Select state': 'Selecione o estado',
	City: 'Cidade',
	'City:': 'Cidade:',
	'Select city': 'Selecione a cidade',

	'Update performed': 'Operação realizada',
	'Customer information updated successfully': 'Informações atualizadas com sucesso',
	'Fill in your personal information': 'Complete suas informações pessoais',

	// Forgot password
	'Forgot your password?': 'Esqueceu sua senha?',
	'We will send you an e-mail to regain access to your account, rest assured!':
		'Iremos enviar um e-mail para você recuperar o acesso à sua conta, fique tranquilo!',
	'Back to login': 'Voltar para o login',
	'Please enter a valid e-mail address': 'Insira um e-mail válido',
	'Enter an e-mail': 'Insira um e-mail',

	'Oops... Something went wrong!': 'Ops... Algo deu errado!',
	'Operation performed successfully': 'Operação realizada com sucesso',
	'Check your email inbox': 'Verifique sua caixa e-mails',
	'Create new password': 'Crie uma nova senha',
	'Your new password must be different from previous used passwords':
		'Sua nova senha deve ser diferente das senhas usadas anteriormente',
	'Enter your new password': 'Insira sua nova senha',
	'Confirm new password': 'Confirme a nova senha',
	'The two passwords that you entered do not match': 'As duas senhas que você digitou não coincidem',
	Save: 'Salvar',
	'Click here to continue': 'Clique aqui para continuar',
	'Invalid Token': 'Token inválido',
	'Expired token': 'Token expirado',

	trunk: 'Tronco',
	neck: 'Pescoço',
	left_lower_arm: 'Cotovelo esquerdo',
	right_lower_arm: 'Cotovelo direito',
	left_upper_arm: 'Ombro esquerdo',
	right_upper_arm: 'Ombro direito',
	left_knee: 'Joelho esquerdo',
	right_knee: 'Joelho direito',
	left_ankle: 'Tornozelo esquerdo',
	right_ankle: 'Tornozelo direito',
	hip: 'Quadril',
	left_hand: 'Punho esq.',
	right_hand: 'Punho dir.',

	Knee: 'Joelho',
	Ankle: 'Tornozelo',
	Hip: 'Quadril',
	Hand: 'Punho',
	Qualification: 'Qualificação',
	'Select the body parts to display': 'Selecione as partes do corpo a serem exibidas',

	'Light risk': 'Risco leve',
	'Average risk': 'Risco médio',

	Level: 'Nível',
	'Limit of bands reached': 'Limite de bandas atingido',

	'Industrial site': 'Planta industrial',
	Period: 'Período',
	Search: 'Buscar',
	Clear: 'Limpar',

	'Select company': 'Selecionar empresa',

	//User management
	'Create new user': 'Criar novo usuário',
	Email: 'Email',
	Permissions: 'Permissões',
	'Companies - Industrial sites': 'Empresas - Plantas industriais',

	'Change password': 'Alterar senha',
	'Current password': 'Senha atual',
	'New password': 'Nova senha',
	'Confirm password': 'Confirmar senha',
	'Enter your current password': 'Insira sua senha atual',
	'Enter a new password': 'Insira uma nova senha',
	'Password must be at least 6 characters long': 'A senha deve ter no mínimo 6 caracteres',
	'Repeat your new password': 'Repita sua nova senha',
	"The two passwords don't match, check": 'As duas senhas não conferem, verifique',
	'Sorry time exceeded, try again.': 'Desculpe, o tempo foi excedido, tente novamente',
	'Invalid current password': 'Senha atual inválida',
	'Update information': 'Atualizar informações',

	'Phone number': 'Telefone',
	'Account type': 'Tipo da conta',
	'Created in': 'Criada em',
	'Postal code': 'CEP',
	'Sorry, something went wrong :(': 'Desculpe, algo deu errado :(',
	Retry: 'Tentar novamente',

	// Layout - Sidebar
	Settings: 'Configurações',
	'My account': 'Minha conta',
	Plan: 'Plano',
	'Subscription type': 'Tipo de assinatura',
	'Subscription type:': 'Tipo de assinatura:',
	Recurrence: 'Recorrência',
	'Recurrence:': 'Recorrência:',
	'Expiration date': 'Data de expiração',
	'Plan expiration date:': 'Data de expiração do plano:',
	'Active users': 'Usuários ativos',
	'Processed videos': 'Vídeos processados',
	'Processed videos:': 'Vídeos processados:',
	'Processing time': 'Tempo de processamento',
	'Processing time:': 'Tempo de processamento:',
	'Additional minutes': 'Minutos adicionais',
	'Add credits': 'Adicionar créditos',
	'Renew subscription': 'Renovar assinatura',
	'Change subscription': 'Alterar assinatura',
	'Cancel subscription': 'Cancelar assinatura',
	of: 'de',
	minutes: 'minutos',

	Test: 'Teste',

	Minutes: 'Minutos',
	'Consolidate data': 'Consolidar dados',
	'Export report': 'Exportar relatório',
	'There is no post with screwdriver / screwdriver': 'Não tem posto com parafusadeira/apertadeira',
	'Data not found': 'Dados não encontrados',
	'Requires actions': 'Requer ações',
	'No actions required': 'Não requer ações',
	'High Risk and Requires Action': 'Alto Risco e Requer ações',
	'Moderate risk and no action required': 'Risco Moderado e Não requer ações',
	'Low Risk and No Action Required': 'Baixo Risco e Não requer ações',
	'Intensity of work': 'Intensidade do trabalho',
	'Work organization': 'Organização do trabalho',
	'Is spontaneous breaks allowed during the workday?':
		'É permitido pausas espontâneas durante a jornada de trabalho?',
	'Is there a diversification of tasks in the performance of your functions?':
		'No desempenho de suas funções há diversificação de tarefas?',
	'Materials are always available at the workstation, avoiding rush, delay or overload?':
		'Os materiais sempre estão disponíveis no posto de trabalho evitando correria, atraso ou sobrecarga?',
	Complexity: 'Complexidade',
	'Movements do not require high dexterity': 'Movimentos não exigem alta destreza',
	'Low level of attention requirement for long periods': 'Baixo nível de exigência de atenção por longos períodos',
	'Workstation is complex and high cognitive demand': 'Posto é complexo e de alta exigência cognitiva',
	'Workstation is simple and of low cognitive requirement': 'Posto é simples e de baixa exigência cognitiva',
	'Displacement ': 'Deslocamento ',
	'Squat ': 'Agachamento ',
	Cervical: 'Cervical',
	'Left Shoulder/Arm': 'Ombro/Braço Esq.',
	'Right Shoulder/Arm': 'Ombro/Braço Dir.',
	'Left wrist': 'Punho esq.',
	'Right wrist': 'Punho dir.',
	'No repeatability': 'Sem repetividade',
	'With repetition': 'Com repetividade',
	' x per hour': ' x por hora',
	'Without risk': 'Sem risco',
	'Action Level': 'Nível de ação',
	'Complementary resources': 'Fatores complementares',
	'Percussion tools ': 'Ferramentas de percussão ',
	'Hit against ': 'Batidas contra ',
	'Present Risk': 'Risco Presente',
	'Low risk': 'Baixo Risco',
	'Global Ranking': 'Classificação Global',
	'Operator report': 'Relato do operador',
	'Enter operator report below': 'Insira abaixo o relato do operador',
	'Easy to understand information': 'Informação de fácil compreensão',

	'≤ 10 steps per minute': '≤ 10 passos por minuto',
	'15 to 25 steps per minute': 'de 15 a 25 passos por minuto',
	'Above 25 steps per minute': 'Acima de 25 passos por minuto',

	'Inform below the displacement of the operator during the workday':
		'Informar abaixo o deslocamento do operador durante a jornada de trabalho',
	'Regulation notes': 'Notas de regulação',
	'Spontaneous breaks are allowed during the workday (e.g. drinking water, drinking coffee, going to the bathroom)':
		'É permitido pausas espontâneas durante a jornada de trabalho (ex.: tomar água, beber café, ir ao banheiro)',
	'In the performance of their functions, there is diversification of tasks':
		'No desempenho de suas funções há diversificação de tarefas',
	'Materials are always available at the workstation, avoiding rush, delay or overload':
		'Os materiais sempre estão disponíveis no posto de trabalho evitando correria, atraso ou sobrecarga',
	'Work intensity: give a score from 1 to 7 (1 being a little work and 7 being very intense work)':
		'Intensidade do trabalho: dê uma nota de 1 a 7 (sendo 1 pouco trabalho e 7 trabalho intensíssimo)',

	Wrist: 'Punho',
	'Hit against': 'Batidas',
	'Enter job information below': 'Insira abaixo informações sobre o posto de trabalho',
	'During the operation, the operator taps his hands to adjust parts or components.':
		'O operador realiza durante a operação batidas com as mãos para fazer ajuste de peças ou componentes.',

	'Add a companion video for wrist analysis': 'Adicione um vídeo complementar para análise do punho',
	'Do not include in the analysis': 'Não incluir na análise',
	'Include external video': 'Incluir vídeo externo',
	'Enter video name': 'Digite o nome do vídeo',

	'Sledgehammer and/or taps are hits from hard hits (except hit adjustments)':
		'Marreta e/ou tas so batidas de batidas fortes (exceto ajustes de batida)',
	'Light - Quiet': 'Leve - Tranquilo',
	'Medium - Some effort is perceived (defined)': 'Médio - Percebe-se algum esforço (definido)',
	'Heavy - Clear effort: no change in facial expression': 'Pesado - Esforço nitido: sem mudança de expressão facial',
	'Very heavy - Sharp effort: changes facial expression': 'Muito pesado - Esforço nitido: muda a expressão facial',
	'Next. maximum - Use of torso and limbs to generate greater force':
		'Próximo. máximo - Uso do tronco e membros para gerar força maior',

	// ------- //
	'Workstation Information': 'Informação de trabalho',

	'Enter operator difficulty control below': 'Insira abaixo o controle de dificuldade do operador',
	'Control Difficulty': 'Dificuldade de controle',
	'Select a cost center': 'Selecione o centro de custo',

	'Enter data about vibration in employee activity below':
		'Insira abaixo os dados sobre a vibração na atividade do funcionário, caso não tenha não é necessário preencher',

	"Enter below the data on the employee's reaction in the activity":
		'Insira abaixo os dados sobre a reação do colaborador na atividade',
	'Insert a new reaction': 'Insira uma nova reação',
	'Insert a new vibration': 'Insira uma nova vibração',

	"Enter below the data of the employee's effort in the activity":
		'Insira abaixo os dados sobre o esforço do colaborador na atividade',

	'Insert a new effort': 'Insira um novo esforço',
	'Cost center': 'Centro de custo',
	'Enter a workstation': 'Insira o posto de trabalho',

	'Enter the data related to the selected video below': 'Insira abaixo os dados relacionados ao vídeo selecionado',

	Staff: 'Quadro de colaboradores',
	'Enter the Company Activities': 'Insra o CNAE',
	'Insert a production line': 'Insira a linha de produção',
	'Add new range': 'Adicionar nova banda',
	'industrial site': 'planta industrial',
	'Select a industrial site': 'Selecione a planta industrial',
	'Production line': 'Linha de produção',
	'Select a production line': 'Selecione a linha de produção',

	Role: 'Cargo/Função',
	Code: 'Código',

	'Tax Code': 'CNPJ',
	'Enter a Tax Number': 'Insira o CNPJ',
	'Insert a valid Tax Number': 'Insira um CNPJ válido',

	'Select a role': 'Selecione um cargo/função',
	'Insert the role': 'Insira o cargo/função',
	'User type': 'Tipo de usuário',
	'User type:': 'Tipo de usuário:',
	Document: 'Documento',
	'Document:': 'Documento:',
	'Current plan': 'Plano atual',
	'Not informed': 'Não informado',

	'Risk ranges': 'Bandas de risco',
	'Local application': 'Local de aplicação',
	'Units to be analyzed': 'Unidades a serem analisadas',
	'Select the units that will be included in the review': 'Selecione as unidades que serão incluídas na revisão',
	Factory: 'Fábrica',
	'Select the industrial sites to be analyzed': 'Selecione as plantas industriais a ser analizadas',
	'Production Volume': 'Volume de produção',
	Investments: 'Investimentos',
	'Enter production volumes below': 'Insira os volumes de produção abaixo',
	'Inform below the investments in ergonomics': 'Informe abaixo os investimentos em ergonomia',

	Date: 'Data',
	'Date of reference': 'Data de referência',
	'Date of the report': 'Data do relatório',

	'Ergonomic analysis of work': 'Análise ergonômica do trabalho',
	Presentation: 'Apresentação',
	'Plant Information': 'Informações da planta',
	'Insert a company name': 'Insira o nome da empresa',
	'Risk degree': 'Grau de risco',
	Activity: 'Atividade',
	District: 'Bairro',
	Administrative: 'Administrativo',
	'Work Shift': 'Turno de Trabalho',
	Operational: 'Operacional',
	'Direct hourly worker': 'Horista direto',
	'Indirect hourly worker': 'Horista indireto',
	'Monthly worker': 'Mensalista',
	'Normal working hours': 'Horário normal de trabalho',

	// AET
	'Include checklists related to this analysis below': 'Inclua abaixo as checklists relacionadas a essa análise',
	'Non-standard scale': 'Escala não padronizada',

	// Bodyparts
	'Lower members': 'Membros inferiores',
	'Left elbow': 'Cotovelo esquerdo',
	'Right elbow': 'Cotovelo direito',
	'Left shoulder': 'Ombro esquerdo',
	'Right shoulder': 'Ombro direito',
	'Left knee': 'Joelho esquerdo',
	'Right knee': 'Joelho direito',
	'Left ankle': 'Tornozelo esquerdo',
	'Right ankle': 'Tornozelo direito',

	/* Nomenclature v2 */
	'Left hand': 'Mão esquerda',
	'Right hand': 'Mão direita',
	'Video description': 'Descrição de vídeo',
	Below: 'Abaixo de',
	Above: 'Acima de',

	Checklist: 'Checklist',
	'Frequency and duration': 'Frequência e duração',

	'Date of collection': 'Data de coleta',

	'Load from 2 kg ≤ 10 kg (intermittent)': 'Carga de 2 kg a 10 kg (intermitente)',
	'Load from 2 kg ≤ 10 kg (static or repetitive)': 'Carga de 2 kg a 10 kg (estático ou repetitivo)',
	'Load more than 10 kg of repetitive load or blows': 'Carga maior 10 kg de carga repetitivos ou golpes',

	Medium: 'Médio',
	Invalid: 'Inválido',

	Safe: 'Aceitável',
	Info: 'Leve',
	Danger: 'Perigo',

	safe: 'aceitável',
	info: 'leve',
	warning: 'alto',
	danger: 'perigo',

	'Corrective action not required': 'Ação corretiva não necessária',
	'Corrective action as soon as possible': 'Ação corretiva o mais rápido possível',
	'High priority corrective action': 'Ação corretiva de alta prioridade',
	'Immediate corrective action': 'Ação corretiva imediata',
	'Unable to show results': 'Não foi possível mostrar os resultados',

	/* Nomenclature */
	Line: 'Linha',
	Effort: 'Esforço',
	Reaction: 'Reação',
	Vibration: 'Vibração',
	'Beats against': 'Batidas contras',
	Percussion: 'Percussão',
	Hands: 'Mãos',
	'Shoulders/Arms': 'Ombros/Braços',
	Left: 'Esquerda',
	Right: 'Direita',
	Exit: 'Sair',
	Filters: 'Filtros',

	/* Login - Page */
	// Placeholder's
	'E-mail': 'E-mail',
	'E-mail:': 'E-mail',

	// Validation's errors inputs
	'Enter a valid e-mail': 'Insira um e-mail válido',
	'Enter your password': 'Insira sua senha',
	Login: 'Entrar',

	// SideMenu
	Dashboard: 'Dashboard',
	Upload: 'Upload',
	PEA: 'AEP',
	EAW: 'AET',

	// Dashboard
	'Comparison over the last 6 months': 'Comparativo nos ultimos 6 meses',
	/*************/

	'Are you sure?': 'Tem certeza?',
	'Are you sure you want to quit? You have an upload in process.':
		'Tem certeza que deseja sair? Você tem um upload em processo',

	// Upload filter
	'Select a company': 'Selecione uma empresa',
	'Select an industrial site': 'Selecione uma planta industrial',
	'Select a line': 'Selecione a linha',
	'Select the workstation': 'Selecione um posto de trabalho',

	// Chart
	'No data available': 'Não há dados',
	'There is no registered data.': 'Não há dados cadastrados.',

	// Layout
	Logout: 'Sair',

	// Report
	"Approximated values, depends mainly on the filming methods. In case there is no data, the algorithm didn't find the points to determine the angle, please review the filming method used.":
		'Valores aproximados, dependentes da técnica de filmagem adotada. Caso não tenha dados o algoritmo não identificou os pontos para determinar o ângulo, observar técnicas de filmagem.',
	'The results obtained from each body part can be affected by the method and position of filming, the identification of the person in the analysis can suffer interference from objects present in the room. The angle data shown are approximate values.':
		'Os resultados obtidos em cada membro do corpo poderão ser impactados de acordo com a forma e posição de gravação, a identificação da pessoa que é analisada pode sofrer interferência de objetos presentes no local de operação. Os dados dos ângulos mostrados são valores aproximados.',
	Reference: 'Referência',
	'Investigate and change immediately': 'Investigar e mudar imediatamente',
	'Analysis of the movement by angle': 'Análise do movimento por ângulo',
	'Analysis of the movement by score': 'Análise do movimento com score',
	'Analysis of the movement & Body parts': 'Análise do movimento & Partes do corpo',
	'Report types': 'Tipos de relatórios',
	'Generate reports': 'Gerar relatórios',
	Finish: 'Finalizar',
	'Description already exists!': 'Descrição já existente!',
	'Enter a new sector': 'Insira um novo setor',
	'Do you wish to delete this sector?': 'Deseja excluir esse setor?',
	'This sector is used in other reports, do you really wish to delete it?':
		'Esse setor está sendo utilizado em outros relatórios, tem certeza que deseja excluir?',
	'Sector created successfully!': 'Setor criado com sucesso!',
	"This operation couldn't be completed": 'Não foi possível realizar esta ação',
	Sectors: 'Setores',
	'Fill in the essential information below.': 'Preencha abaixo algumas informações essencias.',
	'Select the sector from the above analysis': 'Selecione o setor da análise acima',
	'Select the sector for the analysis': 'Selecione o setor da análise',
	Add: 'Adicionar',
	'Enter the workstation': 'Insira o posto de trabalho',
	'Enter the date the collection was made': 'Insira a data em que a coleta foi feita',
	'Select the tool for the analysis and the type of report you wish to generate.':
		'Selecione a ferramenta que deseja aplicar para a análise e o tipo de relatório que deseja gerar.',
	Tool: 'Ferramenta',
	'Pick the tool above': 'Selecione a ferramenta acima',
	'Select the type of tool': 'Selecione o tipo da ferramenta',
	Report: 'Relatório',
	'Select the type of report above': 'Selecione o tipo de relatório acima',
	'Select the type of report': 'Selecione o tipo de relatório',

	Squatting: 'Agachado',
	Video: 'Vídeo',
	'Very high': 'Muito alto',
	'Not identified': 'Não identificado',
	Comments: 'Comentários',
	'Comments:': 'Comentários:',
	Filter: 'Filtrar',
	Company: 'Empresa',
	'Select the company': 'Selecione a empresa',
	'Select the industrial site': 'Selecione a planta industrial',
	Function: 'Função',
	'Enter the function': 'Insira a função',
	'Customized reports': 'Relatórios customizados',

	// Settings
	"We weren't able to change your password": 'Não foi possível alterar a sua senha',
	'Warning!': 'Atenção!',
	'Failed to fetch requested information': 'Falha ao buscar informações solicitadas',
	'Do you really wish to cancel your plan?': 'Deseja realmente cancelar seu plano?',
	'After cancelling your plan, when the expiration date comes, you will have no more access to the platform.':
		'Ao cancelar seu plano, após a data de expiração atual você não terá acesso a plataforma.',
	"We weren't able to cancel your plan": 'Não foi possível cancelar o plano',
	"Select a plan that's different from your current one": 'Selecione um plano diferente do seu plano atual',
	"We couldn't process the operation": 'Não foi possível realizar a operação',
	'Plan cancelled on:': 'Plano cancelado em:',
	'Active users:': 'Usuários ativos:',
	'Plan switch': 'Alteração de plano',
	'You have recently changed your plan': 'Recentemente você alterou sua assinatura',
	'Plan type:': 'Tipo de assinatura:',
	'New plan start date:': 'Data de início do novo plano:',
	'User limit:': 'Limite de usuários:',
	'Upload limit:': 'Limite de uploads:',
	'Processing time limit:': 'Limite de minutos de processamento:',
	'Cancel plan': 'Cancelar plano',
	'Error when trying to verify industrial sites from company.':
		'Erro ao verificar plantas industriais dentro da companhia.',
	'Error when trying to delete the company': 'Erro ao deletar empresa',
	'Enter a permission': 'Insira uma permissão',
	'Select a permission': 'Selecione uma permissão',
	'Edit user': 'Editar usuário',
	'User changed successfully': 'Usuário alterado com sucesso',
	Permission: 'Permissão',
	'Do you wish to delete this user?': 'Deseja excluir este usuário',
	Active: 'Ativo',
	Disabled: 'Desabilitado',

	// Manage companies
	'Manage companies': 'Gerenciar empresas',
	'Manage industrial sites': 'Gerenciar plantas industriais',
	'Heads up! This company is not empty': 'Atenção! Esta empresa não está vazia',
	'To continue, first remove industrial sites belonging to this company.':
		'Para continuar, remova as plantas industriais pertencentes a esta empresa.',
	'All industrial sites in this company will be deleted, along with their respective uploads and reports. Do you wish to proceed?':
		'Todas as plantas industriais dessa empresa serão deletadas, em conjunto com seus uploads e relatórios. Tem certeza que deseja prosseguir?',
	'All uploads and reports from this industrial site will be deleted. This action cannot be undone. Do you wish to continue?':
		'Todos os uploads e relatórios dessa planta industrial serão deletados. Esta ação não pode ser desfeita. Tem certeza que deseja prosseguir?',
	'Failed to update information': 'Falha ao atualizar a informação',
	'Edit company': 'Editar empresa',
	Loading: 'Carregando',
	'Loading...': 'Carregando...',
	'Company name': 'Nome da empresa',
	'Industrial sites': 'Plantas industriais',
	'Updated information': 'Informação atualizada',
	'Error while changing the name of the industrial site': 'Erro ao editar o nome da planta industrial',
	Inactive: 'Inativo',
	All: 'Todos',
	'Organization created successfully': 'Organização foi criada com sucesso',
	'Organization updated successfully': 'Organização foi atualizada com sucesso',
	'Organization deleted successfully': 'Organização foi removida com sucesso',
	'Data created successfully': 'Dados foram criados com sucesso',
	'Data updated successfully': 'Dados foram atualizados com sucesso',
	'Data deleted successfully': 'Dados foram removidos com sucesso',
	'Please contact support to reactivate it': 'Por favor, entre em contato com o suporte para reativar novamente',
	'Create a new workstation': 'Criar um novo posto de trabalho',
	'Create a new sector': 'Criar um novo setor',

	// ui/Reports
	'1 - Acceptable posture if not repeated or kept for long periods':
		'1 - Postura aceitável se não for repetida ou mantida durante longos períodos',
	'2 - Investigate; possibility to request for change; it is convenient to introduce alterations':
		'2 - Investigar; possibilidade de requerer mudanças; é conveniente introduzir alterações',
	'3 - Investigate; make changes quickly': '3 - Investigar; realizar mudanças rapidamente',
	'4 - Immediate change': '4 - Mudanças imediatas',
	'Acceptable posture if not repeated or kept for long periods':
		'Postura aceitável se não for repetida ou mantida durante longos períodos',
	'Investigate; possibility to request for change; it is convenient to introduce alterations':
		'Investigar; possibilidade de requerer mudanças; é conveniente introduzir alterações',
	'Investigate; make changes quickly': 'Investigar; realizar mudanças rapidamente',
	'Immediate change': 'Mudanças imediatas',
	Ergonomist: 'Ergonomista',
	'Left lower arm': 'Antebraço esquerdo',
	'Right lower arm': 'Antebraço direito',
	'Left upper arm': 'Braço esquerdo',
	'Right upper arm': 'Braço direito',
	Type: 'Tipo',

	// views/Report/Niosh
	'Manual Lifting Report': 'Relatório - Levantamento Manual',
	'Enter the job characteristics below.': 'Entre com as características do trabalho abaixo.',
	'Gender of the worker': 'Genero do trabalhador',
	'Select the gender': 'Selecione o gênero',
	Age: 'Idade',
	'Select the age': 'Selecione a idade',
	'How many workers are lifting the object?': 'Quantos trabalhadores levantam o objeto?',
	'Which hands are used?': 'Quais mãos são utilizadas?',
	One: 'Um',
	Two: 'Dois',
	Three: 'Três',
	'Both hands': 'Duas mãos',
	'One hand': 'Uma mão',
	'Select the coupling quality': 'Selecione a qualidade da pega',
	'Select the lifting frequency': 'Selecione a frequência do levantamento',
	'Select the lifting duration': 'Selecione a duração do levantamento',
	'Lifting frequency (lifts per minute)': 'Frequência de levantamento (levant./min)',
	Poor: 'Pobre',
	Fair: 'Razoável',
	Good: 'Boa',
	'1 hour': '1 hora',
	'1 - 2 hours': '1 a 2 horas',
	'2 - 8 hours': '2 a 8 horas',
	'Enter the task variables below.': 'Insira abaixo as variáveis do trabalho.',
	'Please enter a value': 'Insira um valor',
	'Weight of the object': 'Peso do objeto',
	'Distance the object is moved vertically': 'Distância que o objeto é deslocado verticalmente',
	'Vertical distance of the object at the beginning of handling, relative to the ground':
		'Distância vertical do objeto no inicio do manuseio, relativa ao solo',
	'Horizontal distance of the object relative to the body': 'Distância horizontal do objeto, relativa ao corpo',
	'Rotation angle': 'Ângulo de rotação',
	'Recommended Weight Limit (RWL)': 'Limite de peso recomendado (LPR)',
	'Lifting Index (LI)': 'Índice de levantamento (IL)',
	'Coupling factor: ': 'Fator de pega: ',
	'Frequency factor: ': 'Fator frequência: ',
	'lifts / min': 'levant. / min',
	'Report date': 'Data do relatório',
	'Comment:': 'Comentário:',
	Details: 'Detalhes',
	Risk: 'Risco',
	'Very low risk': 'Risco muito baixo',
	Measurements: 'Medidas',
	'Lifted by ': 'Levantado por ',
	'1 worker': '1 pessoa',
	'2 workers': '2 pessoas',
	'3 workers': '3 pessoas',
	Using: 'Usando',
	'only one hand': 'apenas uma mão',
	'both hands': 'as duas mãos',
	'Reference weight': 'Peso de referência',
	'Enter the required values': 'Insira os valores obrigatórios',
	'Report created successfully': 'Relatório criado com sucesso',
	'Report updated successfully': 'Relatório atualizado com sucesso',
	'Want to delete this report?': 'Deseja remover este relatório?',
	'Report deleted successfully': 'Relatório removido com sucesso',
	'Comment updated successfully': 'Comentário atualizado com sucesso',
	'Document created successfully': 'Documento criado com sucesso',

	// NIOSH - Conclusions
	Conclusions: 'Conclusões',
	Conclusion: 'Conclusão',
	'The weight to be lifted': 'O peso a ser levantado',
	'is greater than the recommended weight limit (RWL)': 'é maior do que o limite de peso recomendado (LPR)',
	'is less than the recommended weight limit (RWL)': 'é menor do que o limite de peso recomendado (LPR)',
	' The lifting index (LI)': ' O índice de levantamento (IL)',
	'is more than 3.': 'é maior do que 3.',
	'is more than 2 and less than 3.': 'está entre 2 e 3.',
	'is more than 1,5 and less than 2.': 'está entre 1,5 e 2.',
	'is more than 1 and less than 1,5.': 'está entre 1 e 1,5.',
	'is less than 1.': 'é menor do que 1.',
	' There is a very high risk. These values indicate that this lift would be hazardous for a majority of healthy industrial workers.':
		' O risco é muito alto. Estes valores indicam que o levantamento seria perigoso para a maioria dos trabalhadores saudáveis.',
	' There is a high risk. These values indicate that this lift would be hazardous for a majority of healthy industrial workers.':
		' O risco é alto. Estes valores indicam que o levantamento seria perigoso para a maioria dos trabalhadores saudáveis.',
	' There is a moderate risk. These values indicate that this job is somewhat stressful.':
		' O risco é moderado. Estes valores indicam que a tarefa é um pouco rigorosa.',
	' There is a low risk. These values indicate that this job is adequate for the majority of industrial workers, but some may have trouble.':
		' O risco é baixo. Estes valores indicam que a tarefa é adequada para a maioria dos trabalhadores, poucos poderiam encontrar problemas.',
	' There is a very low risk. These values indicate that this job is adequate for the majority of industrial workers.':
		' O risco é muito baixo. Estes valores indicam que a tarefa é adequada para a maioria dos trabalhadores.',
	Male: 'Masculino',
	Female: 'Feminino',
	Man: 'Homem',
	Woman: 'Mulher',
	'20 to 45 years old': '20 a 45 anos',
	'20 to 45': '20 a 45',
	'< 20 years old': '< 20 anos',
	'> 45 years old': '> 45 anos',
	'Download PDF': 'Baixar PDF',

	// NIOSH - Critical factors
	'The most critical variables are listed below with suggestions:':
		'As variáveis mais críticas estão listadas abaixo com sugestões de melhoria:',
	'Bring the load closer to the worker by removing any horizontal barriers or reducing the size of the object. Lifts near the floor should be avoided.':
		'Leve o peso para mais próximo do trabalhador removendo qualquer barreira horizontal ou reduzindo o tamanho do objeto. Levantamentos próximos ao piso devem ser evitados se possível.',
	'Raise/lower the origin/destination of the lift. Avoid lifting near the floor or above the shoulders.':
		'Eleve a origem ou desça o destino do levantamento. Evite levantamentos próximos ao piso ou acima dos ombros.',
	'Reduce the vertical distance between the origin and the destination of the lift.':
		'Reduza a distância vertical entre a origem e o destino do levantamento.',
	'Move the origin and destination closer together to reduce the angle of twist, or move them further apart to force the worker to turn the feet and step, rather than twist the body.':
		'Mova a origem e o destino mais pertos um do outro para reduzir o ângulo de torção, ou deixe-os mais afastados para que o trabalhador precise dar um passo sem torcer o corpo.',
	'Reduce the lifting frequency rate, reduce the lifting duration, or provide longer recovery periods (i.e., light work period).':
		'Reduza a frequência de levantamento, reduza a duração do levantamento ou proporcione períodos de descanso maiores (tarefas menos exigentes).',
	'Improve the hand-to-object coupling by providing optimal containers with handles or handhold cutouts, or improve the handholds for irregular objects.':
		'Melhore a facilidade de agarre providenciando alças ou recortes para as mãos. Para objetos irregulares, melhore os apoios para as mãos.',
	'Consider using both hands when lifting the weight.': 'Considere utilizar as duas mãos ao realizar o levantamento.',
	'One handed': 'Usando uma mão',
	'Distance H': 'Distância H',
	'Distance Vc': 'Distância Vc',
	'Distance Dc': 'Distância Dc',
	'Angle A': 'Ângulo A',
	'Frequency/Duration': 'Frequência/Duração',
	Coupling: 'Pega',
	Characteristics: 'Características',
	Variables: 'Variáveis',
	Result: 'Resultado',
	'Choose a gender': 'Escolha um gênero',
	Gender: 'Gênero',
	Pairs: 'Pares',

	// STRAIN INDEX REPORT
	'Report Results - Revised Strain Index': 'Relatório - Strain Index Revisado',
	'Intensity of exertion': 'Intensidade do esforço',
	'Efforts per minute': 'Esforços por minuto',
	'Number of exertions': 'Número de esforços',
	'Observation time': 'Tempo de observação',
	'Duration per exertion': 'Duração por esforço',
	'Hand/wrist posture': 'Postura da mão/punho',
	'Type of wrist posture': 'Tipo de postura do punho',
	'Hand/wrist angle': 'Ângulo da mão/punho',
	'Duration of task per day': 'Duração da tarefa por dia',
	'Revised Strain Index (RSI)': 'Strain Index Revisado (RSI)',
	'Risk factor': 'Fator de risco',
	Input: 'Entrada',
	'Job is probably safe': 'A tarefa é provavelmente segura',
	'Job is probably hazardous': 'A tarefa é provavelmente perigosa',
	Degrees: 'Graus',
	'Duration/day': 'Duração/dia',
	'Intensity of exertion (Borg Scale - BS)': 'Intensidade do esforço (Borg Scale - BS)',
	'Somewhat Hard': 'Pouco Difícil',
	Hard: 'Difícil',
	'Very Hard': 'Muito Difícil',
	'Near Maximal': 'Quase Máxima',
	'Risk factors 1': 'Fatores de risco 1',
	'Risk factors 2': 'Fatores de risco 2',
	'Barely noticeable or relaxed effort (BS: 0-2)': 'Quase imperceptivel ou esforço mínimo (BS: 0-2)',
	'Noticeable or definite effort (BS: 3)': 'Esforço perceptível (BS: 3)',
	'Obvious effort; Unchanged facial expression (BS: 4-5)':
		'Esforço óbvio; Sem mudanças na expressão facial (BS: 4-5)',
	'Substantial effort; Changes expression (BS: 6-7)': 'Esforço substancial; Mudanças na expressão facial (BS: 6-7)',
	'Uses shoulder or trunk for force (BS: 8-10)': 'Utiliza ombros ou tronco para força (BS: 8-10)',
	'Number of exertions observed': 'Núm. de esforços observados',
	'Total observation time': 'Tempo total da observação',
	'Average single exertion time': 'Tempo médio de cada esforço',
	'(total exertions * single exertion time) must be less than or equal to the total observation time':
		'(núm. de esforços * tempo médio por esforço) deve ser menor ou igual ao tempo da observação',
	seconds: 'segundos',
	hours: 'horas',
	Extension: 'Extensão',
	Flexion: 'Flexão',
	'Revised Strain Index (Moore and Garg)': 'Strain Index Revisado (Moore e Garg)',
	'Report Results': 'Relatório',
	'Revised Strain Index': 'Strain Index Revisado',
	'(Moore and Garg)': '(Moore e Garg)',

	// REBA
	'REBA Report Results': 'Relatório REBA',
	'REBA score of the workplace': 'Pontuação REBA do posto de trabalho',
	'Left leg': 'Perna esquerdo',
	'Right leg': 'Perna direita',
	'Load less than 5kg (intermittent)': 'Carga menor do que 5kg (intermitente)',
	'Load from 5kg to 20kg (intermittent)': 'Carga de 5kg a 20kg (intermitente)',
	'Load from 5kg to 20kg (static or repetitive)': 'Carga de 5kg a 20kg (estática ou repetitiva)',
	'Bigger load 20kg of repetitive load or blows': 'Carga maior do que 20kg de repetição ou de pancadas',
	Unacceptable: 'Inaceitável',
	Force: 'Força',
	Load: 'Carga',
	'Select the load': 'Selecione a carga',
	'Select the number of repetitions': 'Selecione o número de repetições',
	'Score left': 'Score esquerdo',
	'Score right': 'Score direito',
	Acceptable: 'Aceitável',
	Investigate: 'Investigar',
	'Investigate and change soon': 'Investigar e mudar logo',
	'Investigate and change now': 'Investigar e mudar agora',
	'Investigate and Implement change': 'Investigar e implementar mudanças',
	'Implement change': 'Implementar mudanças',
	'REBA Score': 'Score REBA',
	'RULA Score': 'Score RULA',
	'The 4 most critical body parts': 'As 4 partes mais críticas',
	'Viewing the selected body part': 'Visualizando a parte selecionada',
	minute: 'minuto',
	'Requested video data is old, please upload to continue':
		'Os dados do vídeo solicitado são antigos, faça o upload para continuar',
	'View score': 'Ver pontuação',
	'Unable to view': 'Não foi possivel a visualização',
	'Worst grade found in time': 'Pior score encontrado no tempo',
	'Worst score': 'Pior score',
	'The results obtained in each body part may be impacted by the shape and position of filming, and the identification of the person being analyzed may be interfered by objects present in the operating location. The angle data shown are approximate values, dependent on the adopted filming technique. If there is no data, the algorithm did not identify the points to determine the angle, so it is important to observe filming techniques.':
		'Os resultados obtidos em cada membro do corpo podem ser impactados de acordo com a forma e posição de gravação, e a identificação da pessoa que está sendo analisada pode sofrer interferência de objetos presentes no local de operação. Os dados dos ângulos mostrados são valores aproximados, dependentes da técnica de filmagem adotada. Caso não tenha dados, o algoritmo não identificou os pontos para determinar o ângulo, por isso é importante observar técnicas de filmagem.',

	// Angle time
	'Angle by time': 'Ângulo no Tempo',
	'Create risk range': 'Criar faixa de risco',
	'Report Results - Angle by Time': 'Relatório - Ângulo no Tempo',
	'Please fill all required fields': 'Favor preencher todos os campos necessários',

	// ACTION PLANS
	'Do you want to delete this action plan?': 'Gostaria de remover este plano de ação?',
	'Action plan created successfully': 'Plano de ação criado com sucesso',
	'Action plan updated successfully': 'Plano de ação atualizado com sucesso',
	'Create action plan': 'Criar plano de ação',
	'Update action plan': 'Atualizar plano de ação',
	'Please enter a valid description': 'Por favor, digite uma descrição válida',
	'Action plan description': 'Descrição do plano de ação',
	'Please enter a valid action plan description': 'Por favor, insira uma descrição válida do plano de ação',
	'Person responsible': 'Responsável',
	Responsible: 'Responsável',
	'Who is responsible for this action plan?': 'Quem é o responsável por este plano de ação?',
	'Describe the situation found and the preventive measures already implemented (if exist)':
		'Descreva a situação encontrada e as medidas preventivas já implementadas (caso existam)',
	'Finish editing your other task first': 'Primeiro finalize a edição da outra tarefa',
	'Add a new task': 'Adicione uma nova tarefa',
	History: 'Histórico',
	'Finish by: ': 'Concluir até: ',
	'On time': 'No prazo',
	Overdue: 'Atrasado',
	'Finishing in a week': 'Finalizando em uma semana',
	'Person responsible updated': 'Responśavel pelo plano de ação atualizado',
	'Deadline updated': 'Prazo do plano de ação atualizado',
	Tasks: 'Tarefas',
	'Task created': 'Tarefa criada',
	'Task deleted': 'Tarefa removida',
	'Task renamed': 'Tarefa renomeada',
	'Task type changed': 'Tipo da tarefa modificada',
	'Task and type changed': 'Tarefa e tipo alterado',
	'Task checked': 'Tarefa concluída',
	'Task unchecked': 'Tarefa desfeita',
	'Action plan created': 'Plano de ação criado',
	'Action plan renamed': 'Plano de ação renomeado',
	'Status changed': 'Status alterado',
	'Deadline changed': 'Prazo alterado',
	'by ': 'por ',
	'Successfully updated action plan data': 'Dados do plano de ação atualizados com sucesso',
	'Responsible user changed': 'Responsável alterado',
	'Action plan title': 'Título do plano de ação',
	'Enter action plan title': 'Insira o título do plano de ação',
	Title: 'Título',
	'Select a file': 'Selecione um arquivo',
	'Select an option': 'Selecione uma opção',
	'Please enter a valid title': 'Por favor, digite um título válido',
	'Please enter a responsible': 'Por favor, insira um responsável',
	'Please enter a valid date': 'Por favor, insira uma data válida',
	'Please select a condition': 'Por favor, selecione uma condição',
	'Search company': 'Buscar empresa',
	'Search industrial site': 'Buscar planta industrial',

	'Enter a description for your action plan.': 'Insira uma descrição para seu plano de ação.',
	'Select a condition': 'Selecione uma condição',
	Eliminate: 'Eliminar',
	Control: 'Controlar',
	Compensate: 'Compensar',
	ELIMINATE: 'ELIMINAR',
	CONTROL: 'CONTROLAR',
	COMPENSATE: 'COMPENSAR',

	// KIM - MHO
	'Error, cannot get "': 'Erro, não é possível obter "',
	'" from "': '" de "',
	'Total duration of this sub-activity per working day': 'Duração total da atividade por dia trabalhado',
	'Total duration per day worked': 'Duração total por dia trabalhado',
	'Force exerted on hand/fingers (L|R)': 'Força exercida na mão/dedos (E|D)',
	'Power transfer/gripping conditions': 'Transferência de energia/condições de aderência',
	'gripping conditions': 'condições de aderência',
	'Power transfer': 'Transferência de energia',
	'Type of force exertion in the finger/hand area within a “standard minute”':
		'Força exercida na área da mão/dedos em um "minuto padrão"',
	'Force transfer / gripping conditions': 'Transferência de força / condições de pega',
	'Hand/arm position and movement': 'Posição e movimento da mão/braço',
	'Unfavourable working conditions': 'Condições de trabalhos desfavoráveis',
	'Work organization / temporal distribution': 'Organização de trabalho / distribuição temporal',
	'Body posture/movement': 'Postura e movimento do corpo',
	'Very low': 'Muito baixa',
	Moderate: 'Moderada',
	'Peak forces': 'Forças de pico',
	'Powerful hitting': 'Batidas fortes',
	'Up to 15% Fmax. Button actuation, shifting, ordering, material guidance, insertion of small parts.':
		'Até 15% de Fmax. Acionamento de botão, deslocamento, ordenamento, orientação de material, inserção de peças pequenas.',
	'Up to 30% Fmax. Gripping, joining small work pieces by hand or with small tools.':
		'Até 30% de Fmax. Agarrar ou unir peças pequenas com a mão ou com ferramentas pequenas.',
	'Up to 50% Fmax. Turning, winding, packaging, grasping, holding or joining parts, pressing in, cutting, working with small powered hand tools.':
		'Até 50% de Fmax. Tornear, enrolar, embalar, agarrar, segurar ou unir peças, pressionar, cortar, trabalhar com pequenas ferramentas manuais motorizadas.',
	'Up to 80% Fmax. Cutting involving major element of force, working with small staple guns, moving or holding parts or tools.':
		'Até 80% de Fmax. Corte envolvendo grande elemento de força, trabalhando com pequenas pistolas de grampo, movendo ou segurando peças ou ferramentas.',
	'More than 80% Fmax. Tightening, loosening bolts, separating, pressing in.':
		'Mais de 80% de Fmax. Apertar, desapertar parafusos, separar, pressionar.',
	'Hitting with the ball of the thumb, palm of the hand or fist.':
		'Golpear com a ponta do polegar, palma da mão ou punho.',
	'Optimum force transfer/application': 'Transferência/aplicação de força ideal',
	'Restricted force transfer/application': 'Transferência/aplicação de força restrita',
	'Force transfer/application considerably hindered':
		'Transferência/aplicação de força consideravelmente prejudicada',
	'Optimum force application': 'Aplicação de força ideal',
	'Restricted force application': 'Aplicação de força restrita',
	'Force application considerably hindered': 'Aplicação de força consideravelmente prejudicada',
	'Working objects are easy to grip (e.g. bar-shaped, gripping grooves) / good ergonomic gripping design (grips, buttons, tools).':
		'Objetos de trabalho são fáceis de segurar (por exemplo, em forma de barra, ranhuras de preensão) / bom design ergonômico de preensão (alças, botões, ferramentas).',
	'Greater holding forces required / no shaped grips.':
		'Maiores forças de pega necessárias / alças sem formato ergonômico.',
	'Working objects hardly possible to grip (slippery, soft, sharp edges) / no or only unsuitable grips.':
		'Objetos de trabalho dificeis de segurar (escorregadios, macios, bordas afiadas) / apenas alças inadequados ou nenhuma.',
	Restricted: 'Restrita',
	Unfavourable: 'Desfavorável',
	'Position or movements of joints in the middle (relaxed) range, only rare deviations, no continuous static arm posture, hand-arm rest possible as required.':
		'Posição ou movimentos das articulações no intervalo médio (relaxado), apenas desvios raros / sem postura estática contínua do braço / apoio mão-braço possível conforme necessário.',
	'Occasional positions or movements of the joints at the limit of the movement ranges, occasional long continuous static arm posture.':
		'Posições ou movimentos ocasionais das articulações no limite das amplitudes de movimento, postura de braço ocasionalmente estática, longa e contínua.',
	'Frequent positions or movements of the joints at the limit of the movement ranges, frequent long continuous static arm posture.':
		'Posições ou movimentos das articulações frequentemente no limite das amplitudes de movimento, postura de braço frequentemente estática, longa e contínua.',
	'Constant positions or movements of the joints at the limit of the movement ranges, constant long continuous static arm posture.':
		'Posições ou movimentos das articulações constantemente no limite das amplitudes de movimento, postura de braço constantemente estática, longa e contínua.',
	'Frequent variation of the physical workload situation due to other activities (including other types of physical workload) / without a tight sequence of higher physical workloads within one type of physical workload during a single working day.':
		'Variação frequente da situação de carga de trabalho física devido a outras atividades (incluindo outros tipos de carga de trabalho física) / sem uma sequência estreita de cargas de trabalho físicas mais altas dentro de um tipo de carga de trabalho física durante um único dia de trabalho.',
	'Rare variation of the physical workload situation due to other activities (including other types of physical workload) / occasional tight sequence of higher physical workloads within one type of physical workload during a single working day.':
		'Variação rara da situação de carga de trabalho física devido a outras atividades (incluindo outros tipos de carga de trabalho física) / sequência restrita ocasional de cargas de trabalho físicas mais altas dentro de um tipo de carga de trabalho física durante um único dia de trabalho.',
	'No/hardly any variation of the physical workload situation due to other activities (including other types of physical workload) / frequent tight sequence of higher physical workloads within one type of physical workload during a single working day with concurrent high load peaks.':
		'Nenhuma/quase nenhuma variação da situação de carga de trabalho física devido a outras atividades (incluindo outros tipos de carga de trabalho física)/sequência estreita frequente de cargas de trabalho físicas mais altas dentro de um tipo de carga de trabalho físico durante um único dia de trabalho com picos de carga elevados simultâneos.',
	'There are no unfavourable working conditions, i.e. reliable recognition of detail / no dazzle / good climatic conditions.':
		'Não há condições de trabalho desfavoráveis, ou seja, reconhecimento confiável de detalhes / sem encandeamento / boas condições climáticas.',
	'Occasionally impaired detail recognition due to dazzle or excessively small details difficult conditions such as draught, cold, moisture and/or disturbed concentration due to noise.':
		'Reconhecimento de detalhes ocasionalmente prejudicado devido a ofuscamento ou detalhes excessivamente pequenos condições difíceis como corrente de ar, frio, umidade e/ou concentração perturbada devido ao ruído.',
	'Frequently impaired detail recognition due to dazzle or excessively small details frequently difficult conditions such as draught, cold, moisture and/or disturbed concentration due to noise.':
		'Reconhecimento de detalhes frequentemente prejudicado devido a ofuscamento ou detalhes excessivamente pequenos condições frequentemente difíceis, como correntes de ar, frio, umidade e/ou concentração perturbada devido ao ruído.',
	'Alternation between sitting and standing, alternation between standing and walking, dynamic sitting possible':
		'Alternância entre sentado e em pé, alternância entre em pé e andando, assento dinâmico possível',
	'Trunk inclined forward only very slightly': 'Tronco inclinado para a frente apenas muito ligeiramente',
	'No twisting and/or lateral inclination of the trunk identifiable':
		'Sem torção e/ou inclinação lateral do tronco identificável',
	'Head posture: variable, head not inclined backward and/or severely inclined forward or constantly moving':
		'Postura da cabeça: variável, cabeça não inclinada para trás e/ou severamente inclinada para frente ou em constante movimento',
	'No gripping above shoulder height / no gripping at a distance from the body':
		'Sem preensão acima da altura do ombro/sem preensão à distância do corpo',
	'Predominantly sitting or standing with occasional walking':
		'Predominantemente sentado ou em pé com caminhada ocasional',
	'Trunk with slight inclination of the body towards the work area':
		'Tronco com leve inclinação do corpo em direção à área de trabalho',
	'Occasional twisting and/or lateral inclination of the trunk identifiable':
		'Torção ocasional e/ou inclinação lateral do tronco identificável',
	'Occasional deviations from good “neutral” head posture/movement':
		'Desvios ocasionais da boa postura/movimento “neutro” da cabeça',
	'Occasional gripping above shoulder height / occasional gripping at a distance from the body':
		'Apreensão ocasional acima da altura do ombro/apreensão ocasional à distância do corpo',
	'Exclusively standing or sitting without walking': 'Exclusivamente em pé ou sentado sem andar',
	'Trunk clearly inclined forward and/or frequent twisting and/or lateral inclination of the trunk identifiable':
		'Tronco claramente inclinado para frente e/ou torção frequente e/ou inclinação lateral do tronco identificável',
	'Frequent deviations from good “neutral” head posture/movement':
		'Desvios frequentes da boa postura/movimento “neutro” da cabeça',
	'Head posture hunched forward for detail recognition / restricted freedom of movement':
		'Postura da cabeça curvada para a frente para reconhecimento de detalhes/liberdade de movimento restrita',
	'Frequent gripping above shoulder height / frequent gripping at a distance from the body':
		'Preensão frequente acima da altura do ombro/preensão frequente à distância do corpo',
	'Trunk severely inclined forward / frequent or long-lasting bending':
		'Tronco fortemente inclinado para a frente/flexão frequente ou de longa duração',
	'Work being carried out in a kneeling, squatting, lying position':
		'Trabalho sendo realizado em posição ajoelhada, agachada e deitada',
	'Constant twisting and/or lateral inclination of the trunk identifiable':
		'Torção constante e/ou inclinação lateral do tronco identificável',
	'Body posture strictly fixed / visual check of action through magnifying glasses or microscopes':
		'Postura corporal estritamente fixa/verificação visual da ação através de lupas ou microscópios',
	'Constant deviations from good “neutral” head posture/movement':
		'Desvios constantes da boa postura/movimento “neutro” da cabeça',
	'Constant gripping above shoulder height / constant gripping at a distance from the body':
		'Pegada constante acima da altura do ombro/pegada constante a uma distância do corpo',
	'Manual Handling': 'Manuseio Manual',
	'Manual Handling Operations': 'Operações de Manuseio Manual',
	'KIM - Manual Handling Operations': 'KIM - Operações de Manuseio Manual',
	'Total duration per working day': 'Duração total por dia trabalhado',
	'Probability of physical overload': 'Probabilidade de sobrecarga fisica',
	'Possible health consequences': 'Possíveis consequências à saúde',
	Measures: 'Medidas',
	'Physical overload is unlikely.': 'Sobrecarga física não é provável.',
	'No health risk is to be expected.': 'Nenhum risco à saúde é esperado.',
	'None.': 'Nenhum.',
	'Physical overload is possible for less resilient persons.':
		'Sobrecarga física é possível para pessoas menos resilientes.',
	'Fatigue, low-grade adaptation problems which can be compensated for during leisure time.':
		'Fadiga, problemas de adaptação de baixo grau que podem ser compensados durante o tempo livre.',
	'For less resilient persons, workplace redesign and other prevention measures may be helpful.':
		'Para pessoas menos resilientes, mudanças no local de trabalho e outras medidas preventivas podem ser de ajuda.',
	'Physical overload is possible for normally resilient persons.':
		'Sobrecarga física é possível para pessoas normalmente resilientes.',
	'Disorders (pain), possibly including dysfunctions, reversible in most cases, without morphological manifestation.':
		'Distúrbios (dor), possivelmente incluindo disfunções, reversíveis na maioria dos casos, sem manifestação morfológica.',
	'Workplace redesign and other prevention measures should be considered.':
		'Mudanças no local de trabalho e outras medidas preventivas devem ser consideradas.',
	'Physical overload is likely.': 'Sobrecarga física é provável.',
	'More pronounced disorders and/or dysfunctions, structural damage with pathological significance.':
		'Distúrbios e/ou disfunções mais pronunciados, danos estruturais com significado patológico.',
	'Workplace redesign measures are necessary. Other prevention measures should be considered.':
		'Mudanças no local de trabalho são necessárias, outras medidas preventivas devem ser consideradas.',
	'Slightly increased': 'Ligeiramente elevada',
	'Substantially increased': 'Substancialmente elevada',
	'Type of force exertion in the finger/hand area': 'Tipo de esforço realizado na área da mão/dedos',
	'Intensity of force exertion in the finger/hand area within a “standard minute”':
		'Intensidade da força exercida na área da mão/dedos em um "minuto padrão"',
	'No measures need to be taken.': 'Nenhuma medida precisa ser tomada.',
	Holding: 'Segurando',
	Moving: 'Movendo',
	'Left arm': 'Braço esquerdo',
	'Right arm': 'Braço direito',

	'Time and force': 'Tempo e força',
	Conditions: 'Condições',
	Posture: 'Postura',

	'Select the body parts you are interested in': 'Selecione as partes do corpo que lhe interessam',
	'Select all': 'Selecionar todos',

	'Shoulder L': 'Ombro E',
	'Shoulder R': 'Ombro D',
	'Elbow L': 'Cotovelo E',
	'Elbow R': 'Cotovelo D',
	'Leg L': 'Perna E',
	'Leg R': 'Perna D',

	Cycle: 'Ciclo',
	'Select one of the vehicles or a wheel type:': 'Selecione um dos veículos ou um tipo de roda',
	Conveyors: 'Transportador',
	Cranes: 'Guindastes',
	'Driveway conditions:': 'Condições do piso:',
	'Select the mass to be transported (in kg):': 'Selecione a massa a ser transportada (em kg):',
	'Inclination or stairs:': 'Inclinação ou escadas:',
	'Conditions:': 'Condições:',

	'Increased movement speed (approx. 1.0 up to 1.3 m/s)': 'Maior velocidade de movimento (aprox. 1,0 até 1,3 m/s)s',

	//Error
	'length must be less than or equal to 50 characters long': 'comprimento deve ser menor ou igual a 50 caracteres',

	'Preliminary Ergonomic Analysis': 'Avaliação Ergonômica Preliminar',
	'Ergonomic Analysis': 'Avaliação Ergonômica',
	'Ergonomic analysis': 'Avaliação ergonômica',

	//Super Preliminary Ergonomic Analysis

	'Basic information': 'Informações básicas',
	'Basic Information': 'Informações Básicas',
	"To generate this document, it's necessary that the EWA's must be consolidated, so the list below only shows the consolidated EWA's.":
		"Para gerar esse documento é necessário que as AEP's sejam consolidadas, por isso a lista abaixo apresenta apenas as AEPs consolidadas.",

	// Basic Informartion Step
	'Report Information': 'Dados do relatório',
	'Report name': 'Nome do relatório',
	'Enter report name': 'Digite o nome do relatório',
	'Select a Company': 'Selecione uma empresa',
	'Select the reference date': 'Selecione a data de referência',
	'Select the report date': 'Selecione a data do relatório',

	// Select PEA Step
	'File Name': 'Nome do arquivo',
	'Created At': 'Criado em',
	'Date of creation': 'Data de criação',
	'Analyst Name': 'Analista',
	Submit: 'Finalizar',

	//Signatures Step
	Signatures: 'Assinaturas',
	'Enter with a role': 'Digite o cargo/função',
	'Enter with a name of responsible': 'Digite o nome do responsável',
	'Add a new signature': 'Adicione uma assinatura',
	'Responsible name': 'Nome do responsável',

	// Conclusion Step
	'Check selected Files': 'Confira os Arquivos selecionados',
	'Files List': 'Lista de Arquivos',
	'Selected EWA': 'Análises Selecionadas.',
	'EWA created successfully': 'AEP criada com sucesso.',
	'Failed to upload PEA report.': 'Falha ao criar a AEP.',

	// Super PEA File History
	'Preliminary Ergonomic Analyzes': 'Avaliações Ergonômicas Preliminares',
	'Selected Files': 'Arquivos selecionados',
	'Worst Score': 'Pior Avaliação',
	'Open File List': 'Abrir Lista de Arquivos',
	'EWA deleted successfully': 'PEA removida com sucesso.',

	//Others words
	Men: 'Homens',
	Women: 'Mulheres',
	Likelihood: 'Probabilidade',
	Exposure: 'Exposição',
	'Possible Injuries/Harms': 'Possíveis lesões/danos',
	Job: 'Atividade',
	'Assignment note': 'Nota da Tarefa',
	'Weight Limit recommended (WLR)': 'Limite de peso recomendado (LPR)',
	'Lifting index (LI)': 'Índice de elevação (IL)',
	Comment: 'Comentário',
	Particularities: 'Particularidades',
	Production: 'Produção',
	'Working regime': 'Regime de trabalho',
	EWA: 'AEP',
	'New document': 'Novo documento',
	'File history': 'Histórico de arquivos',

	//Action plans cards
	'Fill in the details above': 'Preencha os dados acima',

	'Risk Acceptable': 'Risco Aceitável',

	'Immediate action for improvement and reassess': 'Ação Imediata de melhoria e reavaliar',

	'Improvement action': 'Ação de melhoria',

	'Risk Very High': 'Risco Muito Elevado',
	'Perform Ergonomic Work Analysis': 'Realizar Análise Ergonômica do Trabalho',

	'Risk Serious and Imminent': 'Risco Grave e Iminente',
	'Stop the activity and carry out an Ergonomic Work Analysis':
		'Parar a atividade e realizar análise ergonômica do trabalho',

	//Possible injuries
	'Possible injuries': 'Possíveis lesões/agravos',
	'Fatigue complaints': 'Queixas de fadiga',
	Absenteeism: 'Absenteismo',
	'Musculoskeletal complaints': 'Queixas osteomusculares',
	'Complaints of mental overload': 'Queixas de esgotamento mental',
	'Complaints of irritability': 'Queixas de irritabilidade',
	'Nonconforming products': 'Produtos não conforme',
	'Low back pain complaints': 'Queixas de lombalgia',
	'Elbow pain': 'Dor no cotovelo',
	'Shoulder pain': 'Dor no ombro',
	Headache: 'Dor de cabeça',
	'Unexcused absences': 'Faltas não justificadas',
	'Neck pain': 'Dor de pescoço',
	'Wrist pain': 'Dor no punho',

	//Consequence
	Consequence: 'Consequência',
	'Severe with fatalities and serious injuries': 'Severo com mortes e lesões graves',
	'Temporary disability': 'Incapacidade temporária',
	'Injuries with leave': 'Lesões/dano com afastamentos',
	'Severe with possibility of death or disability': 'Grave com possibilidade de morte ou incapacidade',
	'Superficial injuries': 'Lesões/dano superficiais',
	'Catastrophic with numerous deaths': 'Catastrófico com inúmeras mortes',

	//Probability
	Probability: 'Probabilidade',
	'Most likely and expected outcome': 'Resultado mais provável e esperado',
	'Extremely remote possibility': 'Extremamente remota a possibilidade',
	'Practically impossible, never happened': 'Praticamente impossível, nunca ocorreu',
	'Unlikely, but it has happened': 'Pouco provável, mas já ocorreu',
	'Possible outcome': 'Resultado possível',
	'Rare but possible': 'Raro, porém, possível ',

	//Exhibition
	'Remotely (never occurred)': 'Remotamente (nunca ocorreu)',
	'Rarely (occurs in short bursts without definition)': 'Raramente (ocorre em curtos espaços sem definição)',
	'Irregular (once a month to once a year)': 'Irregular (uma vez ao mês até uma vez ao ano)',
	'Occasionally (once a week to once a month)': 'Ocasionalmente (uma vez na semana até uma vez ao mês)',
	'Frequent (at least once a day)': 'Frequente (pelo menos uma vez ao dia)',
	'Continues (several times a day)': 'Contínua (várias vezes ao dia)',
	'Remotely (>90% green)': 'Remotamente (>90% verde)',
	'Rarely (80 to 89% green)': 'Raramente (80 a 89% verde)',
	'Irregular (50 to 79% green)': 'Irregular (50 a 79% verde)',
	'Occasionally (30 to 49% green)': 'Ocasionalmente (30 a 49% verde)',
	'Frequent (10 to 29% green)': 'Frequente (10 a 29% verde)',
	'Continues (<9% green)': 'Contínua (<9% verde)',

	//Steps erros
	'Select the injury': 'Selecione uma ou mais lesões/agravos',
	'Select the injuries': 'Selecione uma ou muitas lesões/agravos',
	'Select the consequence': 'Selecione a Consequência',
	'Select the probability': 'Selecione a Probabilidade',
	'Select the exposure': 'Selecione a Exposição',
	"Some required steps wasn't filled": 'Dados obrigatórios não preenchidos',
	"Some required step wasn't filled": 'Dados obrigatórios não preenchidos',

	//Step 1
	Score: 'Nota',
	Informations: 'Informações',
	'Select the sector': 'Selecione um setor',
	Analyst: 'Analista',
	'Insert the analyst': 'Insira um analista',
	'Insert the responsibility': 'Insira um cargo/função',
	'Insert the cell': 'Insira uma célula',
	'Insert the activity': 'Insira uma atividade',
	'Insert the workstation': 'Insira um posto de trabalho',
	'Enter the collection date': 'Insira a data de coleta',

	//Step 2
	'Work Conditions': 'Condições de trabalho',
	'Select the constraints perceived in the evaluated work condition':
		'Selecione as restrições percebidas na condição de trabalho avaliada',
	'Work schedule': 'Horário de trabalho',
	'Summary description of the place': 'Descrição sumária do local',
	'Simplified description of the expected task': 'Descrição simplificada da tarefa prevista',
	'Simplified description of the task performed': 'Descrição simplificada da tarefa realizada',

	//Step 3
	'Working population': 'População trabalhadora',
	'Working population percentages must sum up to 100%.':
		'As porcentagens da população trabalhadora devem somar 100%.',
	"Worker's verbalization": 'Verbalização dos trabalhadores',
	'Self-evaluation': 'Autoavaliação',
	'What is the level of stress perceived by the worker when performing the task?':
		'Qual é o nível de estresse percebido pelo trabalhador ao realizar a tarefa?',
	'Nothing stressful': 'Nada estressante',
	'Little stressful': 'Pouco estressante',
	'Working population must be greater than 0': 'A população de trabalhadores deve ser maior que 0',
	'Total working population must be greater than 0': 'A população total de trabalhadores deve ser maior que 0',
	workers: 'trabalhadores',
	Stressful: 'Estressante',
	'Update the tool': 'Atualize a ferramenta',
	'Tool already set': 'Ferramenta já definida',
	'Tool not set yet': 'Ferramenta ainda não definida',
	'Very stressful': 'Muito estressante',
	'Extremely stressful': 'Extremamente estressante',

	//Step 4
	'Workday and concentration at work': 'Jornada e concentração no trabalho',
	'Working hours of more than 8 hours a day': 'Jornada de trabalho superior à 8h por dia',
	'Existence of long and frequent overtime hours of work':
		'Existência de longas e frequentes horas extras de trabalho',
	'Stays long time continuous operation': 'Permanece longo tempo de operação contínua',
	'Insufficient rest interval': 'Intervalo de descanso insuficiente',
	'Insufficient rest break': 'Intervalo de descanso insuficiente',
	'Insufficient rest days': 'Dias de descanso insuficientes',
	'Unbalanced concentrations of work in a day, week, month or year':
		'Concentrações desequilibradas de trabalho em um dia, semana, mês ou ano',
	'Unbalanced concentrations of work among workers': 'Concentrações desequilibradas de trabalho entre trabalhadores',
	'Insufficient rest between shifts (less than 11 hours)': 'Descanso insuficiente entre turnos (menos de 11 h)',
	'Remains long time continuous operation': 'Permanece longo tempo de operação contínua',

	//Step 5
	'Kind of work': 'Tipo de trabalho',
	'Lift and/or carry heavy objects': 'Levanta e/ou carrega objetos pesados',
	'Apply the tool': 'Aplique a ferramenta',
	'Load surveys': 'Carregar pesquisas',
	'Lifts and/or carries objects farther than 60 cm': 'Levanta e/ou carrega objetos mais distantes que 60 cm',
	'Performs tasks that require great strength': 'Realiza tarefas que requerem grande força',
	'Performs tasks that require pronounced pushing and pulling forces':
		'Realiza tarefas que exigem forças acentuadas para empurrar e puxar',
	'Performs repetitive work': 'Realiza trabalho repetitivo',
	'Performs work that requires frequent finger, hand, or arm movements':
		'Realiza trabalho que requer movimentos frequentes de dedo, mão ou braço',
	'Do intensive work with a keyboard or other input devices':
		'Realiza trabalho intensivo com um teclado ou outros dispositivos de entrada de dados',
	'Perform precision work': 'Realiza trabalho de precisão',
	'Performs activities with high visual requirements': 'Realiza atividades de requisitos visuais elevados',

	//Step 6
	'Postures and movements': 'Posturas e movimentos',
	'Stays in uncomfortable postures and movements': 'Permanece em posturas e movimentos desconfortáveis',
	'Performs continuous and/or highly frequent change in joint positions':
		'Realiza mudança contínua e/ou altamente frequente nas posições das articulações',
	'Stays in constrained posture for long duration': 'Permanece em postura restrita de longa duração',
	'Walk for long duration and/or long distance (on a horizontal surface as well as an inclined surface)':
		'Realiza caminhada de longa duração e/ou longa distância (em superfície horizontal bem como em uma superfície inclinada)',
	'Performs frequent stair climbing': 'Realiza subida de escada frequente',
	'Remains in a sedentary/standing position for a long time':
		'Permanece tempo prolongado em posição sedentária/de pé',
	'Postures requirements (Kinebot)': 'Exigências posturais (Kinebot)',

	//Step 7
	'Influence of workspace and task factors': 'Influência do espaço de trabalho e de fatores da tarefa',
	'Workspace is inadequate and forces an uncomfortable posture or limited movement':
		'O espaço de trabalho é inadequado e força uma postura desconfortável ou movimento limitado',
	'Workstation layout requires excessive movement or uncomfortable postures':
		'O leiaute do posto de trabalho exige movimento excessivo ou posturas desconfortáveis',
	'The heights, distances and dimensions of controls or work surfaces are inadequate':
		'As alturas, distâncias e dimensões dos comandos ou superfície de trabalho são inadequadas',
	'Anthropometry Table x Furniture (cm)': 'Tabela da Antropometria x Mobiliário (cm)',
	'Add information': 'Adicionar informações',
	'Found (cm)': 'Encontrado (cm)',
	'Foreseen (cm)': 'Previsto (cm)',
	'Enter a location': 'Qual a localização',
	'Enter what was found': 'Onde foi encontrado',
	'Insert predicted': 'Insira a previsão',
	'Performs handling of work objects above the shoulder or below the knee':
		'Realiza manuseio de objetos de trabalho acima do ombro ou abaixo do joelho',
	'The workspace forces the worker to maintain the same working posture':
		'O espaço de trabalho força o trabalhador a manter a mesma postura de trabalho',
	'Handles work objects that are difficult to handle and slippery':
		'Manuseia objetos de trabalho que sejam difíceis de manusear ou escorregadios',
	'Activity requires high-voltage contact or local pressure acting on the body':
		'Atividade exige alta-tensão de contato ou pressão local que age no corpo',

	//Step 8
	'Influence of psychosocial factors': 'Influência de fatores psicossociais',
	'Activity with mental overload or underload': 'Atividade com sobrecarga ou subcarga mental',
	'Work pace with time pressure and high demands': 'Ritmo de trabalho com pressão de tempo e altas demandas',
	'Presence of work-related stress': 'Presença estresse relacionado ao trabalho',
	'Reports of low job satisfaction': 'Relatos de baixa satisfação no trabalho',
	'Activity with lack of autonomy (low influence, low control)':
		'Atividade com falta de autonomia (baixa influência, controle baixo)',
	'Low social support': 'Baixo apoio social',

	//Step 9
	'Influence of environmental factors': 'Influência de fatores do meio ambiente',
	'Place with slippery and/or uneven floor': 'Local com piso escorregadio e/ou irregular',
	'There is exposure to whole body vibration (see ISO 2631)':
		'Existe exposição a  vibração em todo o corpo (ver ISO 2631)',
	'Makes use of hand-arm vibration tools during work; (see ISO 5349)':
		'Faz uso de ferramentas de vibração mão-braço durante o trabalho) (ver ISO 5349)',
	'Performs work using vehicles that transmit vibration throughout the body':
		'Realiza trabalho usando veículos que transmitem vibração em todo o corpo',
	'Hot or cold working environment': 'Local ambiente de trabalho quente ou frio',
	'Location with background noise': 'Local com ruido de fundo ',

	//Step 10
	'Influence of organizational factors': 'Influência de fatores organizacionais',
	'Production standards are not clear or understood by workers':
		'As normas de produção não são claras ou compreendidas pelos trabalhadores',
	'The operative mode descriptions are insufficient or wrong':
		'As descrições de modo operatório são insuficientes ou erradas',
	'The time to carry out the activities is inadequate': 'O tempo para realizar as atividades é inadequado',
	'The pace of work is accelerated': 'O ritmo de trabalho é acelerado',
	'The content of the tasks is different from the technical means available':
		'O conteúdo das tarefas é diferente dos meios técnicos disponíveis ',
	'Task with high cognitive demand': 'Tarefa com grande exigência cognitiva',

	// Results
	'Anthropometry Table': 'Tabela de antropometria',
	'Consolidate analysis': 'Consolidar análise',
	Deconsolidate: 'Desconsolidar',
	'Successfully consolidated analysis': 'Análise consolidada com sucesso',
	'Successfully deconsolidated analysis': 'Análise desconsolidada com sucesso',
	'Failed to generate the report': 'Falha ao gerar o relatório',
	'Failed to generate PDF': 'Falha ao gerar o PDF',

	'Cannot change a consolidated analysis': 'Não é possível alterar uma análise consolidada',
	'Comments successfully saved': 'Comentários salvos com sucesso',

	'Action plan deleted successfully': 'Plano de ação removido com sucesso',
	'Task created successfully': 'Tarefa criada com sucesso',
	'Task deleted successfully': 'Tarefa removida com sucesso',
	'Task updated successfully': 'Tarefa atualizada com sucesso',

	'Ops... something happened!': 'Ops... algo aconteceu!',
	'It was not possible to save your information': 'Não foi possível salvar suas informações',

	Hazardous: 'Perigoso',
	'Movement by score (Kinebot/REBA)': 'Movimento por score (Kinebot/REBA)',
	'Cargo Handling (NIOSH)': 'Manuseio de carga (NIOSH)',
	'Manual Handling (KIM)': 'Operações de manuseio manual (KIM)',
	'Push and Pull (KIM)': 'Empurrar e puxar (KIM)',
	'No more action plans available': 'Não há mais planos de ação disponíveis',
	'No more workstations available': 'Não há mais postos de trabalho disponíveis',

	'No task available': 'Não há tarefas disponíveis',
	day: 'dia',
	days: 'dias',

	Location: 'Localização',
	Found: 'Encontrado',
	Foreseen: 'Previsto',
	Condition: 'Condição',

	'NOT OK': 'NÃO OK',
	"Score's sum": 'Somatório',

	// Do not change due to AEP key - Influences the translation of action plans created by AEP
	'Trunk posture from Kinebot': 'Postura do tronco da Kinebot',
	'Trunk posture from kinebot': 'Postura do tronco da kinebot',
	'Neck posture from Kinebot': 'Postura do pescoço da Kinebot',
	'Neck posture from kinebot': 'Postura do pescoço da kinebot',
	'Left elbow posture from Kinebot': 'Postura do cotovelo esquerdo da Kinebot',
	'Left elbow posture from kinebot': 'Postura do cotovelo esquerdo da kinebot',
	'Right elbow posture from Kinebot': 'Postura do cotovelo direito da Kinebot',
	'Right elbow posture from kinebot': 'Postura do cotovelo direito da kinebot',
	'Left shoulder posture from Kinebot': 'Postura do ombro esquerdo da Kinebot',
	'Left shoulder posture from kinebot': 'Postura do ombro esquerdo da kinebot',
	'Right shoulder posture from Kinebot': 'Postura do ombro direito da Kinebot',
	'Right shoulder posture from kinebot': 'Postura do ombro direito da kinebot',

	'Failed to upload the file': 'Falha ao realizar o upload do arquivo',

	// Two-factor authentication
	// API Errors
	'Two-factor authentication not found': 'Autenticação em dois fatores não encontrada',
	'Already created two-factor authentication': 'Autenticação em dois fatores já criada',
	'Invalid token': 'Token inválido',
	'Token already validated': 'Token já validado',
	'Token not yet validated': 'Token não validado ainda',
	'Token expired': 'Token expirado',
	'Too early to resend email': 'Muito cedo para reenviar o e-mail',
	'Something went wrong checking token': 'Algo deu errado ao verificar o token',
	'Authentication type same as default': 'Tipo de autenticação é o mesmo do atual',
	'Authentication type through app already enabled': 'Autenticação pelo aplicativo já habilitada',
	'Two-factor authentication not enabled': 'Autenticação em dois fatores não habilitada',
	'Too many tries!': 'Muitas tentativas!',
	'Too many calls! Wait a moment to try again.': 'Muitas chamadas. Espere um momento e tente novamente.',

	// Hooks success message
	'Two-factor authentication generated successfully': 'Autenticação em dois fatores gerada com sucesso',
	'Two-factor authentication validated successfully': 'Autenticação em dois fatores validada com sucesso',
	'Two-factor authentication method changed successfully':
		'Método de autenticação em dois fatores alterado com sucesso',
	'Token sent successfully': 'Token enviado com sucesso',
	'Token resent successfully': 'Token reenviado com sucesso',
	'Authenticator app enabled successfully': 'Aplicativo de autenticação habilitado com sucesso',
	'Two-factor authentication deleted successfully': 'Autenticação em dois fatores deletada com sucesso',
	'Two-factor authentication reset successfully': 'Autenticação em dois fatores recuperada com sucesso',
	'Forced two-factor authentication enabled successfully':
		'Forçar autenticação em dois fatores habilitado com sucesso',
	'Forced two-factor authentication disabled successfully':
		'Forçar autenticação em dois fatores desabilitado com sucesso',

	// My Account
	'Account overview': 'Visão geral da conta',
	Profile: 'Perfil',
	'Street name': 'Logradouro',
	'Available time': 'Tempo disponível',
	'Time used': 'Tempo utilizado',

	'Force two-factor authentication to all users': 'Forçar autenticação em dois fatores para todos os usuários',
	'When activated, all users will be forced to activate two-factor authentication on login.':
		'Quando ativado, todos os usuários serão obrigados a ativar a autenticação em dois fatores ao fazer login.',
	'Two-factor authentication': 'Autenticação em dois fatores',
	'When activated, you will have to type a validation code every time you login.':
		'Quando ativado, você terá que digitar um código de validação toda vez que fizer o login.',
	'You can choose to receive your code by email or connect your authentication app with a QRCode.':
		'Você pode optar por receber o código por email ou conectar seu aplicativo de autenticação com um código QR.',

	// Actions
	'Reset 2FA': 'Redefinir',
	'Deactivate 2FA': 'Desativar',
	'Confirm your password to proceed with the desired action':
		'Confirme sua senha para prosseguir com a ação desejada',
	'Deactivate two-factor authentication': 'Desativar autenticação em dois fatores',
	'You are about to reset your authentication method.': 'Você está prestes a redefinir seu método de autenticação.',
	'Select a new desired authentication type and insert one of your recovery token to perform this operation.':
		'Selecione um novo método desejado e insira um dos tokens de recuperação para realizar essa ação.',
	'Authentication method': 'Método',
	'Recovery token': 'Token de recuperação',
	'Authenticator app': 'Aplicativo',
	'You are about to deactivate your 2FA.': 'Você está prestes a desativar sua autenticação em dois fatores.',
	'Confirming this action will delete your two-factor authentication. Are you sure?':
		'Confirmando essa ação, você irá desativar sua autenticação em dois fatores. Tem certeza disso?',
	'Heads up! Closing now will delete the token you generated. Want to continue?':
		'Atenção! Fechar agora implica em apagar o token que acabou de gerar. Quer continuar?',
	'Enable two-factor authentication': 'Ativar autenticação em dois fatores',
	'Select a method to generate your two-factor authentication':
		'Selecione um método para sua autenticação em dois fatores',
	'With this method, you will scan a QRCode so you can use your favorite authenticator app (such as Google Authenticator or Microsoft Authenticator). Then, all you have to do is type the code shown in your app when you login.':
		'Com esse método, você irá escanear um código QR com seu aplicativo de autenticação preferido (como Google Authenticator ou Microsoft Authenticator). Então, tudo que tem a fazer é digitar o código que aparecer no aplicativo no momento do login.',
	'With this method, you will receive the login code in your registered email. At the time of login, you must fill in the indicated field with the code sent to your email.':
		'Com esse método, você receberá o código de autenticação em seu e-mail cadastrado. No momento do login, você deve preencher o campo indicado com o código encaminhado ao seu e-mail.',
	'You are about to generate your 2FA with your authenticator app.':
		'Você está prestes a gerar sua autenticação em dois fatores pelo método de aplicativo.',
	'Be sure to have your mobile phone with you ready to scan your code.':
		'Certifique-se de ter seu telefone celular pronto para escanear seu código.',
	'Validation code': 'Código para validação',
	'Validation token': 'Token de validação',
	'Enter the 6 digit code.': 'Digite o código de 6 dígitos',
	'Recovery tokens': 'Códigos de recuperação',
	'For security reasons, we need you to do your two-factor validation':
		'Por razões de segurança, precisamos que faça sua validação em dois fatores',

	'You are about to generate your two-factor authentication via the app method. Make sure you are close to your cell phone, with the authentication app open.':
		'Você está prestes a gerar sua autenticação em dois fatores pelo método de aplicativo. Certifique-se de estar próximo ao seu celular, com o aplicativo de autenticação aberto.',
	'You are about to generate your two-factor authentication via email method. Make sure you have your inbox open.':
		'Você está prestes a gerar sua autenticação em dois fatores pelo método e-mail. Certifique-se de estar com sua caixa de entrada aberta.',

	'If you have not received your code via email, you can try again by clicking':
		'Caso não tenha recebi seu código via e-mail, você pode tentar novamente clicando',
	here: 'aqui',
	'Unable to generate token': 'Não foi possível gerar o token',

	"Here are your recovery codes, which will be requested should you need to recover your account. Keep in mind that they will not be accessible again after this moment, so it's crucial that you save them securely.":
		'Aqui estão seus códigos de recuperação, que serão solicitados caso precise recuperar sua conta. Tenha em mente que eles não estarão mais acessíveis após este momento, então é crucial que os salve de maneira segura.',

	"Can't close yet. Read the instructions.": 'Não pode fechar essa janela ainda. Leia as instruções.',
	"Your company has asked for all users to keep two-factor authentication enabled for security reasons. You'll be asked to activate it again on your next login. Want to deactivate it anyway?":
		'Sua empresa solicitou que todos os usuários mantenham a autenticação de dois fatores habilitada por motivos de segurança. Você será solicitado a ativá-la novamente no próximo login. Deseja desativá-la mesmo assim?',

	Change: 'Mudar',
	Security: 'Segurança',
	'Account security settings': 'Configurações de segurança da conta',
	'Account information summary': 'Resumo das informações da conta',
	'Password must be at least 8 characters long': 'A senha deve ter pelo menos 8 caracteres',
	'The password must have a maximum of 20 characters': 'A senha deve ter no máximo 20 caracteres',
	'The password field is required': 'O campo senha é obrigatório',
	'Password field cannot be empty': 'Campo de senha não pode estar vazio',
	'The password must contain capital letters': 'A senha deve conter letras maiúsculas',
	'The password must contain lowercase letters': 'A senha deve conter letras minúsculas',
	'Password must contain numbers': 'A senha deve conter números',
	'The password must contain at least one special character': 'A senha deve conter pelo menos um caractere especial',

	// Manage Companies
	'Manage industrial site': 'Gerenciar planta industrial',
	'Update company name': 'Atualizar empresa',
	'Delete company': 'Deletar empresa',
	'Total Industrial Sites': 'Total de plantas industriais',
	'Create workstation': 'Criar posto de trabalho',
	'Workstation name': 'Nome do posto de trabalho',
	'Create sector': 'Criar setor',
	'Sector name': 'Nome do setor',
	'Edit industrial site': 'Edite a planta industrial',
	'All data will be deleted, along with their respective uploads, reports and contents. Do you wish to proceed?':
		'Todos os dados serão apagados, juntamente com seus respectivos uploads, relatórios e conteúdos. Você deseja continuar?',
	'All companies in this organization will be deleted, along with their respective uploads and reports. Do you wish to proceed?':
		'Todas as empresas nesta organização serão excluídas, juntamente com seus respectivos uploads e relatórios. Você deseja continuar?',
	'Edit sector': 'Edite o setor',
	'Select a workstation': 'Selecione um posto de trabalho',
	'Please, select a industrial site': 'Por favor, selecione uma planta industrial',
	'Please, select a sector': 'Por favor, selecione um setor',
	'Please, select a workstation': 'Por favor, selecione um posto de trabalho',
	'Edit workstation': 'Edite o posto de trabalho',
	'You will create a new workstation with same name. Do you wish to proceed ?':
		'Você irá criar um novo posto de trabalho com o mesmo nome. Deseja prosseguir ?',
	'You will create a new company with same name. Do you wish to proceed ?':
		'Você criará uma nova planta industrial com o mesmo nome. Deseja prosseguir ?',
	'You will create a new sector with same name. Do you wish to proceed ?':
		'Você criará um novo setor com o mesmo nome. Deseja prosseguir ?',
	'This name already exists. Do you wish to proceed ?': 'Este nome já existe. Deseja prosseguir ?',
	'Create new company': 'Criar nova empresa',
	'You will create a new organization with same name. Do you wish to proceed ?':
		'Você criará uma nova empresa com o mesmo nome. Deseja prosseguir ?',
	'Failed to update the report': 'Falha ao atualizar o relatório',
	'Failed to create the report': 'Falha ao criar o relatório',
	'Analyzed person': 'Pessoa analisada',

	Person: 'Pessoa',
	Persons: 'Pessoas',
	'Identify people to be analyzed': 'Identifique as pessoas a serem analisadas',
	'No person has been identified with more than 50% of accuracy.':
		'Nenhuma pessoa foi identificada com mais de 50% de precisão.',
	'Please, record the video following the guidelines': 'Por favor, grave o vídeo seguindo as orientações',
	Files: 'Arquivos',

	// Recovery Two-Factor
	'Select a desired new method and enter one of the recovery tokens to perform the recovery':
		'Selecione um novo método desejado e insira um dos tokens de recuperação para efetuar a recuperação',

	// Force Two-Factor
	'For security reasons, we need you to enable your two-factor validation':
		'Por razões de segurança, precisamos que ative sua validação em dois fatores',
	'Your company is requesting that all users have two-factor authentication enabled. Proceed to activate yours and login.':
		'Sua companhia está solicitando que todos os usuários tenham a autenticação em dois fatores habilitada. Continue para ativar a sua e fazer login.',
	Return: 'Voltar',
	'Continue with Microsoft SSO': 'Continue com Microsoft SSO',

	// Liberty Mutual
	'General informations': 'Informações gerais',
	'Material Handling': 'Manuseio de Material',
	'Material handling': 'Manuseio de material',
	'Invalid request data': 'Dados inválidos para requisição',
	'Liberty Mutual report created successfully!': 'Relatório Liberty Mutual criado com sucesso',
	'Liberty Mutual report updated successfully!': 'Relatório Liberty Mutual atualizado com sucesso',
	'Liberty Mutual task not found': 'Atividade do Liberty Mutual não encontrada',
	'System of units not found': 'Sistema de unidades não encontrado',
	Lift: 'Levantar',
	Lifts: 'Levantamentos',
	lift: 'levantamento',
	lifts: 'levantamentos',
	Lower: 'Abaixar',
	Lowers: 'Abaixamentos',
	lowers: 'abaixamentos',
	lower: 'abaixamento',
	Push: 'Empurrar',
	Pushes: 'Empurrações',
	pushes: 'empurrações',
	push: 'empurração',
	Pull: 'Puxar',
	Pulls: 'Puxadas',
	pulls: 'puxadas',
	pull: 'puxada',
	Carry: 'Carregar',
	Carries: 'Carregamentos',
	carries: 'carregamentos',
	carry: 'carregamento',
	'System of unit': 'Sistema de unidades',
	'Select the system of unit': 'Selecione um sistema de unidades',
	'Select the movement of the task being evaluated': 'Selecione o movimento da tarefa sendo avaliada',
	'Insert the fields data for the selected task': 'Insira os dados dos campos para a atividade selecinada',
	'Start hand height': 'Altura inicial da mão',
	'End hand height': 'Altura final da mão',
	'Start hand distance': 'Distância inicial da mão',
	'End hand distance': 'Distância final da mão',
	'Initial force': 'Força inicial',
	'Sustained force': 'Força contínua',
	'Horizontal distance': 'Distância horizontal',
	'Vertical hand height': 'Altura vertical da mão',
	'Hand coupling': 'Pega',
	'Object weight': 'Peso do objeto',
	Weight: 'Peso',
	'Select an hand coupling': 'Selecione uma qualidade de pega',
	'Insert a start height': 'Insira uma altura inicial',
	'Insert an end height': 'Insira uma altura final',
	'Insert a start distance': 'Insira uma distância inicial',
	'Insert an end distance': 'Insira uma distância final',
	'Insert an object weight': 'Insira o peso do objeto',
	'Insert a frequency value': 'Insira a frequência',
	'Select a frequency interval': 'Insira o intervalo da frequência',
	'Insert an initial force': 'Insira uma força inicial',
	'Insert a sustained force': 'Insira uma força contínua',
	Initial: 'Inicial',
	Sustain: 'Contínua',
	'Insert an horizontal distance': 'Insira uma distância horizontal',
	'Insert a vertical hand height': 'Insira uma altura vertical da mão',
	'Select a task': 'Selecione uma atividade',
	'per hour': 'por hora',
	hour: 'hora',
	'per minute': 'por minuto',
	per: 'por',
	'Video duration': 'Duração',
	'Assessment date': 'Data de avaliação',
	'Population percentile': 'Percentil da população',
	'Activity is safe for': 'Atividade é segura para',
	'Initial state': 'Estado inicial',
	'End state': 'Estado final',
	Displacement: 'Deslocamento',
	Task: 'Tarefa',
	Metric: 'Métrico',
	Imperial: 'Imperial',
	'Data entered for Start hand height and End hand height indicates that the task type is Lift, not Lower':
		'Valores inseridos para alturas inicial e final da mão indicam que a atividade é do tipo Levantar e não Abaixar',
	'Data entered for Start hand height and End hand height indicates that the task type is Lower, not Lift':
		'Valores inseridos para alturas inicial e final da mão indicam que a atividade é do tipo Abaixar e não Levantar',

	// Integrations
	'Built-in user groups': 'Grupos de usuários integrados',
	'Connect your Microsoft Azure AD users': 'Conecte seus usuários do Microsoft Azure AD',
	'Invitation sent': 'Convite enviado',
	'Microsoft Azure Active Directory - Users': 'Microsoft Azure Active Directory - Usuários',
	'Failed to load user groups': 'Falha ao carregar grupos de usuários',
	'User removed successfully!': 'Usuário removido com sucesso!',
	'Failed to remove user': 'Falha ao remover usuário',
	'Do you want to remove the user': 'Você deseja remover o usuário',
	'Failed to update user': 'Falha ao atualizar usuário',
	'Failed to load information': 'Falha ao carregar as informações',
	'Investigate and implement change': 'Investigar e implementar mudanças',
	'Implement changes': 'Implementar mudanças',
	Attention: 'Atenção',

	// Upload Logos
	'Upload logos': 'Upload de logos',
	'Use the same of the company': 'Usar a mesma da empresa',
	'.jpeg or .png up to 5MB': '.jpeg ou .png de até 5MB',
	'Upload files': 'Faça o upload de arquivos',
	'Upload your company logo': 'Faça o upload da logo de sua empresa',
	'Upload your industrial site logo': 'Faça o upload da logo da sua planta industrial',
	'Add a image': 'Enviar imagem',
	'Image successfully added': 'A imagem foi adicionada com sucesso',
	'Image successfully changed': 'A imagem foi alterada com sucesso',
	'Uploaded picture': 'Upload da Imagem',
	'Changed picture': 'Imagem alterada',
	'Edit picture': 'Editar Imagem',
	Reset: 'Redefinir',

	// Minutes Details
	'Track the minutes used on the platform during the desired period':
		'Acompanhe os minutos utilizados na plataforma no período desejado',
	'See more details': 'Ver mais detalhes',
	'Please select one or more': 'Por favor, selecione um ou mais',
	'End date must be greater than start date': 'A data final deve ser maior que a data inicial',
	Granularity: 'Granularidade',
	'Select a granularity': 'Selecione uma Granularidade',
	'Select a date': 'Selecione uma Data',
	'Minutes used': 'Minutos utilizados',
	Start: 'Início',
	End: 'Final',
	Users: 'Usuários',
	'Failed to search for companies': 'Falha ao buscar as companhias',
	'Error in searching for user information': 'Erro em buscar as informações do usuário',

	'To Do': 'A fazer',
	'TO DO': 'A FAZER',
	Doing: 'Fazendo',
	DOING: 'FAZENDO',
	Done: 'Feito',
	DONE: 'FEITO',

	Status: 'Status',
	'Remember me': 'Lembrar de mim',
	'Add item': 'Adicionar item',

	'Type of force exertion in the finger/hand (left | right)': 'Força exercida na área da mão/dedos (esq. | dir.)',
	Password: 'Senha',
	Descending: 'Decrescente',
	Integrations: 'Integrações',
	'Order by worst score': 'Ordenar por score',
	'Order by deadline': 'Ordenar por prazo',
	'Select EWA': 'Seleção de AEP',
	'Workstation score': 'Nota do posto',
	Deadline: 'Prazo',
	Responsibility: 'Cargo',

	'average (seconds holding / minute)': 'média (segundos segurando / min.)',
	'average (movements / minute)': 'média (movimentos / min.)',

	// Personalised Reports
	'Personalised reports': 'Relatórios personalizados',
	'Personalised report': 'Relatório personalizado',
	'Created by': 'Criado por',
	'Edit report': 'Editar relatório',
	'Created date': 'Data de criação',
	'New report': 'Novo relatório',
	'Manage report': 'Gerenciar relatório',
	Forward: 'Avançar',
	'Require video': 'Exigir vídeo',
	Evaluator: 'Avaliador',
	'Require evaluator': 'Exigir avaliador',
	'Evaluator created successfully': 'Avaliador criado com sucesso',
	'Select an evaluator': 'Selecione um avaliador',
	'Require work condition': 'Exigir condição de trabalho',
	'Require characteristics': 'Exigir características',
	Acronym: 'Acrônimo',
	'Insert an acronym': 'Insira um acrônimo',
	'Risk scale': 'Escala de risco',
	'Insert a risk scale': 'Insira uma escala de risco',
	'Insert a report name': 'Insira um nome do relatório',
	'Recommended sections': 'Seções recomendadas',
	'Create section': 'Criar seção',
	'Section title': 'Título da seção',
	'Search by section name...': 'Pesquisar por nome da seção...',
	'No sections selected': 'Nenhuma seção selecionada',
	'No more sections available': 'Não há mais seções disponíveis',
	'No sections available': 'Não há seções disponíveis',
	'Section created successfully': 'Seção criada com sucesso',
	'Section has already been added': 'A seção já foi adicionada',
	'Already created section': 'Seção já criada',
	'Selected sections': 'Seções selecionadas',
	'Recommended criteria': 'Critérios recomendados',
	'Create criterion': 'Criar critério',
	'Criterion title': 'Título do critério',
	'Search by criterion name...': 'Pesquisar por nome do critério...',
	'No criteria selected': 'Nenhum critério selecionado',
	'No more criteria available': 'Não há mais critérios disponíveis',
	'No criteria available': 'Não há critérios disponíveis',
	'Criterion created successfully': 'Critério criado com sucesso',
	'Criterion has already been added': 'O critério já foi adicionado',
	'Already created criterion': 'Critério já criado',
	'Selected criteria': 'Critérios selecionados',
	'Section not found': 'Seção não encontrada',
	'Failed to create section': 'Falha ao criar seção',
	'Failed to update section': 'Falha ao atualizar seção',
	'Criterion not found': 'Critério não encontrado',
	'Failed to create criterion': 'Falha ao criar critério',
	'Failed to update criterion': 'Falha ao atualizar critério',
	'Indicate possible injuries/aggravations': 'Indicar possíveis lesões/agravos',
	'Link tool to analysis': 'Atrelar ferramenta à análise',
	'Sections and criteria': 'Seções e critérios',
	'No tool selected': 'Nenhuma ferramenta selecionada',
	'Selected tools': 'Ferramentas selecionadas',
	'Do not indicate possible injuries/aggravations': 'Não indicar possíveis lesões/agravos',
	'Incomplete section': 'Seção incompleta',
	'Custom report created successfully': 'Relatório personalizado criado com sucesso',
	'Custom report updated successfully': 'Relatório personalizado atualizado com sucesso',
	'Report downloaded': 'Relatório baixado',
	'Analysis downloaded successfully': 'Análise baixada com sucesso',
	'Want to delete this criterion?': 'Deseja excluir esse critério?',
	'Want to delete this criterion? Deleting it will remove the section as well!':
		'Deseja excluir esse critério? Excluí-lo irá remover a seção também!',
	"Kinebot's standard prioritisation matrix": 'Matriz de priorização padrão Kinebot',
	'3 level prioritisation matrix': 'Matriz de priorização de 3 níveis',
	'6 level prioritisation matrix': 'Matriz de priorização de 6 níveis',
	'Select the restrictions perceived in the evaluated characteristics':
		'Selecione as restrições percebidas nas características avaliadas',
	'Particularities / Production / Working regime': 'Particularidades / Produção / Regime de trabalho',
	'Select the restrictions perceived in the evaluated working condition':
		'Selecione as restrições percebidas na condição de trabalho avaliada',
	'Insert work schedule': 'Inserir horário de trabalho',
	'Work hours must be greater than 1': 'A jornada de trabalho deve ser maior que 1',
	'Unauthorized requisition': 'Requisição não autorizada',
	'Authorization header is missing': 'Cabeçalho de autorização ausente',
	'Failed to query the database': 'Falha ao consultar o banco de dados',
	'Calculation per second not found': 'Cálculo por segundo não encontrado',
	'Business information not found': 'Informações comerciais não encontradas',
	'Custom report not found': 'Relatório personalizado não encontrado',
	'Already created custom report': 'Relatório personalizado já criado',
	'Failed to create custom report': 'Falha ao criar relatório personalizado',
	'Custom report result not found': 'Resultado do relatório personalizado não encontrado',
	'Already created custom report result': 'Resultado do relatório personalizado já criado',
	'Failed to create custom report result': 'Falha ao criar resultado de relatório personalizado',
	'Work condition not found': 'Condição de trabalho não encontrada',
	'Already created work condition': 'Condição de trabalho já criada',
	'Failed to create work condition': 'Falha ao criar condição de trabalho',
	'Characteristic not found': 'Característica não encontrada',
	'Already created characteristic': 'Característica já criada',
	'Failed to create characteristic': 'Falha ao criar característica',
	'Criteria result not found': 'Resultado do critério não encontrado',
	'Already created criteria result': 'Resultado de critério já criado',
	'Failed to create criteria result': 'Falha ao criar resultado de critério',
	'Failed to update criteria result': 'Falha ao atualizar o resultado do critério',
	'Criteria injury not found': 'Critério de lesão não encontrado',
	'Already created criteria injury': 'Critério de lesão já criado',
	'Failed to create criteria injury': 'Falha ao criar critério de lesão',
	'Custom report score scale not found': 'Escala de pontuação de relatório personalizado não encontrada',
	'Worker self evaluation not found': 'Autoavaliação do trabalhador não encontrada',
	'Custom report setting not found': 'Configuração de relatório personalizado não encontrada',
	'Custom report step setting not found': 'Configuração de seção do relatório personalizado não encontrada',
	'Criteria settings not found': 'Configurações de critérios não encontradas',
	'Step key ergonomic tool not found': 'Ferramenta ergonômica de critério não encontrada',
	'Failed to create step key ergonomic tool': 'Falha ao criar ferramenta ergonômica de critério',
	'Failed to update step key ergonomic tool': 'Falha ao atualizar a ferramenta ergonômica de critério',
	'Evaluator not found': 'Avaliador não encontrado',
	'Already created evaluator': 'Avaliador já criado',
	'Ergonomic tool not found': 'Ferramenta ergonômica não encontrada',
	'Ergonomic tool category not found': 'Categoria da ferramenta ergonômica não encontrada',
	'Action plan not found': 'Plano de ação não encontrado',
	'Failed to create an history': 'Falha ao criar um histórico',
	'Already created action plan': 'Plano de ação já criado',
	'Action plan task not found': 'Tarefa do plano de ação não encontrada',
	'Action plan history not found': 'Histórico do plano de ação não encontrado',
	'Preliminary analysis not found': 'Análise preliminar não encontrada',
	'Analysis step not found': 'Etapa de análise não encontrada',
	'Default step not found': 'Etapa padrão não encontrada',
	'Probabilty not found': 'Probabilidade não encontrada',
	'Injury not found': 'Lesão não encontrada',
	'Consequence not found': 'Consequência não encontrada',
	'Exposure not found': 'Exposição não encontrada',
	'Range risk not found': 'Risco de alcance não encontrado',
	'Failed to fetch risk tracks': 'Falha ao buscar faixas de risco',
	'Organization not found': 'Organização não encontrada',
	'Organization still has registered companies': 'A organização ainda tem empresas registradas',
	'Failed to create an organization': 'Falha ao criar uma organização',
	'Company not found': 'Empresa não encontrada',
	'Failed to create company': 'Falha ao criar uma empresa',
	'Sector not found': 'Setor não encontrado',
	'Workstation not found': 'Estação de trabalho não encontrada',
	'Corrupted video': 'Vídeo corrompido',
	'File not found': 'Arquivo não encontrado',
	'Parameters not found': 'Parâmetros não encontrados',
	'Failed to read sent file': 'Falha ao ler o arquivo enviado',
	'Failed to save file': 'Falha ao salvar o arquivo',
	'Failed to create download url': 'Falha ao criar url de download',
	'File already queued': 'Arquivo já enviado à fila',
	'File already processed': 'Arquivo já processado',
	'Failed to save file information': 'Falha ao salvar as informações do arquivo',
	'Already registered user': 'Usuário já registrado',
	'Failed to create customer plan': 'Falha ao criar plano de cliente',
	'Failed to create customer transaction': 'Falha ao criar transação de cliente',
	'Customer transaction not found': 'Transação de cliente não encontrada',
	'Customer transaction already completed': 'Transação do cliente já concluída',
	'Product not found': 'Produto não encontrado',
	'Failed to create customer credits': 'Falha ao criar créditos de cliente',
	'Plan not found': 'Plano não encontrado',
	'Processed minutes limit reached': 'Limite de minutos processados atingido',
	'Invalid event': 'Evento inválido',
	'Not authorized': 'Não autorizado',
	'Failed to create a user permission': 'Falha ao criar uma permissão de usuário',
	'Customer not found': 'Cliente não encontrado',
	'Unregistered customer': 'Cliente não registrado',
	'Already registered customer': 'Cliente já registrado',
	'Failed to create customer': 'Falha ao criar cliente',
	'Failed to update customer': 'Falha ao atualizar o cliente',
	'Report not found': 'Relatório não encontrado',
	'Already created report': 'Relatório já criado',
	'Failed to create report': 'Falha ao criar relatório',
	'Failed to create report pdf': 'Failed to create report pdf',
	'Failed to update report': 'Falha ao atualizar o relatório',
	'Group not found': 'Grupo não encontrado',
	'User group not found': 'Grupo de usuários não encontrado',
	'Failed to upload file to storage': 'Failed to upload file to storage',
	'Failed to create file signature': 'Falha ao criar assinatura de arquivo',
	'Failed to download data': 'Falha ao baixar dados',
	'Reply queue communication failure': 'Falha na comunicação da fila de resposta',
	'1% to 10% of the cycle': '1% a 10% do ciclo',
	'11% to 20% of the cycle': '11% a 20% do ciclo',
	'21% to 30% of the cycle': '21% a 30% do ciclo',
	'31% to 50% of the cycle': '31% a 50% do ciclo',
	'51% to 75% of the cycle': '51% a 75% do ciclo',
	'76% to 100% of the cycle': '76% a 100% do ciclo',
	'Physical or cognitive discomfort': 'Desconforto físico ou cognitivo',
	'Muscle fatigue or mental overload': 'Fadiga muscular ou sobrecarga mental',
	'Acute pain': 'Quadro agudo de dor',
	'Illness with partial loss of working capacity - without time off work':
		'Doença com perda parcial da capacidade laborativa - sem afastamento',
	'Illness with total loss of working capacity - with time off work':
		'Doença com perda total da capacidade laborativa - com afastamento',
	'Disability retirement': 'Aposentadoria por invalidez',
	'Illness - with leave': 'Doença - com afastamento',
	'Up to 25% of the cycle': 'Até 25% do ciclo',
	'26% to 75% of the cycle': '26% à 75% do ciclo',
	Unlikely: 'Improvável',
	Remote: 'Remota',
	Occasional: 'Ocasional',
	Likely: 'Provável',
	Frequent: 'Frequente',
	Certainly: 'Certamente',
	'Create/update tool': 'Criar/atualizar ferramenta',
	'Driveway conditions are not applied for the selected vehicle.':
		'As condições da entrada de automóveis não são aplicadas ao veículo selecionado.',

	'Your organization uses single sign-on (SSO). Use your corporate email to access Kinebot.':
		'Sua organização usa logon único (SSO). Use seu e-mail corporativo para acessar a Kinebot.',
	'Sign in without SSO': 'Iniciar sessão sem SSO',
	'Enter a valid email': 'Insira um e-mail válido',
	'Enter your email': 'Insira seu e-mail',
	'Work email': 'E-mail de trabalho',
	'Oops! Something happened.': 'Ops! Aconteceu uma coisa.',
	'Failed to fetch information': 'Falha ao buscar informações',
	'Continue with SSO': 'Continuar com o SSO',
	'Return to login without SSO': 'Voltar para o login sem SSO',
	'You are being redirected': 'Você está sendo redirecionado',
	'User not associated with an SSO account': 'Usuário não associado a uma conta de SSO',
	'Fail get user info': 'Falha ao obter informações do usuário',
	'The server encountered an unexpected situation.': 'O servidor encontrou uma situação inesperada.',
	'Please enter a valid email address.': 'Por favor insira um endereço de e-mail válido.',
	'Report incomplete': 'Relatório incompleto',

	// Reports list table
	'KIM PP score': 'Score KIM PP',
	'KIM MHO score': 'Score KIM MHO',
	'Items per page': 'Itens por página',
	'Exposure average': 'Média de exposição',
	'Percentile (Man | Woman)': 'Percentil (Homem | Mulher)',

	// Files list screen
	'Edit file': 'Editar arquivo',
	'Edit files': 'Editar arquivos',
	'File updated successfully': 'Arquivo atualizado com sucesso',
	'Files deleted successfully': 'Arquivos excluídos com sucesso',
	'Files updated successfully': 'Arquivos atualizados com sucesso',

	/*USER MANAGEMENT*/
	'Do you wish to delete this user?!': 'Deseja deletar este usuário?!',
	'Insert a name': 'Insira um nome',
	'Insert an e-mail': 'Insira um email',
	Find: 'Buscar',
	'You are creating user': 'Você está criando o usuário',
	'is receiving permission level': 'está recebendo permissões de nível',
	'in the following companies and industrial sites:': 'nas seguintes empresas e plantas industriais:',
	'Companies and Industrial Sites': 'Empresas e plantas industriais',
	'is a new user with the role': 'é um novo usuário com a permissão nível',
	user: 'usuário',
	USER: 'USUÁRIO',
	from: 'de',
	'You are removing the permission level': 'Você está removendo a permissão de nível',
	'You are changing': 'Você está alterando o nível de permissão de',
	"permission's level to": 'para',
	'Do you want to confirm the change?': 'Deseja confirmar a alteração?',
	'Search a company or industrial site': 'Procure uma empresa ou planta industrial',
	'Create user': 'Criar usuário',
	'is a new user': 'é um novo usuário',
	'in the following companies and industrial sites': 'nas seguintes empresas e plantas industriais',
	'with permission level': 'com permissão nível',
	'Do you want do confirm the creation?': 'Deseja confirmar a criação?',
	'User permissions updated successfully': 'Permissões do usuário atualizadas com sucesso',
	'User deleted successfully': 'Usuário desativado com sucesso',
	'User created successfully': 'Usuário criado com sucesso',
	'User already registered': 'Usuário já registrado',
	'Delete user': 'Deletar usuário',
	'You must change user role or at least one premission.':
		'Você deve alterar o nível de permissão do usuário ou ao menos uma permissão',
	'Heads up! Want to disable this user?': 'Atenção! Deseja desativar este usuário?',
	'Heads up! Want to enable this user?': 'Atenção! Deseja ativar este usuário?',
	'You need to make at least one change.': 'Você precisa fazer ao menos uma alteração.',
	'Failed to fetch workstations': 'Falha ao buscar os postos de trabalho',
	Carriage: 'Carrinho de transporte',
	Barrow: 'Carrinho de mão',
	Conveyor: 'Transportador',
	Crane: 'Guindaste',
	Activate: 'Ativar',
	Deactivate: 'Desativar',
	Edit: 'Editar',

	'User status updated successfully': 'Status do usuário atualizado com sucesso',
	'Failed to update user status': 'Falha ao atualizar o status do usuário',
	'Video select': 'Selecione arquivo',
	'Search for a file': 'Procure um arquivo',
	Uploader: 'Autor do upload',
	'Start report without a video': 'Iniciar relatório sem vídeo',
	'Select the end date': 'Selecione a data fim',
	'Select the start date': 'Selecione a data início',
	'Date cannot larger than start date': 'Data não pode ser maior que a data de início',

	'Activity created successfully': 'Atividade criada com sucesso',
	Reporting: 'Relatório',
	List: 'Lista',
	'Start date': 'Data de início',
	'End date': 'Data de fim',
	to: 'a',
	Activities: 'Atividades',
	'Activity name': 'Nome da atividade',
	'Download report': 'Baixar relatório',
	'Download video': 'Baixar vídeo',
	Filename: 'Nome do arquivo',

	'Add description': 'Adicionar descrição',
	'Action plan': 'Plano de ação',
	'Create actions to address the problems encountered': 'Crie ações para resolver os problemas encontrados',
	'Edit action plan': 'Editar plano de ação',
	'Action plan generated': 'Plano de ação gerado',
	'New action plan': 'Novo plano de ação',
	'Describe the situation detected and the preventive measures already implemented (if any)':
		'Descreva a situação detectada e as medidas preventivas já implementadas (se houver)',
	'Generate action plan': 'Gerar plano de ação',
	Section: 'Seção',
	'Create actions to address the problems encountered.': 'Crie ações para resolver os problemas encontrados.',
	Evaluation: 'Avaliação',
	'Pre-set specific characteristics': 'Pre-definir características específicas',
	Chair: 'Cadeira',
	'Monitor and Phone': 'Monitor e Telefone',
	'Mouse and Keyboard': 'Mouse e Teclado',
	'Peripherals and monitor': 'Periféricos e monitor',
	'Final score': 'Pontuação final',
	'Select an activity': 'Selecione uma atividade',
	'Analysis date': 'Data de análise',
	'Enter the date of analysis': 'Insira a data de análise',
	'Please enter a report name': 'Por favor, insira um nome de relatório',
	'Enter the data related to the new analysis below': 'Insira os dados relacionados ao novo análise abaixo',
	'Other elements': 'Outros elementos',
	'New element': 'Novo elemento',
	Priority: 'Prioridade',
	Provide: 'Fornecer',
	'Failed to create elements': 'Falha ao criar elementos',
	'Required elements': 'Elementos necessários',
	'Prioritize the required elements': 'Priorize os elementos necessários',
	Maintenance: 'Manutenção',
	'Overall results': 'Resultados gerais',
	'Upload image': 'Upload de imagem',
	'Upload images of the activity being evaluated': 'Upload de imagens da atividade sendo avaliada',
	Image: 'Imagem',
	Images: 'Imagens',
	'Do you really want to delete this image?': 'Você realmente deseja excluir esta imagem?',
	'Add file': 'Adicionar arquivo',
	'General information': 'Informações gerais',

	CHANGE: 'MUDAR',
	MAINTENANCE: 'MANUTENÇÃO',
	PROVIDE: 'FORNECER',

	'Knees at 90°': 'Pernas em 90°',
	'Very low - Knee angle < 90°': 'Muito baixo - Ângulo do joelho < 90°',
	'Very high - Knee angle > 90º': 'Muito alto - Ângulo do joelho > 90º',
	'No foot contact with the floor': 'Sem contato de pé com o chão',
	'Insufficient space at the bottom of the desk - No leg mobility':
		'Espaço insuficiente no fundo da mesa - Sem mobilidade das pernas',
	'Not adjustable': 'Não ajustável',
	'Approximately 8 centimeters of space between the knee and the edge of the seat or chair':
		'Aproximadamente 8 centímetros de espaço entre o joelho e a borda do assento ou cadeira',
	'Very long - Less than 8 centimeters of space': 'Muito longo - Menos de 8 centímetros de espaço',
	'Very short - More than 8 centimeters spacing': 'Muito curto - Mais de 8 centímetros de espaço',
	'Elbows supported in line with the shoulder - Shoulders relaxed':
		'Cotovelos apoiados na linha com o ombro - Ombros relaxados',
	'Very high (Shoulders shrugged) / Low (Unsupported arms)':
		'Muito alto (Ombros encolhidos) / Baixo (braços não apoiados)',
	'Very hard or damaged surface': 'Superfície muito dura ou danificada',
	'Very wide': 'Muito ampla',
	'Adequate lumbar support - Chair reclines between 95° and 110º':
		'Suporte lumbar adequado - Cadeira reclina entre 95° e 110º',
	'No lumbar support or lumbar support not positioned on the lower back':
		'Sem suporte lumbar ou suporte lumbar não posicionado no fundo',
	'Backrest angle too far back (greater than 110º) or angle too far forward (less than 95º)':
		'Ângulo do encosto muito longe de trás (maior que 110º) ou ângulo muito longe para frente (menor que 95º)',
	'Without backrest or lumbar support (e.g. on a stool or leaning forward)':
		'Sem encosto ou suporte lombar (ex: em uma cadeira ou agachar para frente)',
	'Very high work surface (shrugged shoulders)': 'Superfície de trabalho muito alta (ombros encolhidos)',
	'Backless back - Non-adjustable': 'Encosto de trás - Não ajustável',
	'Length or distance measured with the arm (40 to 75 cms) / Screen at eye level':
		'Medida de comprimento ou distância medida com o braço (40 a 75 cms) / Tela no nível do olho',
	'Very low (Below 30º)': 'Muito baixo (Abaixo de 30º)',
	'Far away': 'Muito longe',
	'Very high (Neck in extension)': 'Muito alto (Pescoço em extensão)',
	'Neck rotated more than 30º': 'Pescoço rotacionado mais de 30º',
	'Glare on the screen': 'Brilho na tela',
	'No use of lecterns or document holders, if required':
		'Sem uso de púlpitos ou guardadores de documentos, se necessário',
	'Headphones with microphones, one hand on the phone and neutral neck posture':
		'Microfones com fones, uma mão no telefone e postura de pescoço neutro',
	'Too far for the range (More than 30cms separation)': 'Muito distante para a faixa (Mais de 30cms de separação)',
	'Holding the phone with the neck and shoulder': 'Segurar o telefone com o pescoço e o ombro',
	'No hands-free option': 'Sem opção de não utilizar as mãos',
	'Mouse in line with shoulder': 'Mouse na linha com o ombro',
	'Reaching for the mouse / Arm in abduction': 'Alcançar o mouse / Braço em abdução',
	'Mouse and keyboard on surfaces of different levels': 'Mouse e teclado em superfícies de diferentes níveis',
	'Gripper grip on the mouse': 'Aperto de garra no mouse',
	'Wrist rest in front of the mouse': 'Descanso do pulso na frente do mouse',
	'Wrists straight - Shoulders relaxed': 'Pulsos diretos - Ombros relaxados',
	'Wrists extended - Keyboard at positive angle ( > 15º Extension of wrists)':
		'Pulsos estendidos - Teclado no ângulo positivo ( > 15º Extensão dos pulsos)',
	'Deviation of the wrists during transcription or typing': 'Desvio dos pulsos durante a transcrição ou digitação',
	'Keyboard too high, shoulders shrugged': 'Teclado muito alto, ombros encolhidos',
	'Reaching for items or objects overhead': 'Alcançar itens ou objetos sobre o cabo',
	'Non-adjustable keyboard platform': 'Plataforma de teclado não ajustável',

	'Element deleted successfully': 'Elemento excluído com sucesso',
	'Image deleted successfully': 'Imagem excluída com sucesso',
	'Rosa report updated successfully!': 'Relatório Rosa atualizado com sucesso!',
	'Fail to save the comments': 'Falha ao salvar os comentários',
	'Error save duration!': 'Erro ao salvar a duração',
	'Error save element!': 'Erro ao salvar o elemento',
	'Image updated successfully': 'Imagem atualizada com sucesso',

	chair: 'cadeira',
	'Chair height': 'Altura da cadeira',
	'Seat depth': 'Profundidade do assento',
	Armrests: 'Encostos',
	'Seat backrest': 'Encosto do assento',
	Mouse: 'Mouse',
	Keyboard: 'Teclado',
	Monitor: 'Monitor',
	Phone: 'Telefone',

	'Final score ROSA': 'Pontuação final ROSA',
	'Scoring by section': 'Pontuação por seção',

	'Chair Height': 'Altura da cadeira',
	'Seat Depth': 'Profundidade do assento',
	'Seat Backrest': 'Encosto do assento',

	Footrest: 'Encostos de pé',
	'Fixed monitor base': 'Base de monitor fixa',
	'Portable monitor stand': 'Apoio de monitor portátil',
	'Channeling cables': 'Canaletas',
	Headband: 'Faixa de cabeça',
	'Priority level of the demand, being 1 medium priority and 2 high priority.':
		'Nível de prioridade da demanda, sendo 1 de prioridade média e 2 de prioridade alta.',

	'Low priority': 'Baixa prioridade',
	'Medium priority': 'Média prioridade',
	'High priority': 'Alta prioridade',
	'Select a priority': 'Selecione uma prioridade',
	'Administrative activities analysis': 'Análise de atividades administrativas',
	'Do you really want to delete this element?': 'Você realmente deseja excluir este elemento?',

	'Select the items you want to present in your document':
		'Selecione os itens que você deseja apresentar no seu documento',
	'Filling details': 'Preenchimento de detalhes',
	'Image description': 'Descrição da imagem'
};

export default dictionary;
