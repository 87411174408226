import Types from './types.js';

export function setPlanDetails(payload) {
	const { plan, canceled_on, expiration_plan } = payload;

	const max_users = plan?.max_users ?? 0;

	const expired = !!(expiration_plan && new Date(expiration_plan) < Date.now());

	const canceled = !!(canceled_on && new Date(canceled_on) < Date.now());

	const body = {
		max_users,
		type: plan.name,
		expiration_plan,
		canceled_on,
		expired: expired,
		canceled: canceled
	};

	return { type: Types.SET_PLAN_DETAILS, payload: body };
}
