import React from 'react';
import { Row, Col } from 'antd';

import { URL_LOGO } from '@/utils/constants';
import { useGetThumbnailURL } from '@/hooks/useGetThumbnailURL';
import type { FilesListResponse } from '@/hooks';
import { ThumbnailContainer } from './styles';

interface ThumbnailProps {
	file: FilesListResponse;
	onShowVideoModal(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void;
}

export function Thumbnail({ file, onShowVideoModal }: Readonly<ThumbnailProps>) {
	const { data: thumbnail } = useGetThumbnailURL({
		organization_id: file.organization!.id,
		company_id: file.company!.id,
		file_id: file!.id
	});

	return (
		<Col xxl={4}>
			<Row justify="center">
				<ThumbnailContainer preview={false} onClick={onShowVideoModal} src={thumbnail ?? URL_LOGO} />
			</Row>
		</Col>
	);
}
