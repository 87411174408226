import { Image, Row } from 'antd';
import styled from 'styled-components';

interface PlayerRowContainerProps {
	$isRowSelected: boolean;
}

export const PlayerRowContainer = styled(Row)<PlayerRowContainerProps>`
	padding: 10px;
	cursor: pointer;
	border-radius: 10px;
	border: ${({ $isRowSelected }) => ($isRowSelected ? '2px solid #2F54EB' : 'none')};
	background-color: ${({ $isRowSelected }) => ($isRowSelected ? '#EAEEFD' : '#ffffff}')};
`;

export const ThumbnailContainer = styled(Image)`
	cursor: pointer;
	max-height: 150px;
	border-radius: 10px;
	padding: 16px 16px 10px 10px;
`;
