import locale_br from './pt_BR';
import locale_es from './es_ES';
import locale_de from './de_DE';

const data = {
	'pt-BR': locale_br,
	es: locale_es,
	de: locale_de
};

export default data;
