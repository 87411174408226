import React from 'react';
import { DeleteOutlined, DownOutlined, EditOutlined, PictureOutlined, ToolOutlined } from '@ant-design/icons';
import { Button, Dropdown, Space } from 'antd';
import { I18n } from '@aws-amplify/core';

import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { useManageOrganization } from './context';
import { OrganizationDTO } from './types';
import { ActionDropdown } from './styles';
import { usePlan } from '@/hooks/usePlan';

interface IActionsProps {
	organization: OrganizationDTO;
	isEditing: boolean;
	onManageOrganizationModal: (organization: OrganizationDTO) => void;
	onLogosUpdate: (organization: OrganizationDTO) => void;
	onDelete: (event: React.MouseEvent<HTMLElement, MouseEvent>, organizationId: string) => void;
	onUpdate: (event: React.MouseEvent<HTMLElement, MouseEvent>, organizationId: string) => void;
}

export const Actions: React.FC<IActionsProps> = ({
	organization,
	isEditing,
	onLogosUpdate,
	onManageOrganizationModal,
	onDelete,
	onUpdate
}) => {
	const { data } = usePlan();
	const { organizationsList } = useManageOrganization();

	function handleManageOrganization() {
		onManageOrganizationModal(organization);
	}

	function handleUpdate(event: React.MouseEvent<HTMLElement, MouseEvent>) {
		onUpdate(event, organization.id);
	}

	function handleLogoUpdate() {
		onLogosUpdate(organization);
	}

	function handleDelete(event: React.MouseEvent<HTMLElement, MouseEvent>) {
		if (organizationsList.length === 1) {
			return;
		}
		onDelete(event, organization.id);
	}

	const items: ItemType[] = [
		{
			key: 1,
			icon: <ToolOutlined />,
			label: (
				<Button style={{ padding: 0 }} type="link" onClick={handleManageOrganization}>
					{I18n.get('Manage industrial site')}
				</Button>
			)
		},
		{
			key: 2,
			icon: <EditOutlined />,
			disabled: data?.special_plan,
			label: (
				<Button
					style={{ padding: 0 }}
					type="link"
					disabled={data?.special_plan}
					onClick={(e) => handleUpdate(e)}
				>
					{I18n.get('Update company name')}
				</Button>
			)
		},
		{
			key: 3,
			icon: <PictureOutlined />,
			label: (
				<Button style={{ padding: 0 }} type="link" onClick={handleLogoUpdate}>
					{I18n.get('Upload logos')}
				</Button>
			)
		},
		{
			key: 4,
			icon: <DeleteOutlined />,
			danger: true,
			label: (
				<Button
					style={{ padding: 0 }}
					disabled={data?.special_plan}
					type="link"
					onClick={(e) => handleDelete(e)}
				>
					{I18n.get('Delete')}
				</Button>
			),
			disabled: organizationsList.length === 1 || data?.special_plan
		}
	];

	return (
		<Dropdown menu={{ items }} disabled={isEditing} placement="bottom" trigger={['click']}>
			<ActionDropdown>
				<Space>
					{I18n.get('Action')}
					<DownOutlined />
				</Space>
			</ActionDropdown>
		</Dropdown>
	);
};
