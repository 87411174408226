import React from 'react';
import { Row, Col, Collapse } from 'antd';

import hooks from './hooks';

import { TableList } from './List';
import { Title } from '@/components/Typography/index';
import { useApplicationContext } from '@/context/Application';

const { Panel } = Collapse;
const { usePreliminaryAnalysisList } = hooks;

export const PreliminaryAnalysis: React.FC = () => {
	const { organization, company } = useApplicationContext();
	const analysisList = usePreliminaryAnalysisList(organization?.id, company?.id);

	return (
		<Row gutter={[0, 10]}>
			<Col span={24}>
				<Title level={4}>Preliminary Ergonomic Analysis</Title>
			</Col>
			<Col span={24}>
				<Collapse>
					<Panel header="Filter" key="1">
						<p>Filter</p>
					</Panel>
				</Collapse>
			</Col>
			<Col span={24}>
				<TableList data={analysisList.data} isLoading={analysisList.isLoading} />
			</Col>
		</Row>
	);
};
