import React, { useState } from 'react';
import { UseQueryResult } from '@tanstack/react-query';
import { Row, Col, Divider, Button } from 'antd';
import { I18n } from '@aws-amplify/core';
import moment from 'moment';

import { IPlanResponse } from '@/hooks/usePlan/types';
import { ENUM_TYPE_PLAN } from '@/utils/enum';
import { usePlan } from '@/hooks/usePlan';
import Can from '@/components/Can';

import { Field, Information, Section, Subsection, TitleSection } from '../styles';
import { DetailsModal } from './DetailsModal';

const PLAN_TYPES: Record<string, string> = ENUM_TYPE_PLAN;

export const Plan: React.FC = () => {
	const { data }: UseQueryResult<IPlanResponse> = usePlan();
	const [openPlanDetailsModal, setOpenPlanDetailsModal] = useState<boolean>(false);

	function formatTimeText(minutesProcessed: number) {
		if (!minutesProcessed) {
			return '0 m';
		}

		const duration = moment.duration(minutesProcessed, 'minutes');
		const seconds = duration.seconds();
		const minutes = duration.minutes();
		const hours = duration.hours();

		let message = '';

		if (hours) {
			message = `${hours}h `;
		}

		if (minutes || (hours && seconds)) {
			message += `${minutes}m `;
		}

		if (seconds) {
			message += `${seconds}s`;
		}

		return message;
	}

	function handleOpenModalDetails(value: boolean): void {
		setOpenPlanDetailsModal(value);
	}

	return (
		<Section>
			<TitleSection>{I18n.get('Plan')}</TitleSection>
			<Row>
				<Row style={{ width: '100%' }}>
					<Subsection span={14}>
						<Field>{I18n.get('Subscription type')}</Field>
						<Information>{data?.plan ? PLAN_TYPES[data.plan.name] : ''}</Information>
					</Subsection>
					<Subsection span={10}>
						<Field>{I18n.get('Expiration date')}</Field>
						<Information>{moment(data?.expiration_plan).format('L')}</Information>
					</Subsection>
				</Row>
				<Divider style={{ margin: '25px 0' }} />
				<Subsection>
					<Field>{I18n.get('Available time')}</Field>
					<Information>{data && formatTimeText(data.minutes_available)}</Information>
				</Subsection>
				<Divider style={{ margin: '25px 0' }} />
				<Subsection>
					<Field>{I18n.get('Time used')}</Field>
					<Row justify={'space-between'} align={'middle'}>
						<Col span={23}>
							<Information>{data && formatTimeText(data.minutes_processed)}</Information>
						</Col>
						<Col span={1}>
							<Button
								type="link"
								size="large"
								disabled={!Can('settings', 'billing')}
								onClick={() => setOpenPlanDetailsModal(true)}
							>
								{I18n.get('See more details')}
							</Button>
							<DetailsModal open={openPlanDetailsModal} onCancel={handleOpenModalDetails} />
						</Col>
					</Row>
				</Subsection>
			</Row>
		</Section>
	);
};
