import React, { useState, useEffect, useCallback } from 'react';
import { Form, Modal, Select, Button, Input, Tree, message } from 'antd';
import { I18n } from '@aws-amplify/core';

import Api from '@/services/api';
import { useIsMounted } from '@/hooks/useIsMounted';

export function CreateUser({ visible, onClose, triggerReload }) {
	const [form] = Form.useForm();
	const isMounted = useIsMounted();

	const [loading, setLoading] = useState(false);
	const [errors, setErrors] = useState(null);

	const [treeData, setTreeData] = useState([]);
	const [permissions, setPermissions] = useState([]);

	const getAllPermission = useCallback(() => {
		if (!visible) return;
		Api.get('/user_management/permissions_list')
			.then((res) => {
				let tree = [];
				if (isMounted()) {
					res.data.forEach((item) => {
						let permission = {
							title: item.organization_name,
							key: item.organization_id,
							children: [
								...item.companies?.map((company) => ({
									key: company.company_id,
									title: company.company_name
								}))
							]
						};
						tree.push(permission);
					});
					setTreeData(tree);
				}
			})
			.catch((err) => isMounted() && setErrors(err));
	}, [isMounted, visible]);

	useEffect(() => {
		getAllPermission();
	}, [getAllPermission, visible]);

	useEffect(() => {
		if (errors) {
			const result = errors.response?.data?.message || errors.message;
			const description = I18n.get(result);
			message.error(description);
			setErrors(null);
		}
	}, [errors]);

	const selectedChildren = (child, id) => {
		if (child.key === id) {
			return {
				company_id: child.key,
				company_name: child.title
			};
		}
	};

	const formatPermissions = (checkedList) => {
		let permissions = [];

		treeData.forEach((object) => {
			let result = {
				organization_id: object.key,
				organization_name: object.title,
				companies: []
			};

			const filterUndefined = (permission) => permission !== undefined && result.companies.push(permission);

			checkedList.map((id) =>
				object.children.map((child) => selectedChildren(child, id)).forEach(filterUndefined)
			);

			if (result.companies.length > 0) {
				permissions.push(result);
			}
		});

		return permissions;
	};

	const onCloseModal = () => {
		onClose();
		form.resetFields(['name', 'email', 'role']);
		setTreeData([]);
	};

	const onCreateUser = (body) => {
		const url = '/user_management/user_add';
		Api.post(url, body)
			.then(setLoading(true))
			.then((res) => {
				message.success(I18n.get('User created with success'));
				onCloseModal();
				triggerReload();
			})
			.catch((err) => setErrors(err))
			.finally(() => setLoading(false));
	};

	const onSave = ({ name, email, role }) => {
		const body = {
			name: name,
			email: email,
			role: role,
			permissions: formatPermissions(permissions)
		};

		onCreateUser(body);
	};

	const onSubmit = () => {
		form.validateFields()
			.then(onSave)
			.catch((errInfo) => {});
	};

	const onCheck = (checkedKeys, { halfCheckedKeys }) => {
		const arrayTree = [...checkedKeys, ...halfCheckedKeys];
		setPermissions(arrayTree);
	};

	const Footer = () => [
		<Button key="cancel" onClick={onCloseModal}>
			Cancelar
		</Button>,
		<Button key="submit" type="primary" onClick={onSubmit} loading={loading}>
			Salvar
		</Button>
	];

	return (
		<Modal open={visible} title={I18n.get('Create new user')} footer={<Footer />} onCancel={onCloseModal}>
			<Form form={form} layout="vertical" name="create_user">
				<Form.Item
					name="name"
					label={I18n.get('Name')}
					rules={[
						{
							required: true,
							message: I18n.get('Please enter a valid name')
						}
					]}
				>
					<Input placeholder={I18n.get('Name')} />
				</Form.Item>
				<Form.Item
					name="email"
					label={I18n.get('Email')}
					rules={[
						{
							type: 'email',
							message: I18n.get('Enter a valid e-mail')
						},
						{
							required: true,
							message: I18n.get('Enter an e-mail')
						}
					]}
				>
					<Input placeholder={I18n.get('Email')} />
				</Form.Item>
				<Form.Item
					name="role"
					label={I18n.get('Permissions')}
					rules={[
						{
							required: true,
							message: I18n.get('Enter a permission')
						}
					]}
				>
					<Select
						showSearch
						style={{ width: '100%' }}
						placeholder={I18n.get('Permissions')}
						optionFilterProp="children"
					>
						<Select.Option value="USER">{I18n.get('User')}</Select.Option>
						<Select.Option value="SUPERVISOR">{I18n.get('Supervisor')}</Select.Option>
					</Select>
				</Form.Item>
				<Form.Item name="permissions" label={I18n.get('Companies - Industrial sites')}>
					<Tree checkable onCheck={onCheck} treeData={treeData} />
				</Form.Item>
			</Form>
		</Modal>
	);
}
