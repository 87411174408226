import React from 'react';
import { Breadcrumb } from 'antd';
import { I18n } from '@aws-amplify/core';
import { Link, useLocation } from 'react-router-dom';

const breadcrumbPaths: Record<string, string> = {
	'/upload': 'Upload',
	'/super-pea': 'AEP',
	'/reporting': 'Reports',
	'/settings': 'Settings',
	'/settings/plan': 'Plan',
	'/users': 'Manage users',
	'/reporting/rula': 'RULA',
	'/reporting/reba': 'REBA',
	'/reporting/niosh': 'NIOSH',
	'/action-plans': 'Action plans',
	'/settings/account': 'My account',
	'/settings/companies': 'Companies',
	'/settings/preferences': 'Preferences',
	'/settings/information': 'Information',
	'/settings/integrations': 'Integrations',
	'/super-pea/file-history': 'File history',
	'/reporting/strain-index': 'Strain Index',
	'/super-pea/new-document': 'New document',
	'/reporting/kim-pp': 'KIM - Push and pull',
	'/settings/integrations/azure-ad': 'Azure AD',
	'/reporting/liberty-mutual': 'Liberty Mutual',
	'/settings/integrations/azure-ad/group': 'Grupo',
	'/reporting/kim-mho': 'Manual Handling Operations',
	'/reporting/personalised-report': 'Personalised report',
	'/settings/personalised-reports': 'Personalised reports',
	'/settings/personalised-reports/manage': 'Manage report',
	'/preliminary-ergonomic-analysis': 'Preliminary Ergonomic Analysis',
	'/reporting/preliminary-ergonomic-analysis': 'Preliminary Ergonomic Analysis',
	'/reporting/list/angle-time': 'Angle by time',
	'/reporting/list/score-parts': 'RULA',
	'/reporting/list/kim-pp': 'KIM Push and Pull',
	'/reporting/list/liberty-mutual': 'Liberty Mutual'
};

export function WrapperBreadcrumb() {
	const location = useLocation();
	const pathnames = location.pathname.split('/').filter((i) => i);

	const breadcrumbs: JSX.Element[] = pathnames.map((key, index) => {
		let url = `/${pathnames.slice(0, index + 1).join('/')}`;
		let label = key.charAt(0).toUpperCase() + key.slice(1);

		if (pathnames.length === index + 1) {
			label = breadcrumbPaths[url] || label;
		}

		return (
			<Breadcrumb.Item key={key}>
				<Link to={url}>{I18n.get(label)}</Link>
			</Breadcrumb.Item>
		);
	});

	const items: JSX.Element[] = [
		<Breadcrumb.Item key="home">
			<Link to="/">{I18n.get('Home')}</Link>
		</Breadcrumb.Item>
	].concat(breadcrumbs);

	return <Breadcrumb>{items}</Breadcrumb>;
}
