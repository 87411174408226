import { Row, Col, Form, Modal, Select, DatePicker, Button, Input } from 'antd';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { I18n } from '@aws-amplify/core';
import moment from 'moment';

import { useCreateActionPlan } from '@/hooks/v1/useActionPlanMutations';
import { FileSelect } from '@/components/ui/Inputs/FileSelect';
import { ActionPlansContext } from './context';

const { TextArea } = Input;

export function CreateActionPlan({ visible, onClose }) {
	const {
		companies,
		selectedCompanyId,
		setSelectedCompanyId,
		sectors,
		selectedSectorId,
		setSelectedSectorId,
		files,
		setSelectedFileId,
		responsibleUsers,
		creationBoard
	} = useContext(ActionPlansContext);

	const [form] = Form.useForm();

	const organizationId = useSelector(({ organization }) => organization.organization?.id);

	const { mutateAsync: createActionPlan, isLoading } = useCreateActionPlan();

	function resetFieldsForm(field) {
		const options = {
			company_id: ['sector_id', 'file_id'],
			sector_id: ['file_id']
		};
		form.resetFields(options[field] || []);
	}

	const onResponsibleChange = () => {};

	const onCloseModal = () => {
		onClose();
		form.resetFields(['title', 'responsible_user_id', 'deadline', 'company_id', 'sector_id', 'file_id']);
	};

	const onCreateActionPlan = (values) => {
		const { title, responsible_user_id, deadline, workstation_id, file_id } = values;
		const description = values.description ?? null;

		const action_plan = {
			title,
			description,
			responsible_user_id,
			deadline,
			board: creationBoard.toUpperCase(),
			workstation_id,
			file_id
		};

		const body = {
			action_plan,
			organization_id: organizationId
		};

		createActionPlan({ body }).then(() => onCloseModal());
	};

	const onSubmit = () => {
		form.validateFields()
			.then(onCreateActionPlan)
			.catch((errInfo) => {});
	};

	const Footer = () => [
		<Button key="cancel" onClick={onCloseModal}>
			{I18n.get('Cancel')}
		</Button>,
		<Button key="submit" type="primary" onClick={onSubmit} loading={isLoading}>
			{I18n.get('Save')}
		</Button>
	];

	return (
		<Modal
			style={{ top: 20 }}
			open={visible}
			width={'1000px'}
			title={I18n.get('Create action plan')}
			footer={<Footer />}
			onCancel={onCloseModal}
		>
			<Form form={form} layout="vertical" name="create_action_plan">
				<Row gutter={[50, 10]}>
					<Col span={12}>
						<Form.Item
							name="title"
							label={I18n.get('Title')}
							rules={[
								{
									required: true,
									message: I18n.get('Please enter a valid title')
								}
							]}
						>
							<Input placeholder={I18n.get('Action plan title')} />
						</Form.Item>
						<Form.Item
							name="description"
							label={I18n.get(
								'Describe the situation found and the preventive measures already implemented (if exist)'
							)}
							rules={[
								{
									required: false
								}
							]}
						>
							<TextArea
								showCount
								maxLength={500}
								rows={4}
								placeholder={I18n.get('Action plan description')}
							/>
						</Form.Item>
						<Row justify="space-between">
							<Form.Item
								name="responsible_user_id"
								label={I18n.get('Person responsible')}
								rules={[
									{
										message: I18n.get('Please enter a person responsible'),
										required: true
									}
								]}
								style={{
									width: '70%',
									display: 'inline-block'
								}}
							>
								<Select
									placeholder={I18n.get('Who is responsible for this action plan?')}
									onChange={onResponsibleChange}
									allowClear
								>
									{responsibleUsers.data?.map((person) => (
										<Select.Option key={person.id} value={person.id}>
											{person.name}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
							<Form.Item
								name="deadline"
								label={I18n.get('Deadline')}
								rules={[
									{
										required: true,
										message: I18n.get('Please enter a valid date')
									}
								]}
								style={{
									display: 'inline-block',
									width: '25%'
								}}
							>
								<DatePicker
									format={'DD/MM/YYYY'}
									placeholder={I18n.get('Date')}
									disabledDate={(current) => current < moment().subtract(1, 'day')}
								/>
							</Form.Item>
						</Row>
					</Col>
					<Col span={12}>
						<Form.Item
							name="company_id"
							label={I18n.get('Industrial site')}
							rules={[
								{
									required: true,
									message: I18n.get('Select a industrial site')
								}
							]}
						>
							<Select
								style={{ width: '100%' }}
								onChange={(value) => {
									setSelectedCompanyId(value);
									resetFieldsForm('company_id');
								}}
								placeholder={I18n.get('Select a industrial site')}
							>
								{companies.data?.map((company) => (
									<Select.Option key={company.id} value={company.id}>
										{company.name}
									</Select.Option>
								))}
							</Select>
						</Form.Item>
						<Form.Item
							name="sector_id"
							label={I18n.get('Sector')}
							rules={[
								{
									required: true,
									message: I18n.get('Select a sector')
								}
							]}
						>
							<Select
								style={{ width: '100%' }}
								onChange={(value) => {
									setSelectedSectorId(value);
									resetFieldsForm('sector_id');
								}}
								placeholder={I18n.get('Select a industrial site')}
								disabled={!selectedCompanyId}
							>
								{sectors.data?.map((sector) => (
									<Select.Option key={sector.id} value={sector.id}>
										{sector.name}
									</Select.Option>
								))}
							</Select>
						</Form.Item>
						<Form.Item
							name="file_id"
							label={I18n.get('File')}
							rules={[
								{
									required: true,
									message: I18n.get('Select a file')
								}
							]}
						>
							<FileSelect
								style={{ width: '100%' }}
								onChange={(value) => {
									setSelectedFileId(value);
								}}
								placeholder={I18n.get('Select a file')}
								disabled={!selectedSectorId}
								loading={files.isLoading}
								files={files}
							/>
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</Modal>
	);
}
