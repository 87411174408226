import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Col, Form } from 'antd';

import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';
import { CustomFormItem, CustomSelect } from '../styles';
import { useGetUsers } from '../../hooks';
import { OptionType } from '../types';

const { useFormInstance } = Form;

export const Users: React.FC = () => {
	const breakpoints = useBreakpoint();
	const { setFieldValue } = useFormInstance();

	const { data: users, isLoading, isFetching, isError } = useGetUsers();

	function filterOption(option: OptionType, input: string): boolean {
		return (option?.label.toLowerCase() ?? '').includes(input.toLowerCase());
	}

	const handleSelectAll = (value: string[]) => {
		if (value?.includes('all') && users) {
			if (value.length === users.length + 1) {
				return [];
			}
			return users.filter((user) => !user.id.includes('all')).map((user) => user.id);
		}
		return value;
	};

	function handleClearUser() {
		setFieldValue(['user_ids'], undefined);
	}
	return (
		<Col xs={24}>
			<CustomFormItem name={['user_ids']} getValueFromEvent={handleSelectAll} label={I18n.get('Users')}>
				<CustomSelect
					showSearch
					allowClear
					onClear={handleClearUser}
					filterOption={(input, option: any) => filterOption(option, input)}
					maxTagCount={'responsive'}
					mode="multiple"
					size={!breakpoints.xxl ? 'small' : 'large'}
					options={users?.map(({ id, name }) => ({ label: name, value: id }))}
					placeholder={I18n.get('Please select one or more')}
					disabled={isLoading || isFetching || isError}
					loading={isLoading || isFetching || isError}
					showArrow={true}
				/>
			</CustomFormItem>
		</Col>
	);
};
