import React from 'react';
import { PickerProps } from 'antd/lib/date-picker/generatePicker';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';
import { I18n } from '@aws-amplify/core';
import { Moment } from 'moment';
import { Col } from 'antd';

import { CustomDatePicker, CustomFormItem } from '../styles';

type DateInputProps = {
	name: string;
	label: string;
	errorMessage: string;
} & PickerProps<Moment>;

export const DateInput: React.FC<DateInputProps> = ({ name, label, errorMessage, ...props }) => {
	const breakpoints = useBreakpoint();
	return (
		<Col xs={24}>
			<CustomFormItem
				label={I18n.get(label)}
				name={[name]}
				rules={[
					{
						required: true,
						message: I18n.get(errorMessage)
					},
					({ getFieldValue }) => ({
						validator() {
							const end_date = getFieldValue(['end_date']);
							const start_date = getFieldValue(['start_date']);

							if (end_date && end_date < start_date) {
								return Promise.reject(I18n.get('End date must be greater than start date'));
							}

							return Promise.resolve();
						}
					})
				]}
				style={{ height: 'auto' }}
			>
				<CustomDatePicker
					size={!breakpoints.xxl ? 'small' : 'large'}
					style={{ width: '100%' }}
					{...props}
					format="L"
				/>
			</CustomFormItem>
		</Col>
	);
};
