import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Steps, notification } from 'antd';
import { useParams } from 'react-router-dom';
import { I18n } from '@aws-amplify/core';
import moment from 'moment';
import _ from 'lodash';

import hooks from '../hooks';
import { Spinner } from '@/components/Spinner';
import { Title } from '@/components/Typography';
import { PreliminaryAnalysisProvider } from '../context';
import { useApplicationContext } from '@/context/Application';

import { Informations } from './Steps/Informations/index';
import { WorkConditions } from './Steps/WorkConditions/index';
import { Characters } from './Steps/Characters/index';
import { Workday } from './Steps/Workday';
import { KindOfWork } from './Steps/KindOfWork';
import { PosturesAndMovements } from './Steps/PosturesAndMovements';
import { InfluenceOfEnvironment } from './Steps/InfluenceOfEnvironment';
import { PsychosocialInfluence } from './Steps/PsychosocialInfluence';
import { InfluenceOfEnvFactors } from './Steps/InfluenceOfEnvFactors';
import { OrganizationalInfluences } from './Steps/OrganizationalInfluences';
import { Result } from './Steps/Result';
import { Footer } from './Steps/_components/Footer';

import { useCalculateAngles, useCreateReba, useUpdateReba } from '@/hooks/useReba';
import { queryClient } from '@/store/query';
import { useSearchParams } from '@/hooks';

const { useForm } = Form;
const { useUpdateStep, useFindOrCreateAnalysis, useGetReba } = hooks;

const WAIT = 'wait';
const ERROR = 'error';
const FINISH = 'finish';
const FAILED = 'failed';

const arraySteps = [
	{
		content: <Informations />
	},
	{
		content: <WorkConditions />
	},
	{
		content: <Characters />
	},
	{
		content: <Workday />
	},
	{
		content: <KindOfWork /> // tool
	},
	{
		content: <PosturesAndMovements /> // tool and body parts (partials)
	},
	{
		content: <InfluenceOfEnvironment /> // tool ok
	},
	{
		content: <PsychosocialInfluence /> // Patter
	},
	{
		content: <InfluenceOfEnvFactors />
	},
	{
		content: <OrganizationalInfluences />
	},
	{
		content: <Result />
	}
];

export function PreliminaryAnalysisForm() {
	const [form] = useForm();
	const { file_id } = useParams();

	const [current, setCurrent] = useState(0);
	const [steps, setSteps] = useState(arraySteps);

	const { organization, company } = useApplicationContext();
	const { organization_id = organization?.id, company_id = company?.id } = useSearchParams();
	const {
		data: analysis,
		isLoading: loadingAnalysis,
		isError: errorFindingOrCreatingAnalysis
	} = useFindOrCreateAnalysis(organization_id, company_id, file_id);
	const { mutate: updateStep, isLoading: loadingUpdateStep } = useUpdateStep(organization_id, company_id, file_id);
	const { data: reba, isLoading: loadingReba } = useGetReba(organization_id, company_id, analysis?.id);

	const [notificationAPI, contextHolder] = notification.useNotification();

	const { mutate: createReba } = useCreateReba();
	const { mutate: updateReba } = useUpdateReba();
	const { mutate: calculateAngles } = useCalculateAngles();

	useEffect(() => {
		if (!analysis?.id) {
			return;
		}

		const payload = {
			organization_id: organization_id,
			company_id: company_id,
			sector_id: analysis.sector_id,
			file_id,
			workstation_id: analysis.workstation_id,
			comment: '',
			coupling: 1,
			force: 1,
			repetition: 1,
			collection_date: new Date()
		};

		if (current === 0 && !loadingReba) {
			calculateAngles({
				organization_id: organization_id,
				company_id: company_id,
				file_id: file_id
			});
		}

		if (current === 2 && !_.isEmpty(analysis) && !reba?.id && reba?.status === FAILED) {
			createReba(payload);
		}

		if (reba?.id && allBodyPartsAreNullOrDontHaveScore(reba.body_parts)) {
			updateReba({
				...reba,
				comment: reba.comment ?? '',
				coupling: reba.coupling ?? 1,
				force: reba.force ?? 1,
				repetition: reba?.repetition ?? 1,
				organization_id,
				company_id,
				file_id,
				sector_id: analysis.sector_id,
				workstation_id: analysis.workstation_id
			});
		}
	}, [loadingReba, current, analysis?.id]);

	useEffect(() => {
		goToResults();
	}, [analysis]);

	useEffect(() => {
		if (loadingUpdateStep) {
			setSteps(updateItemArray(WAIT));
		}
	}, [loadingUpdateStep]);

	function allBodyPartsAreNullOrDontHaveScore(bodyParts) {
		if (!bodyParts || !bodyParts.score_seconds) {
			return true;
		}

		return Object.values(bodyParts).every((bodyPartScore) => !bodyPartScore);
	}

	function goToResults() {
		analysis?.preliminary_analysis?.consolidated && setCurrent(steps.length - 1);
	}

	const openNotificationError = () => {
		notificationAPI.error({
			message: I18n.get('Ops... something happened!'),
			description: I18n.get('It was not possible to save your information')
		});
	};

	const updateItemArray = (status) => {
		const newArray = steps.map((item, index) => (index === current ? { ...item, status } : item));
		return newArray;
	};

	const next = async () => {
		try {
			const values = await form.validateFields();
			updateStep(values, {
				onSuccess: () => {
					setCurrent((prev) => prev + 1);
					setSteps(updateItemArray(FINISH));
					queryClient.invalidateQueries(['preliminary-analysis']);
					form.resetFields();
				},
				onError: () => {
					setSteps(updateItemArray(ERROR));
					openNotificationError();
				}
			});
		} catch (error) {
			const errorName = error.errorFields[0].name;
			form.scrollToField(errorName, { behavior: 'smooth', block: 'center', inline: 'center' });

			notificationAPI.error({
				message: I18n.get('Ops... something happened!'),
				description: I18n.get("Some required steps wasn't filled"),
				duration: 5
			});
			setSteps(updateItemArray(ERROR));
		}
	};

	const prev = async () => {
		try {
			const values = await form.validateFields();
			updateStep(values, {
				onSuccess: () => {
					setCurrent((prev) => prev - 1);
					setSteps(updateItemArray(FINISH));
					queryClient.invalidateQueries(['preliminary-analysis']);
					form.resetFields();
				},
				onError: () => {
					setSteps(updateItemArray(ERROR));
					openNotificationError();
				}
			});
		} catch (error) {
			setSteps(updateItemArray(ERROR));
		}
	};

	function formatStepsCompleted(array = []) {
		const completed_step = array.reduce((acc, { step_key, injuries, ...rest }) => {
			const { name } = step_key || [];

			const [key] = name.slice(0, 1);
			const restKeys = name.slice(1);

			const injuriesFormated = injuries?.map((item) => item.name);

			// Refactor PLEASE!
			if (name.length === 3) {
				return {
					...acc,
					[key]: {
						...acc[key],
						[name[1]]: {
							...acc[key]?.[name[1]],
							checked: true,
							[name[2]]: { ...rest, checked: true, injuries: injuriesFormated }
						}
					}
				};
			}

			const data = restKeys.map((item) => ({ [item]: { ...rest, checked: true, injuries: injuriesFormated } }));
			const object = Object.assign({}, ...data);

			return {
				...acc,
				[key]: {
					...acc[key],
					...object
				}
			};
		}, {});

		return completed_step;
	}

	function formatInitialValues() {
		const { sector_id, workstation_id, preliminary_analysis } = analysis;
		const {
			activity_id,
			evaluator_id,
			cellule_id,
			report_name,
			collection_date,
			role_name,

			hours_work_schedule,
			minutes_work_schedule,
			place_description,
			expected_task_description,
			performed_task_description,
			working_population_men,
			working_population_women,
			working_population_others,
			total_working_population,
			particularities_description,
			worker_verbalization_description,
			self_evaluation,
			steps
		} = preliminary_analysis || {};

		const completed_steps = formatStepsCompleted(steps);

		return {
			file: {
				sector_id,
				workstation_id
			},
			preliminary_analysis: {
				// Informations
				role_name,
				sector_id,
				workstation_id,
				activity_id,
				evaluator_id,
				cellule_id,
				report_name,
				collection_date: collection_date && moment(collection_date),

				// WorkConditions
				hours_work_schedule: hours_work_schedule || 8,
				minutes_work_schedule: minutes_work_schedule || 0,
				place_description,
				expected_task_description,
				performed_task_description,

				//Characters
				working_population_men: formatWorkingPopulation(working_population_men, total_working_population) || 0,
				working_population_women:
					formatWorkingPopulation(working_population_women, total_working_population) || 0,
				working_population_others:
					formatWorkingPopulation(working_population_others, total_working_population) || 0,
				total_working_population: total_working_population || 0,
				particularities_description,
				worker_verbalization_description,
				self_evaluation,

				//Reports
				niosh: !!analysis.niosh,
				strain_index: !!analysis.strain_index,
				kim_push_pull: !!analysis.kim_push_pull,
				kim_mho: !!analysis.kim_mho,

				...completed_steps
			}
		};
	}

	const formatWorkingPopulation = (working_population, total_working_population) => {
		const percentage = Math.round((working_population * total_working_population) / 100);
		return percentage;
	};

	const initialValues = formatInitialValues();

	const isLoading = loadingAnalysis;
	const isError = errorFindingOrCreatingAnalysis;

	if (isLoading) {
		return <Spinner />;
	}

	if (isError) {
		return <h2>Error</h2>;
	}

	return (
		<PreliminaryAnalysisProvider>
			<Form form={form} layout="vertical" initialValues={initialValues}>
				<Row gutter={[0, 20]}>
					<Col span={24}>
						<Title level={4}>Preliminary Ergonomic Analysis</Title>
						{contextHolder}
					</Col>
					<Col span={24}>
						<Row>
							<Col sm={4}>
								<Steps
									items={steps}
									current={current}
									direction="vertical"
									status={steps[current].status}
									style={{ alignItems: 'center' }}
								/>
							</Col>
							<Col sm={20}>{steps[current].content}</Col>
							<Col span={16} offset={4} style={{ textAlign: 'center' }}>
								<Footer
									steps={steps}
									onNext={next}
									onPrev={prev}
									current={current}
									isLoading={loadingUpdateStep}
								/>
							</Col>
						</Row>
					</Col>
				</Row>
			</Form>
		</PreliminaryAnalysisProvider>
	);
}
