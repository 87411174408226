import React from 'react';
import { Col, Row } from 'antd';

import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';
import { Paragraph, Title } from '@/components/Typography';

export function Header() {
	const breakpoints = useBreakpoint();
	return (
		<Row justify="center" style={{ textAlign: 'center', padding: '0.8vh' }}>
			<Col span={24}>
				<Title level={3} style={{ fontSize: `${!breakpoints.xxl ? '1.5rem' : '2rem'}` }}>
					Minutes used
				</Title>
			</Col>
			<Col span={24}>
				<Paragraph>Track the minutes used on the platform during the desired period</Paragraph>
			</Col>
		</Row>
	);
}
