import React from 'react';
import * as I from '@ant-design/icons';
import { I18n } from '@aws-amplify/core';
import { Row, Col, Tooltip } from 'antd';
import { useHistory } from 'react-router-dom';

import { Chair } from '@/assets/svg/Chair';
import { ErgonomicTools } from '@/types';
// import { ScoreParts } from './ScoreParts';
import { FilesListResponse } from '@/hooks';
import { LibertyMutualIcon } from './LibertyMutualIcon';
import { Paragraph, Title } from '@/components/Typography';
import { useApplicationContext } from '@/context/Application';
import { PersonalisedReportIcon } from './PersonalisedReportIcon';
import type { ErgonomicToolsIconMapper, ErgonomicToolsList } from './types';
import { useGetErgonomicToolsCategories } from '@/hooks/useGetErgonomicToolsCategories';
import { ErgonomicToolCol, ErgonomicToolRow, ModalBorder, ModalCard, ModalColumn } from './styles';

interface ErgonomicToolsModalProps {
	visible: boolean;
	file: FilesListResponse;
	onClose: (visible?: boolean) => void;
}

export const ErgonomicToolsModal: React.FC<ErgonomicToolsModalProps> = (props) => {
	const history = useHistory();
	const { file, onClose, visible } = props;
	const { organization, company } = useApplicationContext();

	const {
		data: ergonomicTools,
		isFetching: gettingErgonomicTools,
		isError: errorGettingErgonomicTools
	} = useGetErgonomicToolsCategories({
		company_id: company?.id,
		organization_id: organization?.id
	});

	const ergonomicToolsIconMapper: ErgonomicToolsIconMapper = {
		rosa: <Chair />,
		rula: <I.PieChartOutlined />,
		reba: <I.LineChartOutlined />,
		'angle-time': <I.AreaChartOutlined />,
		niosh: <I.ToTopOutlined />,
		'kim-pp': <I.ColumnWidthOutlined />,
		'kim-mho': <I.LikeOutlined style={{ transform: 'rotate(270deg)' }} />,
		'liberty-mutual': <LibertyMutualIcon />,
		'strain-index': <I.RetweetOutlined />,
		'personalised-report': <PersonalisedReportIcon />
	};

	const onSelectReport = (type: ErgonomicTools, custom_report_id?: string) => {
		const ergonomicToolsWithoutRULA: ErgonomicTools[] = mapErgonomicToolWithoutRULA();
		if (ergonomicToolsWithoutRULA.includes(type)) {
			let url = `/reporting/${type}/${file.id}/`;
			if (type === 'personalised-report') {
				url = `/reporting/${type}/${custom_report_id}/${file.id}/`;
			}
			history.push(`${url}?company_id=${company?.id}&organization_id=${organization?.id}`);
			return;
		}
	};

	function mapErgonomicToolWithoutRULA() {
		const ergonomicToolsWithoutRULA: ErgonomicTools[] = [];
		for (const tool in ergonomicToolsIconMapper) {
			const typedTool = tool as ErgonomicTools;

			ergonomicToolsWithoutRULA.push(typedTool);
		}
		return ergonomicToolsWithoutRULA;
	}

	if (errorGettingErgonomicTools) {
		return null;
	}

	if (gettingErgonomicTools) {
		return null;
	}

	const ergonomicToolsList: ErgonomicToolsList[] = ergonomicTools.map(
		({ id, name, description, ergonomic_tools }) => ({
			id,
			name,
			description,
			ergonomic_tools: ergonomic_tools.map(({ id, description, title, subtitle, custom_report_settings }) => ({
				id,
				title,
				name: description,
				message: subtitle ?? custom_report_settings?.extended_description,
				icon: ergonomicToolsIconMapper[description],
				custom_report_id: custom_report_settings?.custom_report_id
			}))
		})
	);

	return (
		<ModalBorder width={'90rem'} footer={null} open={visible} onCancel={() => onClose()} centered>
			<Row justify="center" gutter={[0, 5]}>
				<Col span={24}>
					<Title level={3} style={{ textAlign: 'center' }}>
						Ergonomic tools
					</Title>
				</Col>
				<Col span={24}>
					<Paragraph className="paragraph-modal" style={{ fontSize: '20px', textAlign: 'center' }}>
						{I18n.get('Select the most suitable tool for your analysis')}
					</Paragraph>
				</Col>
				<Col span={24}>
					<ErgonomicToolRow justify="center">
						{ergonomicToolsList.map(({ id, description, ergonomic_tools }) => (
							<ErgonomicToolCol key={id} className="ergonomic-tool-col" span={4}>
								<Col sm={24}>
									<Title level={4} style={{ fontSize: '14px' }}>
										{I18n.get(description)}
									</Title>
								</Col>
								<ModalColumn sm={24}>
									{ergonomic_tools.map((item) => (
										<Row
											justify={'center'}
											gutter={20}
											key={item.id}
											onClick={() => onSelectReport(item.name, item.custom_report_id)}
										>
											<ModalCard>
												{item.icon}
												<Tooltip title={item.title}>
													<p className="title-card">{I18n.get(item.title)}</p>
												</Tooltip>
												<Tooltip title={item.message}>
													<p className="message-card">{I18n.get(item.message)}</p>
												</Tooltip>
											</ModalCard>
										</Row>
									))}
								</ModalColumn>
							</ErgonomicToolCol>
						))}
					</ErgonomicToolRow>
				</Col>
			</Row>
		</ModalBorder>
	);
};
