import { useQuery } from '@tanstack/react-query';

import Api from '@/services/api';
import { QUERY_KEYS } from '@/utils/query-keys';
import type { AxiosErrorResponse, BaseReportsPaginated, BaseReportsPaginatedResponse, PaginatedData } from '@/types';

export type ListAllRosaDTO = BaseReportsPaginated;

export type ListAllRosaResponse = PaginatedData<BaseReportsPaginatedResponse>;

async function listRosaPaginated(params: ListAllRosaDTO): Promise<ListAllRosaResponse> {
	const url = '/ergonomic-tool/rosa/reports/list-all';
	const { data } = await Api.get<ListAllRosaResponse>(url, {
		params
	});
	return data;
}

export const useListRosaPaginated = ({
	limit,
	offset,
	sector_id,
	company_id,
	cellule_id,
	report_name,
	evaluator_id,
	created_at_end,
	workstation_id,
	organization_id,
	created_at_start,
	isSelectedReport,
	activity_id
}: ListAllRosaDTO) => {
	const enabled = !!limit && !!isSelectedReport && offset >= 0;
	const rebaReport = useQuery<ListAllRosaResponse, AxiosErrorResponse>(
		[
			QUERY_KEYS.GET_ROSA_REPORTS_PAGINATED,
			[
				limit,
				offset,
				sector_id,
				company_id,
				cellule_id,
				report_name,
				evaluator_id,
				created_at_end,
				workstation_id,
				organization_id,
				created_at_start,
				activity_id
			]
		],
		() =>
			listRosaPaginated({
				limit,
				offset,
				sector_id,
				company_id,
				cellule_id,
				report_name,
				evaluator_id,
				created_at_end,
				workstation_id,
				organization_id,
				created_at_start,
				activity_id
			}),
		{
			enabled,
			retry: 0
		}
	);
	return rebaReport;
};
