import React, { useEffect, useState } from 'react';
import { Col, Form, Row, Select, Switch } from 'antd';
import { I18n } from '@aws-amplify/core';

import { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from '@tanstack/react-query';
import { useDeleteImage } from '@/components/ui/UploadLogos/hooks';
import { CompanyDTO, OrganizationDTO } from '../../../types';
import { UploadLogo } from '@/components/ui/UploadLogos';
import { useManageOrganization } from '../../../context';
import { SelectId, SwitchText } from '../styles';
import { useApplicationContext } from '@/context/Application';

const { useFormInstance } = Form;

type OptionType = {
	label: string;
	value: string;
};

type SelectCompanyProps = {
	companies: CompanyDTO[];
	organization: OrganizationDTO;
	companyRefetch: <TPageData>(
		options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
	) => Promise<QueryObserverResult<CompanyDTO[], unknown>>;
};

export const SelectCompany: React.FC<SelectCompanyProps> = ({ companies, companyRefetch, organization }) => {
	const { resetFields, setFieldValue } = useFormInstance();
	const { updateCompany, company: localStorageCompany } = useApplicationContext();

	const [company, setCompany] = useState<CompanyDTO | undefined>();
	const [isChecked, setIsChecked] = useState(false);

	const {
		companyId,
		updatingOrganizationData,
		fileList,
		isUrlLogoCompany,
		handleCompany,
		setIsUrlLogoCompany,
		setFileList,
		organizationRefetch
	} = useManageOrganization();

	useEffect(() => {
		const isCompanyLogo = company?.url_logo;

		isChecked || isCompanyLogo ? setIsUrlLogoCompany(false) : setIsUrlLogoCompany(true);

		if (!isChecked && fileList.length === 0) {
			setIsUrlLogoCompany(true);
		} else {
			setIsUrlLogoCompany(false);
		}
	}, [isChecked, company, fileList]);

	useEffect(() => {
		if (company) {
			const company_logo = company.url_logo;
			handleOrganizationLogo(company_logo);
			company_logo !== organization.url_logo ? setIsChecked(false) : setIsChecked(true);
		}
	}, [company]);

	useEffect(() => {
		handleSelectCompany(companyId);
	}, [company?.url_logo]);

	const organizationId = organization.id;
	const type = 'company';
	const { mutate: removeImage } = useDeleteImage(organizationId, companyId, type);

	function filterCompany(option: OptionType | undefined, input: string): boolean {
		return (option?.label?.toLowerCase() ?? '').includes(input.toLowerCase());
	}

	function handleSelectCompany(id: string) {
		setCompany(companies.find((elem) => elem.id === id));
		handleCompany(id, resetFields, setFieldValue);
	}

	const handleOrganizationLogo = (company_logo?: string) => {
		const url_logo = company_logo && company_logo.length > 0 ? company_logo : organization.url_logo;
		setFileList([
			{
				uid: '-1',
				url: company_logo ? url_logo : organization.url_logo,
				name: I18n.get(type)
			}
		]);
	};

	const switchCheck = (switchValue: boolean) => {
		if (!switchValue || fileList.length > 0) {
			removeImage();
			setLogoCompanyLocalStorage(organization.url_logo);
		}
		organizationRefetch();
		setIsUrlLogoCompany(true);
		isChecked ? setFileList([]) : handleOrganizationLogo();
	};

	const setLogoCompanyLocalStorage = (url_logo: string | undefined) => {
		if (companyId === localStorageCompany.id) {
			updateCompany(url_logo);
		}
	};

	function handleSwitch(switchValue: boolean) {
		setIsChecked(!isChecked);

		if (fileList.length === 0 && !isChecked) {
			setIsUrlLogoCompany(false);
			handleOrganizationLogo();
		}
		switchCheck(switchValue);
	}

	const handleUpdatedCompany = (value: string) => {
		handleSelectCompany(value);
		companyRefetch();
	};

	const companiesList = companies.map(({ id, name }) => ({
		label: name,
		value: id
	}));

	return (
		<Col span={24} style={{ height: '170px' }}>
			<Row align="middle" justify="center" gutter={[65, 20]}>
				<Col>
					<SelectId
						selected={!!companyId}
						name="company_id"
						label={I18n.get('Select a industrial site')}
						style={{ margin: '0', fontWeight: 'normal' }}
						rules={[
							{
								message: I18n.get('Please, select a industrial site')
							}
						]}
					>
						<Select
							showSearch
							options={companiesList}
							loading={updatingOrganizationData}
							filterOption={(input, option) => filterCompany(option, input)}
							onChange={async (value: string) => handleUpdatedCompany(value)}
							placeholder={I18n.get('Select a industrial site')}
						/>
					</SelectId>

					<Row justify={'center'} align={'middle'} style={{ marginTop: '13px' }}>
						<Switch
							size="small"
							defaultChecked
							checked={!company ? false : isChecked}
							onChange={handleSwitch}
							disabled={!company}
							style={{ marginRight: '11px' }}
						/>
						<SwitchText disabled={!company || !isChecked}>
							{I18n.get('Use the same of the company')}
						</SwitchText>
					</Row>
				</Col>
				{company && (
					<Col>
						<UploadLogo
							url={company.url_logo || organization.url_logo}
							organizationId={organization.id}
							companyId={company.id}
							blockButton={isUrlLogoCompany}
							setBlockButton={setIsUrlLogoCompany}
							type={'company'}
							fileList={fileList}
							setFileList={setFileList}
							isDisable={isChecked}
						/>
					</Col>
				)}
			</Row>
		</Col>
	);
};
