import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { Services } from './services';
import { IPlanResponse } from './types';

const { getPlan } = Services;

export const usePlan = (): UseQueryResult<IPlanResponse> => {
	const result = useQuery(['plans'], () => getPlan());
	return result;
};
