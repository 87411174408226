import React from 'react';
import * as S from './styles';
import { Header } from './Header';
import { DatailForm } from './Form';

interface DetailsModalProps {
	open: boolean;
	onCancel: (value: boolean) => void;
}

export const DetailsModal: React.FC<DetailsModalProps> = ({ open, onCancel }) => (
	<S.Container
		open={open}
		width="45vw"
		footer={null}
		centered={true}
		style={{ borderRadius: '10px' }}
		onCancel={() => onCancel(false)}
	>
		<Header />
		<DatailForm />
	</S.Container>
);
