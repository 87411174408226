import React, { useEffect, useState } from 'react';
import { Layout, Grid } from 'antd';

const { useBreakpoint } = Grid;

interface SiderProps {
	children: React.ReactNode;
}

export const Sider: React.FC<SiderProps> = ({ children }) => {
	const breakpoints = useBreakpoint();
	const [collapsed, setCollapsed] = useState<boolean>(false);

	const isExtraLargeScreen = breakpoints.xl ?? true;

	function handleCollapse(): void {
		setCollapsed((prev) => !prev);
	}

	useEffect(() => {
		setCollapsed(!isExtraLargeScreen);
	}, [isExtraLargeScreen]);

	return (
		<Layout.Sider
			id="sider"
			width={250}
			collapsible
			theme="light"
			collapsed={collapsed}
			onCollapse={handleCollapse}
		>
			{children}
		</Layout.Sider>
	);
};
